import { Injectable, Injector } from '@angular/core'
import { Overlay, OverlayRef } from '@angular/cdk/overlay'
import { ComponentPortal, PortalInjector } from '@angular/cdk/portal'
import { ToastComponent } from './toast.component'
import { ToastRef } from './toast'
import { ToastData, TOAST_DATA } from './toast.dictionary'

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  constructor(private overlay: Overlay, private parentInjector: Injector) {}

  private lastToast: DOMRect | ClientRect
  private overlayRef: OverlayRef
  private lastPosition: number

  show(data: ToastData) {
    const positionStrategy = this.getToastPosition(data.position)
    this.overlayRef = this.overlay.create({
      positionStrategy,
    })
    const toastRef = new ToastRef(this.overlayRef)
    const injector = this.getInjector(data, toastRef, this.parentInjector)
    const toastPortal = new ComponentPortal(ToastComponent, null, injector)

    this.overlayRef.attach(toastPortal)
    return toastRef
  }

  getToastPosition(position) {
    switch (position) {
      case 'top-left':
        return this.overlay.position().global().left().top(this.getPosition())
      case 'top-right':
        return this.overlay.position().global().right().top(this.getPosition())
      case 'bottom-left':
        return this.overlay.position().global().left().bottom(this.getPosition())
      case 'bottom-right':
        return this.overlay.position().global().right().bottom(this.getPosition())
      default:
        return this.overlay.position().global().right().top(this.getPosition())
    }
  }

  getInjector(data: ToastData, toastRef: ToastRef, parentInjector: Injector) {
    const tokens = new WeakMap()

    tokens.set(TOAST_DATA, data)
    tokens.set(ToastRef, toastRef)

    return new PortalInjector(parentInjector, tokens)
  }

  getPosition() {
    this.getLastPosition()
    const newPosition = this.lastToast
      ? this.lastPosition + (this.lastToast.bottom - this.lastToast.top)
      : 0
    this.lastPosition = newPosition
    return newPosition + 'px'
  }

  getLastPosition() {
    if (this.overlayRef && this.overlayRef.overlayElement) {
      this.lastToast = this.overlayRef.overlayElement.getBoundingClientRect()
    } else {
      this.lastToast = null
    }
  }
}
