import { Directive, Input } from '@angular/core'
import {
  FieldConfigInterface,
  FormConfigInterface,
  TemplateConfigInterface,
} from './dictionary/flx-template.dictionary'
import { StatusType } from 'paperflow-web-components/lib/shared/shared.dictionary'
import { config } from 'rxjs'

@Directive()
// tslint:disable-next-line:directive-class-suffix
export abstract class FlxWrapperComponent<
  F extends Partial<
    TemplateConfigInterface | FieldConfigInterface | FormConfigInterface
  > = FieldConfigInterface
> {
  protected _config: F

  @Input()
  set config(data: F) {
    this._config = data
  }

  get config(): F {
    return this._config
  }

  get placeholder(): string {
    return this._config?.displayOptions?.flowxProps?.placeholder
  }

  get label(): string {
    return this._config?.displayOptions?.flowxProps?.label
  }
  get type(): string {
    return this._config?.displayOptions?.flowxProps?.type || 'box'
  }

  get class(): string {
    return this._config?.displayOptions?.class || ''
  }

  get style(): string {
    return this._config?.displayOptions?.style || ''
  }

  get layout(): string {
    return this._config?.displayOptions?.flexLayout?.fxLayout || 'row'
  }

  get layoutAlign(): string {
    return this._config?.displayOptions?.flexLayout?.fxLayoutAlign || 'start stretch'
  }

  get status(): StatusType | null {
    return this._config?.displayOptions?.flowxProps?.status
      ? this._config?.displayOptions.flowxProps.status
      : null
  }
}
