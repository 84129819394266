import { from, Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { flow as _flow } from 'lodash/fp'

import { getConfig } from '../store/process-config'
import { buildAuthHeader, getFlowXEngineAxiosInstance } from './axios.utils'

// TODO: Define ProcessInstanceResponse format
export function fetchProcessInstance(id: number): Observable<Record<string, any>> {
  return from(
    _flow(getConfig, getFlowXEngineAxiosInstance)().get(`/api/instances/${id}`, {
      headers: buildAuthHeader(),
    })
  ).pipe(map((response) => response.data))
}

export function fetchProcessInstanceStatus(uuid: string): Observable<Record<string, any>> {
  return from(
    _flow(getConfig, getFlowXEngineAxiosInstance)().get(`/api/process/${uuid}/status`, {
      headers: buildAuthHeader(),
    })
  ).pipe(map((response) => response.data))
}

export function fetchProcessData(uuid: string, templateConfigId: number): Observable<any> {
  return from(
    _flow(getConfig, getFlowXEngineAxiosInstance)().get(
      `/api/process/${uuid}/data/${templateConfigId}`,
      {
        headers: buildAuthHeader(),
      }
    )
  ).pipe(map((response) => response.data))
}

export function dismissProcess(uuid: string): Observable<any> {
  return from(
    _flow(getConfig, getFlowXEngineAxiosInstance)().post(`/api/process/${uuid}/dismiss`, null, {
      headers: buildAuthHeader(),
    })
  ).pipe(map((response) => response.data))
}
