import { Component, ElementRef, ViewChild, AfterViewInit, ContentChild, HostBinding } from '@angular/core'
import { SwitchRefDirective } from './switch.directive'

@Component({
  selector: 'ppf-switch',
  templateUrl: './switch.component.html',
  styleUrls: ['./switch.component.scss'],
})
export class SwitchComponent implements AfterViewInit {
  @ContentChild(SwitchRefDirective) checkbox: SwitchRefDirective | null


  @HostBinding('class.disabled-state')
  get disabled() {
    return this.checkbox ? this.checkbox.isDisabled : false;
  }

  ngAfterViewInit(): void {
    if (!this.checkbox) {
      throw Error(
        `You must provide a child input of type 'checkbox' using 'ppfSwitch' directive to ppf-switch!`
      )
    }
  }
}
