import { Component, Input, OnChanges } from '@angular/core'
import { CdkStepper, CdkStep } from '@angular/cdk/stepper'

@Component({
  selector: 'ppf-stepper',
  templateUrl: './stepper.component.html',
  styleUrls: ['./stepper.component.scss'],
  providers: [{ provide: CdkStepper, useExisting: StepperComponent }],
})
export class StepperComponent extends CdkStepper {
  @Input() lines = false
  @Input() fillLayout = true

  showStepChecked(step: CdkStep, stepIndex: number) {
    return step.completed && stepIndex !== this.selectedIndex
  }

  showStepDisabled(stepIndex: number) {
    return (
      this.linear &&
      this.steps.filter(
        (step, index) => step.stepControl && !step.stepControl.valid && index < stepIndex
      ).length
    )
  }

  shouldRenderLine(stepIndex: number) {
    return this.lines && stepIndex < this.steps.length - 1
  }
}
