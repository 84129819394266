import { Component, OnInit, Inject, OnDestroy, HostBinding } from '@angular/core'
import { ToastRef } from './toast'
import {
  TOAST_DATA,
  ToastData,
  DEFAULT_DISPLAY_TYPE,
  DEFAULT_CLOSE_INTERVAL,
} from './toast.dictionary'

@Component({
  selector: 'ppf-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss'],
})
export class ToastComponent implements OnInit, OnDestroy {
  constructor(@Inject(TOAST_DATA) readonly data: ToastData, readonly ref: ToastRef) {}

  private intervalId

  @HostBinding('class')
  get classes() {
    const displayType = this.data.displayType ? this.data.displayType : DEFAULT_DISPLAY_TYPE
    return `ppf-toast-${this.data.messageType} ppf-toast-${displayType}`
  }

  @HostBinding('class.ppf-toast-no-title')
  get isNoTitle() {
    return !this.data.title
  }

  ngOnInit(): void {
    if (!this.data.closeOnClick) {
      this.intervalId = setTimeout(
        () => this.close(),
        this.data.closeInterval ? this.data.closeInterval : DEFAULT_CLOSE_INTERVAL
      )
    }
  }

  close() {
    this.ref.close()
  }

  getIconName() {
    if (!this.data.icon) {
      return this.data.messageType !== 'custom' ? this.data.messageType : 'info'
    } else {
      return this.data.icon
    }
  }

  handleClick(action) {
    action.event()
    this.close()
  }

  ngOnDestroy() {
    if (this.intervalId) {
      clearTimeout(this.intervalId)
    }
  }
}
