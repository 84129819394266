export type spinnerType = 'indeterminate' | 'determinate'
export type spinnerVisualType = 'default' | 'custom'
export type spinnerSize = 'small' | 'big' | 'custom'
export type spinnerColor = 'primary' | 'secondary' | 'error'

export const DEFAULT_TYPE = 'indeterminate'
export const DEFAULT_VISUAL_TYPE = 'default'
export const DEFAULT_SIZE = 'big'
export const DEFAULT_COLOR = 'primary'

export const SIZE = {
  small: {
    radius: 31,
    border: 6,
  },
  big: {
    radius: 77,
    border: 8,
  },
}
