import { Directive, HostBinding, Input, ElementRef, OnInit, HostListener } from '@angular/core'

@Directive({
  selector: '[ppfCheckbox]',
})
export class CheckboxRefDirective implements OnInit {
  @Input()
  @HostBinding('checked')
  checked: boolean

  @Input()
  disabled

  @HostBinding('disabled')
  get isDisabled() {
    return typeof this.disabled !== 'undefined' ? this.disabled : this.el.nativeElement.disabled
  }

  @Input()
  id: string

  @HostListener('click')
  onClick() {
    this.checked = this.el.nativeElement.checked
  }

  constructor(private el: ElementRef) {}

  ngOnInit() {
    this.checkHost()
  }

  private checkHost() {
    if (this.el.nativeElement.tagName !== 'INPUT') {
      throw Error(`ppfCheckbox directive works only on an input element`)
    }

    if (this.el.nativeElement.type !== 'checkbox') {
      throw Error(`ppfCheckbox directive works only on a checkbox input element`)
    }
  }
}
