import { from, Observable, of } from 'rxjs'
import { map, mergeMap, switchMap, take, tap, withLatestFrom } from 'rxjs/operators'

import { ProgressUpdateDTO, StartProcessResponse } from '../dictionary/flx-process.dictionary'
import { discardProcessInstanceData, updateProcessData } from '../store/process-data.store'
import { TemplateConfigInterface } from '../dictionary/flx-template.dictionary'
import { addProcessActions } from '../store/process-actions.store'
import { updateNotifications } from '../store/process-notifications.store'

import * as ProcessInstaceGateway from './process-instance.gateway'

import {
  activeProcessInstanceId$,
  addChildProcessInstance,
  addProcessInstance,
  currentNodeId$,
  discardProcessInstance,
  processInstances$,
  setActiveProcessInstance,
  updateProcessInstanceCurrentState,
} from '../store/process-instance.store'

import {
  addTemplateConfig,
  discardTemplateConfigById,
  flattenedTemplateConfigs$,
  getTemplateConfigsByTemplateSequence,
} from '../store/template-config.store'

// TODO: Seems like handleProcessInstanceUpdate & handleNewProcess instance have duplicated code
// TODO: but not sure if trying to combine them would actually be a good ideea
// TODO: Investigate further
// ! As an insight, I think the duplicated code should be moved up one layer of abstraction
// ! (like the one which looks if there is process data to be saved to the store)
export function handleProcessInstanceUpdate(processInstanceUpdate: ProgressUpdateDTO): void {
  processInstances$
    .pipe(
      take(1),
      switchMap((processInstances) => {
        if (processInstances[processInstanceUpdate.processInstanceUuid]) {
          updateProcessInstanceCurrentState(
            processInstanceUpdate.processInstanceUuid,
            processInstanceUpdate.currentNodeId,
            processInstanceUpdate.tokenUuid
          )
          return of(processInstanceUpdate.processInstanceUuid)
        } else {
          return ProcessInstaceGateway.fetchProcessInstanceStatus(
            processInstanceUpdate.processInstanceUuid
          ).pipe(
            tap((processInstance) => {
              if (processInstance.subprocessesUuids?.length) {
                processInstance.subprocessesUuids.forEach((subprocessUuid) => {
                  handleProcessInstanceUpdate({
                    processInstanceUuid: subprocessUuid,
                    tokenUuid: null,
                    currentNodeId: null,
                  })
                })
              }
            }),
            withLatestFrom(activeProcessInstanceId$),
            tap(([processInstance, activeProcessInstanceId]) => {
              const { uuid, templateConfig, tokens, generalData, websocketPath } = processInstance
              const { currentNodeId, uuid: currentTokenUuid } = tokens[0]

              addChildProcessInstance(activeProcessInstanceId, {
                uuid,
                websocketPath,
                currentNodeId,
                currentTokenUuid,
                tokens,
              })
              setActiveProcessInstance(uuid)
              addTemplateConfig(uuid, templateConfig)
              updateNotifications(uuid, generalData?.notifications)
            }),
            map(() => {
              return processInstanceUpdate.processInstanceUuid
            })
          )
        }
      }),
      switchMap((processInstanceUuid: string) => {
        return getDataForCurrentNodeId(processInstanceUuid)
      })
    )
    .subscribe()
}

export function handleNewProcessInstance(processInstance: StartProcessResponse): void {
  const { uuid, templateConfig, templatesSequence, tokens, generalData } = processInstance
  const currentNodeId = tokens ? tokens[0].currentNodeId : null
  const currentTokenUuid = tokens ? tokens[0].uuid : null

  addProcessInstance({
    uuid,
    websocketPath: processInstance.webSocketPath,
    parentProcessUuid: null,
    currentNodeId,
    currentTokenUuid,
    templatesSequence,
    subprocessIds: [],
    tokens,
  })

  setActiveProcessInstance(uuid)

  addTemplateConfig(uuid, templateConfig)

  updateNotifications(uuid, generalData?.notifications)

  if (templatesSequence?.length) {
    getDataForTemplateSequences(uuid, templatesSequence).subscribe()
  } else {
    getDataForCurrentNodeId(uuid).subscribe()
  }
}

export function getDataForCurrentNodeId(processInstanceUuid: string): Observable<any> {
  return currentNodeId$(processInstanceUuid).pipe(
    take(1),
    withLatestFrom(activeProcessInstanceId$, flattenedTemplateConfigs$),
    switchMap(([currentNodeId, activeProcessInstanceId, flattenedTemplateConfigs]) => {
      const templateConfig: TemplateConfigInterface = flattenedTemplateConfigs[
        activeProcessInstanceId
      ].find((tc) => tc.nodeDefinitionId === currentNodeId)
      if (!templateConfig) {
        return of()
      }
      return ProcessInstaceGateway.fetchProcessData(processInstanceUuid, templateConfig.id).pipe(
        tap((response) => {
          updateProcessData(processInstanceUuid, response.data)
          addProcessActions(processInstanceUuid, response?.actions || [])
        })
      )
    })
  )
}

export function getDataForTemplateSequences(
  processInstanceUuid: string,
  templateSequencesIds: number[]
): Observable<any> {
  return flattenedTemplateConfigs$.pipe(
    take(1),
    map((flattenedTemplateConfigsStore) => {
      return flattenedTemplateConfigsStore[processInstanceUuid]
    }),
    map((templateConfigs) =>
      getTemplateConfigsByTemplateSequence(templateConfigs, templateSequencesIds).map((tc) => tc.id)
    ),
    switchMap((templateIdsIds: number[]) => {
      return from(templateIdsIds).pipe(
        mergeMap((tcId) => {
          return ProcessInstaceGateway.fetchProcessData(processInstanceUuid, tcId).pipe(
            tap((response) => {
              updateProcessData(processInstanceUuid, response.data)
              addProcessActions(processInstanceUuid, response?.actions || [])
            })
          )
        })
      )
    })
  )
}

export function dismissProcess(processInstanceUuid: string): Observable<any> {
  return ProcessInstaceGateway.dismissProcess(processInstanceUuid).pipe(
    tap(() => removeAllProcessData(processInstanceUuid))
  )
}

export function removeAllProcessData(processInstanceUuid: string): void {
  discardProcessInstance(processInstanceUuid)
  discardTemplateConfigById(processInstanceUuid)
  discardProcessInstanceData(processInstanceUuid)
}
