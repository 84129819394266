import { Component, OnInit } from '@angular/core'
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms'
import { Observable } from 'rxjs'
import { distinctUntilChanged, map, tap, withLatestFrom } from 'rxjs/operators'
import { StepperSelectionEvent } from '@angular/cdk/stepper'
import { isNil as _isNil, orderBy as _orderBy, last as _last, isEqual as _isEqual } from 'lodash/fp'

import {
  ComponentIdentifierEnum,
  IStep,
  TemplateConfigInterface,
  TemplateConfigType,
} from '../../../dictionary/flx-template.dictionary'
import { activeProcessInstanceId$, currentNodeId$ } from '../../../store/process-instance.store'
import { flattenedTemplateConfigs$ } from '../../../store/template-config.store'
import { FlxWrapperComponent } from '../../../flx-wrapper'

@Component({
  selector: 'flx-stepper-wrapper',
  templateUrl: './stepper-wrapper.component.html',
  styleUrls: ['./stepper-wrapper.component.css'],
})
export class StepperWrapperComponent
  extends FlxWrapperComponent<TemplateConfigInterface>
  implements OnInit
{
  steps: IStep[]
  form: FormGroup = new FormGroup({})
  childFormsTemplateConfigs: TemplateConfigInterface[] = []
  selectedStepIndex$: Observable<number>
  selectedIndex: number

  get fillLayout(): boolean {
    return !_isNil(this.config?.displayOptions?.flowxProps?.fillLayout)
      ? this.config.displayOptions.flowxProps.fillLayout
      : true
  }

  stepClicked(event: StepperSelectionEvent): void {
    this.selectedIndex = event.selectedIndex
  }

  ngOnInit(): void {
    this.steps = this.buildSteps()
    this.buildStepControls()
    this.selectedStepIndex$ = currentNodeId$(this.config.processInstanceUuid).pipe(
      withLatestFrom(flattenedTemplateConfigs$, activeProcessInstanceId$),
      map(([currentNodeId, flattenedTemplateConfigs, activeProcessInstanceId]) =>
        this.findCurrentStepIndex(currentNodeId, flattenedTemplateConfigs[activeProcessInstanceId])
      ),
      distinctUntilChanged((prev, curr) => _isEqual(prev, curr)),
      tap((selectedIndex) => this.updateStepsValidity(selectedIndex)),
      tap((selectedIndex) => this.markPreviousStepsAsCompleted(selectedIndex))
    )
  }

  buildSteps(): IStep[] {
    if (!this.config.templateConfig) {
      return []
    }

    const steps = this.config.templateConfig
      .filter((tc) => tc.componentIdentifier === ComponentIdentifierEnum.STEP)
      .map((step) => ({
        ...step,
        completed: false,
      }))
    return _orderBy('order', 'asc', steps)
  }

  buildStepControls(): void {
    this.buildSteps().forEach((step) => {
      return this.form.addControl(`${step.id}`, new FormControl('', [Validators.required]))
    })
  }

  getFormControl(step: any): AbstractControl {
    return this.form.get(`${step.id}`)
  }

  /**
   * Finds the step that contains the UI element which matches the current node id.
   * @param currentNodeId the id by which the search is made
   * @param uiElements UI elements array, sorted by order
   */
  findCurrentStepIndex(currentNodeId: number, uiElements: TemplateConfigInterface[]): number {
    // For anyone reading this function, keep in mind it is made to also work with stepper in stepper
    let currentStepIndex
    // find the current template element in the ordered templateElements array
    const currentNodeCardIndex = uiElements.findIndex(
      (templateElement) => templateElement.nodeDefinitionId === currentNodeId
    )

    // find all UI elements for the last step (so we can find the right boundary of the current
    // stepper in the ordered UI elements array)
    this.findAllNodeTemplateConfigs(_last(this.steps), [
      ComponentIdentifierEnum.FORM_GROUP,
      ComponentIdentifierEnum.MODAL,
    ])

    // the result of the findAllNodeTemplateConfigs function is put in the childFormsTemplateConfigs
    const lastStepTemplateConfigs = this.childFormsTemplateConfigs

    // find the index for the right boundary of the current stepper in the ordered ui elements array
    const lastCardInStepperIndex = uiElements.findIndex(
      (templateElement) =>
        _last(lastStepTemplateConfigs).nodeDefinitionId === templateElement.nodeDefinitionId
    )

    if (currentNodeCardIndex > lastCardInStepperIndex) {
      // current node is past this stepper (so this stepper has reached the final step)
      currentStepIndex = this.steps.length - 1
    } else {
      // we are somewhere in this stepper, find exactly where
      currentStepIndex = this.steps.findIndex((step) => {
        return this.getNodeDefinitionsIds(step).includes(currentNodeId)
      })
    }

    this.selectedIndex = currentStepIndex > 0 ? currentStepIndex : 0
    return this.selectedIndex
  }

  updateStepsValidity(selectedIndex: number): void {
    this.steps.forEach((step, index) => {
      if (index < selectedIndex) {
        this.getFormControl(step).setValue(step.nodeDefinitionId)
      } else {
        this.getFormControl(step).reset(null)
      }

      this.form.updateValueAndValidity()
    })
  }

  markPreviousStepsAsCompleted(selectedIndex: number): void {
    this.steps = this.steps.map((step, i) => ({ ...step, completed: i < selectedIndex }))
  }

  private getNodeDefinitionsIds(step: IStep): number[] {
    this.childFormsTemplateConfigs = []

    // TODO use templateConfigsByParentTemplateId$ to get all child nodes based in parentTemplateId?
    this.findAllNodeTemplateConfigs(step, [ComponentIdentifierEnum.FORM_GROUP])

    // ? Care e presupunerea aici? Ca doar form-urile au nodeDefinitionId?
    return this.childFormsTemplateConfigs
      .map((tc) => tc.nodeDefinitionId)
      .filter((nodeDefinitionId) => Boolean(nodeDefinitionId))
  }

  // TODO: Functia ar trebui mutata in store-ul de template configs
  // ! Nu prea se intelege din numele functiei ca se cauta doar anumite templateConfigs
  private findAllNodeTemplateConfigs(
    templateConfig: TemplateConfigInterface,
    tcTypesToStopRecursion: string[]
  ): void {
    if (!tcTypesToStopRecursion.includes(templateConfig.componentIdentifier)) {
      templateConfig.templateConfig?.forEach((tc: TemplateConfigInterface) => {
        return this.findAllNodeTemplateConfigs(tc, tcTypesToStopRecursion)
      })
    }

    if (
      templateConfig.componentIdentifier === ComponentIdentifierEnum.FORM_GROUP ||
      templateConfig.componentIdentifier === ComponentIdentifierEnum.CONTAINER ||
      templateConfig.type === TemplateConfigType.CUSTOM
    ) {
      this.childFormsTemplateConfigs = [...this.childFormsTemplateConfigs, templateConfig]
    }
  }
}
