import { Pipe, PipeTransform } from '@angular/core'
import { isArray, orderBy } from 'lodash'

@Pipe({
  name: 'flxOrderBy',
})
export class FlxOrderByPipe implements PipeTransform {
  constructor() {}

  transform(input: any, prop: string): Array<any> {
    if (!isArray(input) || !prop) {
      return input
    }
    return orderBy(input, prop)
  }
}
