import { Component, Input, HostBinding, ViewEncapsulation, ChangeDetectionStrategy } from '@angular/core';
import { AVATAR_CONSTANTS, AvatarType, AvatarSize, AvatarStatus, AvatarPosition, AvatarBackground } from './avatar.dictionary';

@Component({
  selector: 'ppf-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AvatarComponent {
  @Input() size: AvatarSize = 'large';
  @Input() type: AvatarType = 'square';
  @Input() status: AvatarStatus;
  @Input() statusPosition: AvatarPosition = 'topRight';
  @Input() background: AvatarBackground | null = null;
  @Input() image: string | null = null;
  @Input() text: string | null = null;

  AVATAR_CONSTANTS = AVATAR_CONSTANTS;

  @HostBinding('class.large')
  get isLarge() {
    return this.size === 'large';
  }

  @HostBinding('class.small')
  get isSmall() {
    return this.size === 'small';
  }
}
