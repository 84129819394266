import { Injectable } from '@angular/core'

import {
  ClientStoreInterface,
  NomenclatorServiceInterface,
} from './dictionary/flx-nomenclator.dictionary'
import { FlxProcessModule } from './flx-process.module'

@Injectable({
  // TODO: See why components can't inject services declared in the FlxProcessModule
  providedIn: 'root',
})
export class FlxClientStoreRepository {
  private _clientStore = null

  set store(clientStore: ClientStoreInterface) {
    this._clientStore = clientStore
  }

  get store(): ClientStoreInterface {
    if (!this._clientStore) {
      throw new Error(
        `FlxClientStoreRepository: Can't get client client store (did you forget to set it?)`
      )
    }

    return this._clientStore
  }
}
