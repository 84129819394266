import { FormControl } from '@angular/forms'
import { NomenclatorValue } from './flx-nomenclator.dictionary'
import { StatusType } from 'paperflow-web-components/lib/shared/shared.dictionary'
import { Observable } from 'rxjs'
import { ModalConfig } from 'paperflow-web-components'

export interface INotification {
  identifier: string
  componentIdentifier: 'MESSAGE'
  displayOptions?: MessageDisplayOptions
  show: boolean
}

export interface TemplateConfigInterface {
  id: number
  key: string
  order: number
  type: TemplateConfigType
  componentIdentifier: ComponentIdentifierEnum
  nodeDefinitionId?: number
  processInstanceUuid?: string
  uiTemplateParentId?: number
  canGoBack?: boolean
  formFields?: FieldConfigInterface[]
  displayOptions?: DisplayOptions
  templateConfig?: TemplateConfigInterface[]
  expressions: Expressions
  action?: any
  inputKeys: string[]
  rootTemplateConfigId?: number
  parentTemplateConfigId?: number
}

export interface FieldConfigInterface {
  id: number
  key: string
  type: string
  control: FormControl
  componentIdentifier: string
  processInstanceUuid?: string
  options?: any
  displayOptions?: DisplayOptions
  dataSource?: DataSource
  expressions?: Expressions
  validators?: FlxValidators
  dismissProcess?: boolean
  action?: any
  actionFn?: any
  status?: StatusType
}

export interface FormConfigInterface {
  formId: string
  formFields: any
  processInstanceUuid: string
  displayOptions?: DisplayOptionsInterface
  expressions?: Expressions
  layoutAlign?: string
}

export interface DisplayOptionsInterface {
  flowxProps: {
    closeOnBackdropClick: boolean
    showClear: boolean
    inputType: 'string' | 'boolean' | 'object'
    type?: 'fill' | 'border' | 'flat'
    selectType: 'default' | 'custom'
    status?: StatusType
    label?: string
    disabled?: any
    placeholder?: string
    leftIconName?: string
    rightIconName?: string
    backdropClass?: string
    panelClass?: string
    maxChar?: number
    minChar?: number
    required?: boolean
    messageType: 'warning' | 'error' | 'info'
    hintType: 'warning' | 'error' | 'success'
    text: string
    fillLayout: boolean
    mask?: string
    replaceChar?: string
    message?: string
    prefix: string
    suffix: string
    emptyMessage: string
    validateOn: ValidateOn
  }
  style: any
  flexLayout: {
    fxLayout: FxLayoutEnum
    fxLayoutAlign: FxAlignLayoutEnum
    fxLayoutGap: string
  }
  class?: any
}

export interface ICommonDisplayOptions {
  flowxProps: {
    showClear: boolean
    inputType: 'string' | 'boolean' | 'object'
    type?: 'fill' | 'border' | 'flat'
    selectType: 'default' | 'custom'
    status?: StatusType
    label?: string
    disabled?: any
    placeholder?: string
    leftIconName?: string
    rightIconName?: string
    maxChar?: number
    minChar?: number
    required?: boolean
    messageType: 'warning' | 'error' | 'info'
    hintType: 'warning' | 'error' | 'success'
    text: string
    fillLayout: boolean
    mask?: string
    replaceChar?: string
    message?: string
    prefix: string
    suffix: string
    emptyMessage: string
    validateOn: ValidateOn
  }
  style: any
  flexLayout: {
    fxLayout: FxLayoutEnum
    fxLayoutAlign: FxAlignLayoutEnum
    fxLayoutGap: string
  }
  class?: any
}

// TODO Pull specific props from ICommonDisplayOptions in component interface
export interface FileUploadDisplayOptions {
  flowxProps: {
    mode: 'basic' | 'advanced'
    accept: string | boolean
    auto: boolean
    multiple: boolean
    fileLimit: number
    maxFileSize: number
  }
}

export interface MessageDisplayOptions {
  flowxProps: {
    messageType: string
    text: string
  }
}

export interface ImageDisplayOptions {
  flowxProps: {
    imageSrc: string
  }
}

export interface InfoTooltipDisplayOptions {
  flowxProps: {
    text: string
    tooltipColor: string
    tooltipPosition: string
  }
}
export interface TextareaDisplayOptions {
  flowxProps: {
    minRows: number
    maxRows: number
  }
}
export interface DatepickerDisplayOptions {
  flowxProps: {
    minDate: string
    maxDate: string
    matDatepickerMax: string
    matDatepickerMin: string
  }
}

export type DisplayOptions = ICommonDisplayOptions &
  FileUploadDisplayOptions &
  ImageDisplayOptions &
  InfoTooltipDisplayOptions &
  DatepickerDisplayOptions &
  TextareaDisplayOptions

export interface DataSource {
  // optional until the migration is done and sourceType
  // will be the only source of truth
  sourceType?: DataSourceType
  defaultValue?: any
  options?: NomenclatorValue[]

  nomenclator?: {
    name: string | number
    parentKey?: string
  }

  processData?: {
    key: string
    parentKey?: string
  }
}

export type Expressions = {
  hide?: string
}
export type FlxValidators = Record<string, FlxValidator>
export type FlxValidator = { message: string; params?: any[]; type?: 'async' | 'sync' }
export type ValidateOn = 'blur' | 'submit'

export interface FieldInterface {
  config: FieldConfigInterface
}

export interface IStep extends TemplateConfigInterface {
  completed: boolean
}

export type ModalComponentMetadata = {
  componentIdentifier: string
  displayOptions: ModalConfig
}

export interface FlxFormGroup {
  templateConfig: TemplateConfigInterface
  isDisabled$: Observable<boolean>
  isCurrent$: Observable<boolean>
}

export enum ComponentIdentifierEnum {
  PAGE = 'PAGE',
  STEPPER = 'STEPPER',
  MODAL = 'MODAL',
  STEP = 'STEP',
  CONTAINER = 'CONTAINER',
  FORM_GROUP = 'FORM_GROUP',
  FORM = 'FORM',
  INPUT = 'INPUT',
  BUTTON = 'BUTTON',
  IMAGE = 'IMAGE',
  TEXT = 'TEXT',
  HINT = 'HINT',
  LINK = 'LINK',
  FILE_UPLOAD = 'FILE_UPLOAD',
  MESSAGE = 'MESSAGE',
  INFO_TOOLTIP = 'INFO_TOOLTIP',
  TEXTAREA = 'TEXTAREA',
}

export enum TemplateConfigType {
  CUSTOM = 'CUSTOM',
  FLOWX = 'FLOWX',
}

export enum DataSourceType {
  STATIC = 'static',
  ENUMERATION = 'enumeration',
  PROCESS_DATA = 'processData',
}

export enum FxLayoutEnum {
  COLUMN = 'column',
  ROW = 'row',
  ROW_WRAP = 'row wrap',
}

export enum FxAlignLayoutEnum {
  START_CENTER = 'start center',
  END_CENTER = 'end center',
}

export enum VALIDATOR_TYPE {
  SYNC = 'sync',
  ASYNC = 'async',
}
