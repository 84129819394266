import { Component, Input, OnInit } from '@angular/core'
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser'
import { Observable, of } from 'rxjs'
import { map, take } from 'rxjs/operators'

import { DEFAULT_PLACEHOLDER_URL, ImageComponentData } from './flx-image.dictionary'
import { processDataValueFromPath$ } from '../../store/process-data.store'
import { FlxWrapperComponent } from '../../flx-wrapper'

@Component({
  selector: 'flx-image',
  templateUrl: './flx-image.component.html',
  styleUrls: ['./flx-image.component.css'],
})
export class FlxImageComponent extends FlxWrapperComponent<ImageComponentData> implements OnInit {
  imageSrc$: Observable<SafeResourceUrl>

  get getImageStyle(): Record<string, string> {
    return this.config.displayOptions.style || ''
  }

  constructor(private sanitizer: DomSanitizer) {
    super()
  }

  ngOnInit(): void {
    this.imageSrc$ = this.getImageSrc$()
  }

  getImageSrc$(): Observable<SafeResourceUrl> {
    if (this.config.key) {
      return processDataValueFromPath$(this.config.processInstanceUuid, this.config.key).pipe(
        map((img) => {
          if (img) {
            return this.sanitizeBase64ImageData(img as string)
          } else {
            return this.sanitizer.bypassSecurityTrustResourceUrl(DEFAULT_PLACEHOLDER_URL)
          }
        })
      )
    } else if (this.config.displayOptions.flowxProps.imageSrc) {
      return of(
        this.sanitizer.bypassSecurityTrustResourceUrl(
          this.config.displayOptions.flowxProps.imageSrc
        )
      )
    } else {
      return of(this.sanitizer.bypassSecurityTrustResourceUrl(DEFAULT_PLACEHOLDER_URL))
    }
  }

  getDefaultPlaceholder(): SafeResourceUrl {
    return this.sanitizer.bypassSecurityTrustResourceUrl(DEFAULT_PLACEHOLDER_URL)
  }

  private sanitizeBase64ImageData(imgUrl: string): SafeResourceUrl {
    return this.sanitizer.bypassSecurityTrustResourceUrl(`data:image/png;base64, ${imgUrl}`)
  }
}
