import { Component, Input, ElementRef, Renderer2, Inject } from '@angular/core'
import { DOCUMENT } from '@angular/common'

import { IconRegistryService } from '../service/icon-registry.service'

@Component({
  selector: 'ppf-icon',
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.scss'],
})
export class IconComponent {
  private svgElement: SVGElement

  @Input()
  set name(iconName: string) {
    if (this.svgElement) {
      this.svgElement.remove()
    }

    const svgString = this.iconRegistry.getIcon(iconName)
    this.svgElement = this.stringToSVGElement(svgString)

    this.renderer.appendChild(this.element.nativeElement, this.svgElement)
  }

  constructor(
    private element: ElementRef,
    private renderer: Renderer2,
    private iconRegistry: IconRegistryService,
    @Inject(DOCUMENT) private document: any
  ) {}

  private stringToSVGElement(value: string): SVGElement {
    const dummyParent: HTMLElement = this.document.createElement('div')
    dummyParent.innerHTML = value

    const svgElement = dummyParent.querySelector('svg')

    if (!svgElement) {
      throw Error(
        `Can't create SVG element from provided string! Make sure the registered Icon is a SVG Element!`
      )
    }

    return svgElement
  }
}
