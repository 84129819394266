import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { InputComponent } from './input.component'
import { IconModule } from '../icon/icon.module'
import { SharedModule } from '../shared/shared.module'
import { message, play, pause, user, error, success, warning } from '../icon/ppfIcons'
import { FormsModule } from '@angular/forms'

@NgModule({
  declarations: [InputComponent],
  imports: [CommonModule, IconModule.forChild({ icons: [message, play, pause, user, error, success, warning] }), SharedModule, FormsModule],
  exports: [InputComponent],
})
export class InputModule {}
