import {
  Component,
  Input,
  HostBinding,
  ContentChild,
  AfterViewInit,
  ChangeDetectionStrategy,
} from '@angular/core'
import { InputRefDirective } from '../shared/input.directive'
import { InputMaskRefDirective } from '../shared/input-mask.directive'
import { DEFAULT_INPUT_TYPE } from './input.dictionary'
import { StatusType, FieldType, INPUT_TYPES, FIELD_BORDER } from '../shared/shared.dictionary'

@Component({
  selector: 'ppf-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InputComponent implements AfterViewInit {
  @Input() label: string
  @Input() type: FieldType = DEFAULT_INPUT_TYPE
  @Input() status: StatusType
  @Input() prefix: string
  @Input() suffix: string

  @ContentChild(InputRefDirective) input: InputRefDirective = null
  @ContentChild(InputMaskRefDirective) inputMask: InputMaskRefDirective = null

  @HostBinding('class.disabled-state')
  get isDisabled() {
    if (this.input) {
      return this.input.isDisabled
    }

    if (this.inputMask) {
      return this.inputMask.isDisabled
    }
  }

  @HostBinding('class.focus-state')
  get isFocus() {
    if (this.input) {
      return this.input.focus
    }

    if (this.inputMask) {
      return this.inputMask.focus
    }
  }

  @HostBinding('class.focus-icon-disabled')
  get hasOpacity() {
    if (this.input) {
      return !this.input._hasValue
    }
  }

  // When float-label class is attached, the label floats (overlaps with) the input content.
  @HostBinding('class.float-label')
  get floatLabel() {
    if (this.input) {
      return !this.input._hasValue && !this.input.focus
    }

    if (this.inputMask) {
      return !this.inputMask.focus && !this.inputMask.valid
    }
  }

  ngAfterViewInit(): void {
    if (!this.input && !this.inputMask) {
      throw Error(
        'You must provide a child input using `ppfInput` or `ppfInputMask` Directive to ppf-input!'
      )
    }
  }

  getTypeClass() {
    return `${INPUT_TYPES[this.type]} ${this.status ? FIELD_BORDER[this.status] : ''} ${
      this.label ? 'has-label' : ''
    }`
  }
}
