import { Component, OnInit } from '@angular/core'
import { FormGroup } from '@angular/forms'
import { FlxFileSelectEvent } from 'paperflow-web-components'
import { getProcessActionByTemplateId$ } from '../../../store/process-actions.store'
import { ProcessAction } from '../../../dictionary/flx-process.dictionary'
import { FlxProcessService } from '../../../services/flx-process.service'
import { Observable } from 'rxjs'
import { tap } from 'rxjs/operators'
import { FlxWrapperComponent } from '../../../flx-wrapper'

@Component({
  selector: 'flx-fileupload-wrapper',
  templateUrl: './fileupload-wrapper.component.html',
  styleUrls: ['./fileupload-wrapper.component.scss'],
})
export class FileUploadWrapperComponent extends FlxWrapperComponent implements OnInit {
  public form: FormGroup
  public errors: any
  public action$: Observable<ProcessAction>
  private actionFn: (formData) => Observable<any>

  constructor(private flxProcessService: FlxProcessService) {
    super()
  }

  ngOnInit(): void {
    this.action$ = getProcessActionByTemplateId$(
      this.config.processInstanceUuid,
      this.config.id
    ).pipe(
      tap((processAction) => {
        if (processAction) {
          this.actionFn = this.flxProcessService.buildAction(
            this.config.processInstanceUuid,
            processAction
          )
        }
      })
    )
  }

  get mode(): string {
    return this.config?.displayOptions?.flowxProps?.mode || 'basic'
  }

  get type(): string {
    return this.config?.displayOptions?.flowxProps?.type || 'fill'
  }

  get accept(): string | boolean {
    return this.config?.displayOptions?.flowxProps?.accept || false
  }

  get maxFileSize(): number {
    return this.config?.displayOptions?.flowxProps?.maxFileSize
  }

  get fileLimit(): number {
    return this.config?.displayOptions?.flowxProps?.fileLimit
  }

  onSelectFile(event: FlxFileSelectEvent): void {
    if (!this.actionFn) {
      throw new Error(`No action defined for FILE UPLOAD with the key ${this.config.key}`)
    }

    this.errors = Object.keys(event.errors).map((error) => {
      return { message: this.config?.displayOptions?.flowxProps[error] || event.errors[error] }
    })
    const formData = new FormData()

    if (event.validFiles?.length) {
      if (!this.config?.displayOptions?.flowxProps?.multiple) {
        formData.append('file', event.validFiles[0])
      } else {
        // TODO come back to this when multiple files can be uploaded
        // formData.append('files', event.validFiles)
      }
      this.actionFn(formData).subscribe()
    }
  }
}
