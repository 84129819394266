export const message: PpfIcon = {
  name: 'message',
  data: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
    <path d="M1 0h14a1 1 0 0 1 1 1v10a1 1 0 0 1-1 1h-5l-6 4v-4H1a1 1 0 0 1-1-1V1a1 1 0 0 1 1-1z" />
  </svg>`,
}

export const next: PpfIcon = {
  name: 'next',
  data: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
    <path d="M-1960 15V1a1 1 0 0 1 1.6-.8l10 7a1.041 1.041 0 0 1 0 1.6l-10 7a1.034 1.034 0 0 1-.6.2 1 1 0 0 1-1-1zm14 0V1a.944.944 0 0 1 1-1 .944.944 0 0 1 1 1v14a.945.945 0 0 1-1 1 .945.945 0 0 1-1-1z" transform="translate(1960)"/>
  </svg>`,
}

export const pause: PpfIcon = {
  name: 'pause',
  data: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 14">
    <path d="M-1950 14a.945.945 0 0 1-1-1V1a.945.945 0 0 1 1-1h3a.945.945 0 0 1 1 1v12a.945.945 0 0 1-1 1zm-9 0a.945.945 0 0 1-1-1V1a.945.945 0 0 1 1-1h3a.945.945 0 0 1 1 1v12a.945.945 0 0 1-1 1z" transform="translate(1960)"/>
  </svg>`,
}

export const play: PpfIcon = {
  name: 'play',
  data: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 11.975 16">
    <path d="M13.6 7.2l-10-7A1 1 0 0 0 2 1v14a1 1 0 0 0 1.6.8l10-7a1.041 1.041 0 0 0 0-1.6z" transform="translate(-2)"/>
  </svg>`,
}

export const stop: PpfIcon = {
  name: 'stop',
  data: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 14">
    <path d="M14 1H2a.945.945 0 0 0-1 1v12a.945.945 0 0 0 1 1h12a.945.945 0 0 0 1-1V2a.945.945 0 0 0-1-1z" transform="translate(-1 -1)"/>
  </svg>`,
}

export const checkmark: PpfIcon = {
  name: 'checkmark',
  data: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 11.41 9.107">
    <path fill="currentColor" d="M16.97-16.06a1.5 1.5 0 0 0-2.121 0L9.8-11.014l-1.24-1.242a1.5 1.5 0 0 0-2.121 0 1.5 1.5 0 0 0 0 2.121l2.3 2.3a1.5 1.5 0 0 0 2.121 0l6.106-6.106a1.5 1.5 0 0 0 0-2.121"  transform="translate(-6 16.5)"/>
  </svg>`,
}

export const user: PpfIcon = {
  name: 'user',
  data: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 16">
    <path d="M-1960 16a4.952 4.952 0 0 1 5-5h4a4.952 4.952 0 0 1 5 5zm3-11V4a4.012 4.012 0 0 1 4-4 4.012 4.012 0 0 1 4 4v1a4.012 4.012 0 0 1-4 4 4.012 4.012 0 0 1-4-4z" transform="translate(1960)"/>
  </svg>`,
}

export const error: PpfIcon = {
  name: 'error',
  data: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
    <path d="M8 0a8 8 0 1 0 8 8 8.024 8.024 0 0 0-8-8zm0 12a.945.945 0 0 1-1-1 .945.945 0 0 1 1-1 .945.945 0 0 1 1 1 .945.945 0 0 1-1 1zm1-3H7V4h2z" />
  </svg>`,
}

export const success: PpfIcon = {
  name: 'success',
  data: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
    <path d="M8 0a8 8 0 1 0 8 8 8.024 8.024 0 0 0-8-8zM7 11.4L3.6 8 5 6.6l2 2 4-4L12.4 6z" />
  </svg>`,
}

export const warning: PpfIcon = {
  name: 'warning',
  data: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14.67 13.875">
    <path d="M15.216 13.529L8.882 1.654a1 1 0 0 0-1.765 0L.784 13.529A1 1 0 0 0 1.667 15h12.667a1 1 0 0 0 .882-1.471zM8 13a1 1 0 1 1 1-1 1 1 0 0 1-1 1zm1-3H7V6h2z" transform="translate(-.665 -1.125)"/>
  </svg>`,
}

export const arrow: PpfIcon = {
  name: 'arrow',
  data: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15.4 9.2">
    <path d="M8 12.6L.3 4.9l1.4-1.5L8 9.7l6.3-6.3 1.4 1.5z"  transform="translate(-.3 -3.4)"/>
  </svg>`,
}

export const menu: PpfIcon = {
  name: 'menu',
  data: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 4 18">
    <g fill-rule="nonzero">
        <circle cx="2" cy="9" r="2" transform="rotate(90 2 9)"/>
        <circle cx="2" cy="2" r="2" transform="rotate(90 2 2)"/>
        <circle cx="2" cy="16" r="2" transform="rotate(90 2 16)"/>
    </g>
  </svg>`,
}

export const close: PpfIcon = {
  name: 'close',
  data: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 14">
    <path d="M14.7 1.3a.967.967 0 0 0-1.4 0L8 6.6 2.7 1.3a.967.967 0 0 0-1.4 0 .967.967 0 0 0 0 1.4L6.6 8l-5.3 5.3a.967.967 0 0 0 0 1.4.908.908 0 0 0 .7.3.908.908 0 0 0 .7-.3L8 9.4l5.3 5.3a.967.967 0 0 0 1.4 0 .967.967 0 0 0 0-1.4L9.4 8l5.3-5.3a.967.967 0 0 0 0-1.4z" transform="translate(-1 -1)"/>
  </svg>`,
}

export const info: PpfIcon = {
  name: 'info',
  data: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
    <path d="M16 0a16 16 0 1 0 16 16A16.019 16.019 0 0 0 16 0zm2 7a2 2 0 1 1-2 2 2 2 0 0 1 2-2zm1.763 17.046a8.37 8.37 0 0 1-3.518.954 3.26 3.26 0 0 1-2.225-.7c-1.045-.92-.869-2.248-.542-3.608l.984-3.483c.19-.717.575-2.182.036-2.7s-1.794-.189-2.524.083l.263-1.073a10.271 10.271 0 0 1 3.523-.954c1.71 0 2.961.855 2.961 2.469q0 .227-.053.8c-.066.7-.086.655-1.178 4.521a8.782 8.782 0 0 0-.311 1.765c0 1.683 1.957 1.267 2.847.847z"/>
  </svg>`,
}

export const minus: PpfIcon = {
  name: 'minus',
  data: `<svg xmlns="http://www.w3.org/2000/svg"" viewBox="0 0 459.313 459.314"
	<g>
	<path d="M459.313,229.648c0,22.201-17.992,40.199-40.205,40.199H40.181c-11.094,0-21.14-4.498-28.416-11.774
		C4.495,250.808,0,240.76,0,229.66c-0.006-22.204,17.992-40.199,40.202-40.193h378.936
		C441.333,189.472,459.308,207.456,459.313,229.648z"/>
</g>
</svg>
`,
}

export const calendar: PpfIcon = {
  name: 'calendar',
  data: `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 12 12">
    <g data-name="calendar (5)">
        <path id="Path_15155" fill="currentColor" d="M11 2h-1V0H8v2H4V0H2v2H1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1V3a1 1 0 0 0-1-1zm-1 8H2V5h8z" data-name="Path 15155"/>
    </g>
</svg>
`,
}

export const playVertical: PpfIcon = {
  name: 'playVertical',
  data: `<svg xmlns="http://www.w3.org/2000/svg" width="8.609" height="6.457" viewBox="0 0 8.609 6.457">
    <path id="play" fill="#414751" d="M6.255 3.874L.863.108A.539.539 0 0 0 0 .538v7.533a.539.539 0 0 0 .863.43l5.392-3.766a.559.559 0 0 0 0-.861z" transform="rotate(90 4.304 4.305)"/>
</svg>`,
}

export const drag: PpfIcon = {
  name: 'drag',
  data: `<svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16">
    <g id="Group_19686" transform="translate(632 6229)">
        <circle id="Ellipse_777" cx="2" cy="2" r="2" transform="translate(-632 -6229)"/>
        <circle id="Ellipse_780" cx="2" cy="2" r="2" transform="translate(-632 -6223)"/>
        <circle id="Ellipse_782" cx="2" cy="2" r="2" transform="translate(-632 -6217)"/>
        <circle id="Ellipse_778" cx="2" cy="2" r="2" transform="translate(-626 -6229)"/>
        <circle id="Ellipse_779" cx="2" cy="2" r="2" transform="translate(-626 -6223)"/>
        <circle id="Ellipse_781" cx="2" cy="2" r="2" transform="translate(-626 -6217)"/>
    </g>
</svg>
`,
}

export type PpfIconTypes =
  | 'message'
  | 'next'
  | 'pause'
  | 'play'
  | 'stop'
  | 'user'
  | 'checkmark'
  | 'error'
  | 'success'
  | 'warning'
  | 'arrow'
  | 'menu'
  | 'close'
  | 'info'
  | 'minus'
  | 'calendar'
  | 'playVertical'

export interface PpfIcon {
  name: string
  data: string
}
