import {
  Directive,
  HostListener,
  HostBinding,
  Input,
  ElementRef,
  AfterViewInit,
  DoCheck,
} from '@angular/core'

@Directive({
  selector: '[ppfInput]',
})
export class InputRefDirective implements AfterViewInit, DoCheck {
  focus = false
  public _hasValue = false

  constructor(private el: ElementRef) {}

  @Input()
  ppfDisabled: boolean

  @HostBinding('disabled')
  get isDisabled() {
    return typeof this.ppfDisabled !== 'undefined'
      ? this.ppfDisabled
      : this.el.nativeElement.disabled
  }

  @HostListener('focus')
  onFocus() {
    this.focus = true
  }

  @HostListener('blur')
  onBlur() {
    this.focus = false
    this._hasValue = this.hasValue()
  }

  ngAfterViewInit(): void {
    this._hasValue = this.hasValue()
  }

  hasValue(): boolean {
    return !!this.el.nativeElement.value.length
  }

  ngDoCheck(): void {
    this._hasValue = !!this.el.nativeElement.value.length
  }
}
