import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { CdkTableModule } from '@angular/cdk/table'

import { TableComponent } from './table.component'

const EXPORTED_DECLARATIONS = [
  // Simple table
  TableComponent,
]

@NgModule({
  declarations: EXPORTED_DECLARATIONS,
  imports: [CommonModule, CdkTableModule],
  exports: [EXPORTED_DECLARATIONS],
})
export class TableModule {}
