import { Component, EventEmitter, Output, Optional } from '@angular/core'
import { ModalRef } from './modal'

@Component({
  selector: 'ppf-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
})
export class ModalComponent {
  constructor(@Optional() private readonly modalRef: ModalRef) {}

  @Output()
  close = new EventEmitter<any>()

  handleClose() {
    this.modalRef?.close()

    if (!this.modalRef) {
      this.close.emit()
    }
  }
}
