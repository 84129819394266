import { Component, Input, HostBinding } from '@angular/core'
import { Highlightable } from '@angular/cdk/a11y'

@Component({
  selector: 'ppf-select-item',
  template: `<ng-content></ng-content>`,
})
export class SelectItemComponent implements Highlightable {
  @Input() item
  private _isActive = false

  @HostBinding('class.active') get isActive() {
    return this._isActive
  }

  setActiveStyles() {
    this._isActive = true
  }

  setInactiveStyles() {
    this._isActive = false
  }

  getLabel(): string {
    return this.item.value
  }
}
