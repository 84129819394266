export type AvatarType = 'circle' | 'square';
export type AvatarSize = 'small' | 'large';
export type AvatarStatus = 'online' | 'idle' | 'offline';
export type AvatarPosition = 'topRight' | 'bottomRight';
export type AvatarBackground = 'text' | 'icon' | 'image';

export const AVATAR_CONSTANTS = {
    BACKGROUND_TEXT: 'text',
    BACKGROUND_ICON: 'icon',
    BACKGROUND_IMAGE: 'image'
};
