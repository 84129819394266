import {
  Component,
  Input,
  forwardRef,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
} from '@angular/core'
import { DEFAULT_INPUT_TYPE } from '../input/input.dictionary'
import { FieldType, StatusType } from '../shared/shared.dictionary'
import {
  AbstractControl,
  ControlValueAccessor,
  FormControl,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
} from '@angular/forms'
import moment from 'moment'

@Component({
  selector: 'ppf-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DatePickerComponent),
      multi: true,
    },
    { provide: NG_VALIDATORS, useExisting: forwardRef(() => DatePickerComponent), multi: true },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DatePickerComponent implements ControlValueAccessor, Validator {
  @Input() label: string
  @Input() disabled: boolean
  @Input() placeholder: string
  @Input() type: FieldType = DEFAULT_INPUT_TYPE
  @Input() status: StatusType
  @Input() minDate: Date
  @Input() maxDate: Date
  @Input() format = 'DD.MM.YYYY'
  dateForm: FormControl = new FormControl()

  constructor(private cd: ChangeDetectorRef) {}

  private onValidationChange(value: any) {}

  private onChange(value: any) {
    this.cd.detectChanges()
    return value
  }

  private onTouch() {}

  registerOnChange(fn: any) {
    this.onChange = fn
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn
  }

  writeValue(value: any): void {
    this.dateForm.setValue(Boolean(value) ? moment(value, this.format) : value)
    this.cd.markForCheck()

    if (!value) {
      return
    }

    this.onChange(moment(this.dateForm.value).format(this.format))
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled
    this.cd.markForCheck()
  }

  public onDate(event): void {
    this.onChange(moment(event.value).format(this.format))
  }

  validate(control: AbstractControl): ValidationErrors | null {
    return this.dateForm.errors
  }

  registerOnValidatorChange?(fn: () => void): void {
    this.onValidationChange = fn
  }
}
