import { Component, Input, ViewChild, ElementRef, AfterViewInit, ContentChild, HostBinding } from '@angular/core'
import { RadioRefDirective } from './radio.directive'
import { RadioType, RADIO_CONSTANTS } from './radio.dictionary'

@Component({
  selector: 'ppf-radio',
  templateUrl: './radio.component.html',
  styleUrls: ['./radio.component.scss'],
})
export class RadioComponent implements AfterViewInit {
  @Input() label: string
  @Input() type: RadioType = RADIO_CONSTANTS.DEFAULT_RADIO_TYPE as RadioType
  @ContentChild(RadioRefDirective) radio: RadioRefDirective = null

  @HostBinding('class')
  get classes() {
    return this.type
  }

  RADIO_CONSTANTS = RADIO_CONSTANTS

  ngAfterViewInit(): void {
    if (!this.radio) {
      throw Error(
        `You must provide a child input of type 'radio' using 'ppfRadio' directive to ppf-radio!`,
      )
    }
  }
}
