import {
  Directive,
  HostBinding,
  Input,
  HostListener,
  ElementRef,
  OnInit,
  DoCheck,
  ChangeDetectorRef
} from '@angular/core'

@Directive({
  selector: '[ppfRadio]',
})
export class RadioRefDirective implements OnInit, DoCheck {
  @Input()
  checked: boolean

  @Input() disabled: boolean


  @HostBinding('disabled')
  get isDisabled() {
    return typeof this.disabled !== 'undefined' ? this.disabled : this.el.nativeElement.disabled
  }

  @HostListener('click')
  onClick() {
    this.checked = this.el.nativeElement.checked
    this.cdRef.detectChanges()
  }

  constructor(private el: ElementRef, private cdRef: ChangeDetectorRef) {}

  ngOnInit() {
    this.checkHost()
    this.el.nativeElement.checked = !!this.checked
  }

  ngDoCheck() {
    if (this.el.nativeElement.checked !== this.checked) {
      this.checked = this.el.nativeElement.checked
    }
  }

  private checkHost() {
    if (this.el.nativeElement.tagName !== 'INPUT') {
      throw Error(`ppfRadio directive works only on an input element`)
    }

    if (this.el.nativeElement.type !== 'radio') {
      throw Error(`ppfRadio directive works only on a radio input element`)
    }
  }
}
