import { Observable } from 'rxjs'
import { map, publishReplay, refCount } from 'rxjs/operators'
import { sortBy as _sortBy } from 'lodash/fp'

import * as FlxEnumerationsGateway from './flx-enumerations.gateway'
import { NomenclatorValue } from '../dictionary/flx-nomenclator.dictionary'
import { FlxEnumerationsRequestOptions } from '../dictionary/flx-enumeration.dictionary'

export enum APPLICATIONS {
  FLOWX = 'flowx',
}

export enum ENUMERATION_TYPES {
  SUBSTITUTION_TAGS = 'substitutionTags',
}

type EnumerationsCache = Record<string, Observable<NomenclatorValue[]>>

const enumerationsCache: EnumerationsCache = {}

export function get(
  name: number | string,
  options?: { language: string }
): Observable<NomenclatorValue[]> {
  const nameString = name as string

  const _options = {
    name: nameString,
    application: APPLICATIONS.FLOWX,
    language: options?.language || 'ro-RO',
  }
  const enumCacheKey = buildEnumCacheKey(_options)

  if (!enumerationsCache[enumCacheKey]) {
    enumerationsCache[enumCacheKey] = FlxEnumerationsGateway.get(_options).pipe(
      map((enums) =>
        _sortBy('order', enums).map(
          (enumeration) =>
            ({
              label: enumeration.content,
              id: enumeration.code,
              value: enumeration.code,
              nomenclatorId: enumeration.childContentDescription?.name,
            } as unknown as NomenclatorValue)
        )
      ),

      publishReplay(1),
      refCount()
    )
  }

  return enumerationsCache[enumCacheKey]
}

function buildEnumCacheKey(requestOptions: Partial<FlxEnumerationsRequestOptions>): string {
  return Object.keys(requestOptions).reduce((acc, option) => {
    return `${acc}-${requestOptions[option]}`
  }, '')
}
