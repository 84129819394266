import { ChangeDetectorRef, Component, DoCheck, OnInit } from '@angular/core'
import { FlxWrapperComponent } from '../../../flx-wrapper'

@Component({
  selector: 'flx-textarea-wrapper',
  templateUrl: './textarea-wrapper.component.html',
  styleUrls: ['./textarea-wrapper.component.scss'],
})
export class TextareaWrapperComponent extends FlxWrapperComponent implements DoCheck {
  constructor(private cdr: ChangeDetectorRef) {
    super()
  }

  ngDoCheck(): void {
    // TODO Find a better way to do this.
    this.cdr.detectChanges()
  }
}
