import { Component, OnInit } from '@angular/core'
import { FormGroup } from '@angular/forms'
import { Observable } from 'rxjs'

import { getOptionsObs } from '../../../services/options-field-helper'
import { FlxNomenclatorRepository } from '../../../flx-nomenclator.repository'
import { FlxWrapperComponent } from '../../../flx-wrapper'

@Component({
  selector: 'flx-radio-wrapper',
  templateUrl: './radio-wrapper.component.html',
  styleUrls: ['./radio-wrapper.component.scss'],
})
export class RadioWrapperComponent extends FlxWrapperComponent implements OnInit {
  options$: Observable<any>

  constructor(private nomenclatorRepository: FlxNomenclatorRepository) {
    super()
  }

  ngOnInit(): void {
    const parentFormGroup = this.config.control.parent as FormGroup

    this.options$ = this.options$ = getOptionsObs(
      this.config.dataSource,
      parentFormGroup,
      this.nomenclatorRepository,
      this.config.processInstanceUuid
    )
  }

  trackByFn(index, item): number {
    return item.label
  }
}
