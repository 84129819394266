import { ProcessAction } from '../dictionary/flx-process.dictionary'
import { BehaviorSubject, Observable } from 'rxjs'
import { distinctUntilChanged, filter, map } from 'rxjs/operators'
import { isEqual as _isEqual } from 'lodash/fp'

export type ProcessActionsStore = {
  [processInstanceUuid: string]: ProcessAction[]
}

const processActionsStore = new BehaviorSubject<ProcessActionsStore>({})

export const processActions$ = processActionsStore.asObservable()

export function addProcessActions(processInstanceUuid: string, actions: ProcessAction[]): void {
  const state = processActionsStore.value

  processActionsStore.next({
    ...state,
    [processInstanceUuid]: [
      ...(state[processInstanceUuid] ? state[processInstanceUuid] : []),
      ...actions,
    ],
  })
}

export function getProcessActionByTemplateId$(
  processInstanceUuid: string,
  uiTemplateId: number
): Observable<ProcessAction> {
  return processActions$.pipe(
    map((processActions) => {
      return processActions[processInstanceUuid]?.find(
        (action) => action.uiTemplateId === uiTemplateId
      )
    }),
    distinctUntilChanged((prev, curr) => _isEqual(prev, curr))
  )
}

export function processActionsByTemplateId$(
  processInstanceUuid: string,
  uiTemplateId: number
): Observable<ProcessAction[]> {
  return processActions$.pipe(
    map((processActions) => {
      const templateActions = processActions[processInstanceUuid]?.filter(
        (action) => action.uiTemplateId === uiTemplateId
      )
      return templateActions?.length ? templateActions : []
    }),
    filter((processActions) => processActions !== undefined),
    distinctUntilChanged((prev, curr) => _isEqual(prev, curr))
  )
}

export function resetActions(): void {
  processActionsStore.next({})
}
