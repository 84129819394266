import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { CheckboxComponent } from './checkbox.component'
import { IconModule } from '../icon/icon.module'
import { CheckboxRefDirective } from './checkbox.directive'
import { checkmark } from '../icon/ppfIcons'

@NgModule({
  declarations: [CheckboxComponent, CheckboxRefDirective],
  imports: [CommonModule, IconModule.forChild({ icons: [checkmark] })],
  exports: [CheckboxComponent, CheckboxRefDirective],
})
export class CheckboxModule {}
