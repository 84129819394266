import { Injectable, Injector } from '@angular/core'
import { Subscription } from 'rxjs'
import { Overlay, OverlayRef } from '@angular/cdk/overlay'
import { ComponentPortal, PortalInjector } from '@angular/cdk/portal'

import { ModalConfig, MODAL_DATA, ModalData, ModalComponentType } from './modal.dictionary'
import { ModalRef } from './modal'

@Injectable({
  providedIn: 'root',
})
export class ModalService {
  constructor(private overlay: Overlay, private readonly parentInjector: Injector) {}

  private overlayRef: OverlayRef | null = null
  private subscriptions: Subscription = new Subscription()

  public show(component: ModalComponentType, config?: ModalConfig, data?: ModalData): ModalRef {
    this.overlayRef = this.createCDKOverlay(config)

    const modalRef = new ModalRef(this.overlayRef)
    const injector = this.getInjector(data, modalRef, this.parentInjector)
    const modalPortal = new ComponentPortal(component, null, injector)
    const componentRef = this.overlayRef.attach(modalPortal)

    if (config.closeOnBackdropClick) {
      this.setupCloseOnBackdropClick()
    }

    modalRef.data$ = componentRef.instance.dataEventEmitter
    modalRef.componentRef = componentRef

    return modalRef
  }

  public hide() {
    this.subscriptions.unsubscribe()
    this.overlayRef?.dispose()
  }

  private createCDKOverlay(config?: ModalConfig): OverlayRef {
    const positionStrategy = this.overlay
      .position()
      .global()
      .centerHorizontally()
      .centerVertically()
    return this.overlay.create({
      positionStrategy,
      scrollStrategy: this.overlay.scrollStrategies.block(),
      panelClass:
        config && config.panelClass ? [config.panelClass, 'ppf-modal-panel'] : ['ppf-modal-panel'],
      hasBackdrop: true,
      backdropClass:
        config && config.backdropClass
          ? [config.backdropClass, 'ppf-modal-backdrop']
          : ['ppf-modal-backdrop'],
    })
  }

  private getInjector(data: ModalData, modalRef: ModalRef, parentInjector: Injector) {
    const tokens = new WeakMap()

    tokens.set(MODAL_DATA, data)
    tokens.set(ModalRef, modalRef)

    return new PortalInjector(parentInjector, tokens)
  }

  private setupCloseOnBackdropClick() {
    const backdropSubscription = this.overlayRef.backdropClick().subscribe(() => {
      this.hide()
    })

    this.subscriptions.add(backdropSubscription)
  }
}
