import { BehaviorSubject, Observable } from 'rxjs'
import { distinctUntilChanged, map } from 'rxjs/operators'
import {
  set as _set,
  get as _get,
  pick as _pick,
  isEqual as _isEqual,
  isEmpty as _isEmpty,
  has as _has,
} from 'lodash/fp'

import { mergeWithArrayOverwrite, replaceExpressionPlaceholders } from '../flx.utils'
import {
  FieldConfigInterface,
  FormConfigInterface,
  TemplateConfigInterface,
} from '../dictionary/flx-template.dictionary'

export type ProcessDataValue = string | number | object | any[]
export type ProcessData = Record<string, ProcessDataValue>
export type CustomComponentData = ProcessData

export type ProcessDataStore = {
  [processInstanceUuid: string]: ProcessData
}

const processDataStore = new BehaviorSubject<ProcessDataStore>({})

export const processData$ = processDataStore.asObservable()

export function processDataById$(processInstanceUuid: string): Observable<ProcessData> {
  return processData$.pipe(
    map((processData) => processData[processInstanceUuid]),
    distinctUntilChanged((prev, curr) => _isEqual(prev, curr))
  )
}

export function getProcessDataValueById(processInstanceUuid: string): ProcessDataValue {
  return processDataStore.value[processInstanceUuid]
}

export function processDataValueFromPath$(
  processInstanceUuid: string,
  path: string,
  alwaysEmit = false
): Observable<ProcessDataValue> {
  return processDataById$(processInstanceUuid).pipe(
    map((processvalue) => {
      return _get(path, processvalue)
    }),
    distinctUntilChanged((prev, curr) => (alwaysEmit ? false : _isEqual(prev, curr)))
  )
}

export function processDataHasKey(processInstanceUuid: string, path: string): boolean {
  return _has(path, processDataStore.value[processInstanceUuid])
}

export function unfoldedProcessData$(
  processInstanceUuid: string,
  slicesPaths: string[],
  alwaysEmit = false
): Observable<any> {
  return processDataById$(processInstanceUuid).pipe(
    map((processData) => unfoldedProcessData(processData, slicesPaths)),
    // TODO figure out why this makes inputs not updating correctly without alwaysEmit true
    distinctUntilChanged((prev, curr) => (alwaysEmit ? false : _isEqual(prev, curr)))
  )
}
export const isHidden$ = (
  slicesPaths: string[],
  config: Partial<TemplateConfigInterface | FieldConfigInterface | FormConfigInterface>
): Observable<boolean> => {
  return unfoldedProcessData$(config.processInstanceUuid, slicesPaths).pipe(
    map((model) => {
      return config.expressions?.hasOwnProperty('hide')
        ? Boolean(
            eval(
              replaceExpressionPlaceholders({
                text: config.expressions.hide,
                model,
              })
            )
          )
        : false
    }),
    distinctUntilChanged((prev, curr) => _isEqual(prev, curr))
  )
}

export function unfoldedProcessData(processDataValue: ProcessDataValue, slicesPaths: string[]) {
  return slicesPaths?.reduce(
    (stateSlice, slicePath) =>
      mergeWithArrayOverwrite(stateSlice, _pick(slicePath, processDataValue)),
    {}
  )
}

export function addProcessData(processInstanceUuid: string, processData: ProcessData): void {
  const state = processDataStore.value

  if (_isEmpty(processData)) {
    return
  }

  processDataStore.next({
    ...state,
    [processInstanceUuid]: processData,
  })
}

export function updateProcessData(processInstanceUuid: string, processData: ProcessData): void {
  const state = processDataStore.value
  if (_isEmpty(processData)) {
    return
  }

  processDataStore.next({
    ...state,
    [processInstanceUuid]: mergeWithArrayOverwrite(state[processInstanceUuid], processData),
  })
}

export function discardProcessInstanceData(processInstanceUuid: string): void {
  const { [processInstanceUuid]: discardedData, ...state } = processDataStore.value

  processDataStore.next({ ...state })
}

export function resetHiddenField(processInstanceUuid: string, key: string): void {
  const resetedValue = _set(key, null, {})
  const state = processDataStore.value

  processDataStore.next({
    ...state,
    [processInstanceUuid]: mergeWithArrayOverwrite(state[processInstanceUuid], resetedValue),
  })
}

export function resetProcessData(): void {
  processDataStore.next({})
}

export function reset(): void {
  resetProcessData()
}
