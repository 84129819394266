import { FieldConfigInterface } from '../../dictionary/flx-template.dictionary'

export type ImageComponentData = Pick<
  FieldConfigInterface,
  'key' | 'displayOptions' | 'processInstanceUuid'
> & {
  displayOptions: { src: string }
}

export const DEFAULT_PLACEHOLDER_URL =
  'https://s3-eu-west-1.amazonaws.com/copo.bt/assets/idPlaceholder/idPlaceholder.png'
