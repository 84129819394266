import { BehaviorSubject, Observable } from 'rxjs'
import { map, withLatestFrom } from 'rxjs/operators'
import { activeProcessInstanceId$ } from './process-instance.store'
import { mergeArrayByKey } from '../flx.utils'
import { INotification } from '../dictionary/flx-template.dictionary'

export type ProcessNotificationsStore = {
  [processInstanceUuid: string]: INotification[]
}
const processNotificationsStore = new BehaviorSubject<ProcessNotificationsStore>({})

export const processNotifications$ = processNotificationsStore.asObservable()

export function updateNotifications(processInstanceUuid: string, notifications: any[]): void {
  const state = processNotificationsStore.getValue()

  processNotificationsStore.next({
    ...state,
    [processInstanceUuid]: mergeArrayByKey(
      notifications || [],
      state[processInstanceUuid] || [],
      'identifier'
    ),
  })
}

export function processNotificationsByActiveProcess$(): Observable<any> {
  return processNotifications$.pipe(
    withLatestFrom(activeProcessInstanceId$),
    map(([notifications, activeProcessId]) => {
      return notifications[activeProcessId]
        ? notifications[activeProcessId].filter((notification) => notification.show)
        : []
    })
  )
}

export function resetNotifications(): void {
  processNotificationsStore.next({})
}
