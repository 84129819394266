import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { TextareaComponent } from './textarea.component'
import { IconModule } from '../icon/icon.module'
import { error, success, warning } from '../icon/ppfIcons'

@NgModule({
  declarations: [TextareaComponent],
  imports: [CommonModule, IconModule.forChild({ icons: [error, success, warning] })],
  exports: [TextareaComponent],
})
export class TextareaModule {}
