import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { CdkTreeModule } from '@angular/cdk/tree'

import { TreeComponent } from './tree.component'
import { IconModule } from '../icon/icon.module'
import { arrow, drag } from '../icon/ppfIcons'
import { DragDropModule } from '@angular/cdk/drag-drop'
import { DndModule } from 'ngx-drag-drop'

@NgModule({
  imports: [
    CommonModule,
    CdkTreeModule,
    DragDropModule,
    DndModule,
    IconModule.forChild({ icons: [arrow, drag] }),
  ],
  exports: [TreeComponent],
  declarations: [TreeComponent],
  providers: [],
})
export class TreeModule {}
