import { Injectable, Type } from '@angular/core'

import { ComponentsRegistry } from '../dictionary/flx.dictionary'
import { getConfig } from '../store/process-config'
import { FlxGenericCustomComponent } from '../components/flx-generic-custom/flx-generic-custom.component'

/**
 * This service is used to resolve components based on the id they were
 * registered with.
 *
 * For it to work, the setComponentRegistry method must be called at least
 * once to provide a map that links ids to component classes.
 */
@Injectable({
  providedIn: 'root',
})
export class FlxComponentResolver {
  private componentsRegistry: ComponentsRegistry | null = null

  setComponentsRegistry(componentsRegistry: ComponentsRegistry): void {
    this.componentsRegistry = componentsRegistry
  }

  addComponentsToRegistry(components: ComponentsRegistry): void {
    this.componentsRegistry = { ...this.componentsRegistry, ...components }
  }

  resolveComponent(componentId: string): Type<any> {
    if (!this.componentsRegistry[componentId]) {
      if (!getConfig().debugCustomComponents) {
        throw new Error(`No component registered with the id: ${componentId}`)
      }

      return FlxGenericCustomComponent
    }

    return this.componentsRegistry[componentId]
  }
}
