import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core'
import { FormGroup } from '@angular/forms'
import { Observable, of, Subscription } from 'rxjs'
import { switchMap, tap } from 'rxjs/operators'
import { isString as _isString } from 'lodash'

import { FlxNomenclatorRepository } from '../../../flx-nomenclator.repository'
import { getOptionsObs } from '../../../services/options-field-helper'
import { NomenclatorValue } from '../../../dictionary/flx-nomenclator.dictionary'
import { FlxWrapperComponent } from '../../../flx-wrapper'

@Component({
  selector: 'flx-select-wrapper',
  templateUrl: './select-wrapper.component.html',
  styleUrls: ['./select-wrapper.component.scss'],
})
export class SelectWrapperComponent extends FlxWrapperComponent implements OnInit, OnDestroy {
  options$: Observable<any>
  subscriptions: Subscription = new Subscription()

  constructor(
    private nomenclatorRepository: FlxNomenclatorRepository,
    private cdr: ChangeDetectorRef
  ) {
    super()
  }

  get showClear(): boolean {
    return Boolean(this.config.displayOptions?.flowxProps?.showClear)
  }

  get selectType(): 'custom' | 'default' {
    return this.config.displayOptions?.flowxProps?.selectType
      ? this.config.displayOptions.flowxProps.selectType
      : 'default'
  }

  ngOnInit(): void {
    const parentFormGroup = this.config.control.parent as FormGroup

    this.options$ = getOptionsObs(
      this.config.dataSource,
      parentFormGroup,
      this.nomenclatorRepository,
      this.config.processInstanceUuid
    ).pipe(
      tap((options) => {
        if (this.config.control?.value) {
          this.setValueFromOptions(options)

          setTimeout(() => {
            this.config.control.updateValueAndValidity({ onlySelf: true, emitEvent: false })
            this.cdr.markForCheck()
          })
        }
      })
    )

    this.subscriptions.add(
      this.config.control.valueChanges
        .pipe(
          switchMap((value) => {
            /**
             * Since process data is always kept as String data type, but inputs with options
             * from nomenlcators need to be internally kept as objects, we need to update the control
             * value only when emitted value is string
             */
            if (value && _isString(value)) {
              return this.options$.pipe(
                tap((options) => {
                  this.setValueFromOptions(options)

                  setTimeout(() => {
                    this.config.control.updateValueAndValidity({ onlySelf: true, emitEvent: false })
                    this.cdr.markForCheck()
                  })
                })
              )
            } else {
              return of()
            }
          })
        )
        .subscribe()
    )
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe()
  }

  setValueFromOptions(options): void {
    const foundOption: NomenclatorValue = options.find(
      (opt: NomenclatorValue) =>
        (opt?.value).toString() === this.config.control.value.toString() ||
        (opt?.value).toString() === this.config.control.value.value?.toString()
    )

    if (foundOption) {
      this.config.control.setValue(
        { ...foundOption },
        {
          onlySelf: true,
          emitEvent: false,
        }
      )
    } else {
      this.config.control.setValue(null, {
        onlySelf: true,
        emitEvent: true,
      })
    }
  }
}
