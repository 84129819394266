import { Component, Input, OnDestroy, OnInit } from '@angular/core'
import { Subscription } from 'rxjs'

import {
  DisplayOptionsInterface,
  TemplateConfigInterface,
} from '../../dictionary/flx-template.dictionary'
import { currentNodeId$ } from '../../store/process-instance.store'
import { tap, withLatestFrom } from 'rxjs/operators'
import { templateConfigsByParentTemplateId$ } from '../../store/template-config.store'
import { FlxWrapperComponent } from '../../flx-wrapper'
import { dismissProcess } from '../../services/process-instance.service'

@Component({
  selector: 'flx-modal',
  templateUrl: './flx-modal.component.html',
  styleUrls: ['./flx-modal.component.scss'],
})
export class FlxModalComponent
  extends FlxWrapperComponent<{
    displayOptions: DisplayOptionsInterface
    templateConfig: TemplateConfigInterface
  }>
  implements OnInit, OnDestroy
{
  private subscriptions = new Subscription()
  showModal = false

  get backdropClass(): string {
    return this.config?.displayOptions?.flowxProps?.backdropClass || ''
  }
  get panelClass(): string {
    return this.config?.displayOptions?.flowxProps?.panelClass || ''
  }

  ngOnInit(): void {
    this.subscriptions.add(
      currentNodeId$(this.config.templateConfig.processInstanceUuid)
        .pipe(
          withLatestFrom(
            templateConfigsByParentTemplateId$(
              this.config.templateConfig.processInstanceUuid,
              this.config.templateConfig.id
            )
          ),
          tap(([currentNode, childTcs]) => {
            this.showModal = childTcs.map((tc) => tc.nodeDefinitionId).includes(currentNode)
          })
        )
        .subscribe()
    )
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe()
  }

  closeModal(): void {
    if (this.config.displayOptions.flowxProps?.closeOnBackdropClick) {
      this.showModal = false
      // TODO: Serban will probably ask us at some point in the future to do this
      // dismissProcess(this.config.templateConfig.processInstanceUuid).subscribe()
    }
  }
}
