import { Directive, HostBinding, Input, ElementRef, OnInit } from '@angular/core'

@Directive({
  selector: '[ppfSwitch]',
})
export class SwitchRefDirective implements OnInit {
  @Input()
  disabled: boolean;

  @HostBinding('disabled')
  get isDisabled() {
    return typeof this.disabled !== 'undefined' ? this.disabled : this.el.nativeElement.disabled;
  }
  
  constructor(private el: ElementRef) {}

  ngOnInit(): void {
    this.checkHost()
  }

  private checkHost() {
    if (this.el.nativeElement.tagName !== 'INPUT') {
      throw Error(`ppfSwitch directive works only on an input element`)
    }
    if (this.el.nativeElement.type !== 'checkbox') {
      throw Error(`ppfSwitch directive works only on a checkbox input element`)
    }
  }
}
