import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { SelectComponent } from './select.component'

import { InputModule } from '../input/input.module'
import { OverlayModule } from '@angular/cdk/overlay'
import { PortalModule } from '@angular/cdk/portal'
import { ReactiveFormsModule } from '@angular/forms'
import { IconModule } from '../icon/icon.module'
import { SelectItemComponent } from './select-item.component'
import { SharedModule } from '../shared/shared.module'
import { arrow, error, success, warning, close } from '../icon/ppfIcons'

@NgModule({
  declarations: [SelectComponent, SelectItemComponent],
  imports: [
    CommonModule,
    InputModule,
    ReactiveFormsModule,
    OverlayModule,
    PortalModule,
    IconModule.forChild({ icons: [arrow, error, success, warning, close] }),
    SharedModule,
  ],
  exports: [SelectComponent, SelectItemComponent],
})
export class SelectModule {}
