import {
  Directive,
  Input,
  TemplateRef,
  ViewContainerRef,
  OnDestroy,
  Output,
  EventEmitter,
  ElementRef,
  OnChanges,
  SimpleChanges,
} from '@angular/core'
import { Overlay, OverlayRef } from '@angular/cdk/overlay'
import { TemplatePortal } from '@angular/cdk/portal'
import { Subscription } from 'rxjs'

@Directive({ selector: '[ppfShowModal]' })
export class ShowModalDirective implements OnChanges, OnDestroy {
  private overlayRef: OverlayRef | null = null
  private backdropSubscription: Subscription | null = null

  @Input() originRef: ElementRef
  @Input() backdropClass
  @Input() panelClass
  @Input() ppfShowModal
  @Output() backdropClick = new EventEmitter()

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private overlay: Overlay
  ) {}

  ngOnDestroy() {
    this.overlayRef?.detach()
    this.backdropSubscription?.unsubscribe()
  }

  private show() {
    const modalPortal = new TemplatePortal(this.templateRef, this.viewContainer)
    this.overlayRef.attach(modalPortal)
  }

  private hide() {
    this.overlayRef.detach()
  }

  private createCDKOverlay() {
    const positionStrategy = !this.originRef
      ? this.overlay.position().global().centerHorizontally().centerVertically()
      : this.overlay
          .position()
          .flexibleConnectedTo(this.originRef)
          .withPush(false)
          .withPositions([
            {
              originX: 'start',
              originY: 'bottom',
              overlayX: 'start',
              overlayY: 'top',
            },
            {
              originX: 'start',
              originY: 'top',
              overlayX: 'start',
              overlayY: 'bottom',
            },
          ])

    this.overlayRef = this.overlay.create({
      positionStrategy,
      scrollStrategy: this.overlay.scrollStrategies.block(),
      panelClass: this.panelClass ? [this.panelClass, 'ppf-modal-panel'] : ['ppf-modal-panel'],
      hasBackdrop: true,
      backdropClass: this.backdropClass
        ? [this.backdropClass, 'ppf-modal-backdrop']
        : ['ppf-modal-backdrop'],
    })
  }

  private setupOverlayBackdropEvent() {
    this.backdropSubscription = this.overlayRef.backdropClick().subscribe(() => {
      this.backdropClick.emit()
    })
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.ppfShowModal.currentValue !== changes.ppfShowModal.previousValue) {
      if (!this.overlayRef) {
        this.createCDKOverlay()
        this.setupOverlayBackdropEvent()
      }
      if (changes.ppfShowModal.currentValue) {
        this.show()
      } else {
        this.hide()
      }
    }
  }
}
