import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { OverlayModule } from '@angular/cdk/overlay'

import { ModalComponent } from './modal.component'
import { ShowModalDirective } from './show-modal.directive'
import { IconModule } from '../icon/icon.module'
import { close, arrow } from '../icon/ppfIcons'

@NgModule({
  declarations: [ModalComponent, ShowModalDirective],
  imports: [CommonModule, OverlayModule, IconModule.forChild({ icons: [close, arrow] })],
  exports: [ModalComponent, ShowModalDirective],
})
export class ModalModule {}
