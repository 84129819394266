import { Injectable } from '@angular/core'

import { NomenclatorServiceInterface } from './dictionary/flx-nomenclator.dictionary'

import * as FlxEnumerationsService from './services/flx-enumerations.service'
import { getConfig } from './store/process-config'

@Injectable({
  // TODO: See why components can't inject services declared in the FlxProcessModule
  providedIn: 'root',
})
export class FlxNomenclatorRepository {
  private _externalNomenclator: NomenclatorServiceInterface | null = null

  set nomenclator(nomenclator: NomenclatorServiceInterface) {
    this._externalNomenclator = nomenclator
  }

  get nomenclator(): NomenclatorServiceInterface {
    if (!this._externalNomenclator) {
      return { get: (name) => FlxEnumerationsService.get(name, { language: getConfig().language }) }
    }

    return this._externalNomenclator
  }
}
