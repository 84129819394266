import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter, HostBinding,
  Input, Output,
} from '@angular/core'
import { AccordionService } from './accordin.service'

@Component({
  selector: 'ppf-accordion-item',
  templateUrl: './accordion-item.component.html',
  styleUrls: ['./accordion-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccordionItemComponent {

  @Input() leftIconName: string
  @Input() rightIconName: string = 'playVertical'
  @Input() completed: boolean = false
  @Input() disabled: boolean = false
  @Output() openChange: EventEmitter<boolean> = new EventEmitter()

  @HostBinding('class')
  get classes() {
    const isCompleted = this.completed ? 'completed' : ''
    const isDisabled = this.disabled ? 'disabled' : ''

    return `${isCompleted} ${isDisabled}`
  }

  protected _open = false
  @Input() get open(): boolean {
    return this._open
  }

  set open(value: boolean) {
    if (value) {
      this.accordionService.emit(true)
    }

    this._open = value
    this.openChange.emit(value)
    this.cdRef.detectChanges()
  }

  constructor(private accordionService: AccordionService, private cdRef: ChangeDetectorRef) {
  }

  toggle() {
    if (!this.disabled) {
      this.open = !this.open
    }
  }
}
