import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { ButtonComponent } from './button.component'
import { IconModule } from '../icon/icon.module'
import { message, play, pause } from '../icon/ppfIcons'

@NgModule({
  declarations: [ButtonComponent],
  imports: [CommonModule, IconModule.forChild({ icons: [message, play, pause] })],
  exports: [ButtonComponent],
})
export class ButtonModule {}
