import { Injectable, Injector } from '@angular/core'
import { Subject, Subscription } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class AccordionService {
    private subject$: Subject<boolean> = new Subject<boolean>();

    constructor() { }

    emit(value?: boolean): void {
        this.subject$.next(value);
    }

    itemOpened(action: any): Subscription {
        return this.subject$.subscribe(action);
    }

}
