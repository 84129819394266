export type RoundType = 'default' | 'high'
export type ChipType = 'flat' | 'fill' | 'border' | 'fillAndBorder' | 'fillWithRedIcon'
export type IconPosition = 'start' | 'end'

export const CHIP_CONSTANTS = {
  DEFAULT_CHIP_TYPE: 'flat',
  DEFAULT_ROUND_TYPE: 'default',
  DEFAULT_ICON_POSITION: 'end',
  DEFAULT_ICON: 'close',
}
