import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  Input,
  QueryList,
  OnInit,
} from '@angular/core'
import { AccordionItemComponent } from './accordion-item.component'
import { AccordionService } from './accordin.service'

@Component({
  selector: 'ppf-accordion',
  template: ` <ng-content></ng-content>`,
  styles: [
    `
      :host {
        display: flex;
        flex-direction: column;
      }
    `,
  ],
  host: {
    role: 'tablist',
  },
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccordionComponent implements OnInit {
  @Input() multipleOpen: boolean = true
  @ContentChildren(AccordionItemComponent) tabList: QueryList<AccordionItemComponent>

  constructor(
    public changeDetector: ChangeDetectorRef,
    private accordionService: AccordionService
  ) {}

  ngOnInit() {
    this.accordionService.itemOpened(() => {
      this.closeOtherPanels()
    })
  }

  closeOtherPanels() {
    if (!this.multipleOpen && this.tabList) {
      const panels = this.tabList.toArray()
      panels.forEach((panel: AccordionItemComponent) => {
        panel.open = false
      })
    }
  }
}
