import { Component, HostBinding, Input } from '@angular/core'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'

import { unfoldedProcessData$ } from '../../store/process-data.store'
import { TemplateConfigInterface } from '../../dictionary/flx-template.dictionary'
import { FlxWrapperComponent } from '../../flx-wrapper'
import { extractPlaceholders, replaceExpressionPlaceholders } from '../../flx.utils'

@Component({
  selector: 'flx-text',
  templateUrl: './flx-text.component.html',
  styleUrls: ['./flx-text.component.scss'],
})
export class FlxTextComponent extends FlxWrapperComponent<TemplateConfigInterface> {
  @HostBinding('class')
  classes = ''

  @Input()
  set config(tc: TemplateConfigInterface) {
    this._config = tc
    this.classes = this._config?.displayOptions?.class || ''
    this.text$ = unfoldedProcessData$(
      this._config.processInstanceUuid,
      extractPlaceholders(this._config.displayOptions?.flowxProps?.text)
    ).pipe(
      map((model) => {
        return this._config?.displayOptions?.flowxProps?.hasOwnProperty('text')
          ? replaceExpressionPlaceholders({
              text: this._config?.displayOptions?.flowxProps?.text,
              model,
              keepStringValueQuotes: false,
            })
          : ''
      })
    )
  }

  text$: Observable<string>
}
