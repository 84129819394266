import { Component, Input, HostBinding, ViewChild, ElementRef } from '@angular/core'
import {
  progressBarType,
  DEFAULT_TYPE,
  DEFAULT_VISUAL_TYPE,
  progressBarVisualType,
  DEFAULT_COLOR,
  progressBarColor,
} from './progress-bar.dictionary'

@Component({
  selector: 'ppf-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.scss'],
})
export class ProgressBarComponent {
  @ViewChild('spanRef') spanElm: ElementRef

  @Input() type: progressBarType = DEFAULT_TYPE
  @Input() visualType: progressBarVisualType = DEFAULT_VISUAL_TYPE
  @Input() color: progressBarColor = DEFAULT_COLOR
  @Input() progress: number

  @HostBinding('class')
  get classes() {
    const mainClasses = `ppf-${this.type} ppf-progress-bar-${this.visualType} ppf-progress-bar-${this.color}`
    return this.spanElm?.nativeElement.children.length !== 0
      ? `${mainClasses} ppf-custom-content`
      : mainClasses
  }
}
