import { Component, Input, OnInit } from '@angular/core'
import { DomSanitizer, SafeUrl } from '@angular/platform-browser'

@Component({
  selector: 'flx-link',
  templateUrl: './flx-link.component.html',
})
export class FlxLinkComponent implements OnInit {
  url: SafeUrl
  class: string
  style: { [p: string]: any } | null
  text: string

  // TODO add type for data after merging file upload component
  @Input()
  set config(data: any) {
    this.url = this.sanitizer.bypassSecurityTrustUrl(data.displayOptions.flowxProps.url)
    this.class = data?.displayOptions?.class || ''
    this.style = data?.displayOptions?.style || ''
    this.text = data.displayOptions.flowxProps.text
  }

  constructor(private sanitizer: DomSanitizer) {}

  ngOnInit(): void {}
}
