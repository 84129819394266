import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { RadioComponent } from './radio.component'
import { IconModule } from '../icon/icon.module'
import { RadioRefDirective } from './radio.directive'
import { checkmark, minus } from '../icon/ppfIcons'

@NgModule({
  declarations: [RadioComponent, RadioRefDirective],
  imports: [CommonModule, IconModule.forChild({ icons: [checkmark, minus] })],
  exports: [RadioComponent, RadioRefDirective],
})
export class RadioModule {}
