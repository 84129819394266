import { ProcessConfig } from '../dictionary/flx-process.dictionary'

let config: ProcessConfig = null

export function setConfig(value: ProcessConfig): void {
  config = value
}

export function getConfig(): ProcessConfig {
  return config
}
