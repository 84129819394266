import { ValidatorFn, AsyncValidatorFn } from '@angular/forms'
import { forEach as _forEach, isNil as _isNil } from 'lodash'
// TODO: Import _merge from lodash/fp
import { merge as _merge } from 'lodash'
import {
  mergeWith as _mergeWith,
  isString as _isString,
  isArray as _isArray,
  get as _get,
  orderBy as _orderBy,
} from 'lodash/fp'
import { FlxValidators, VALIDATOR_TYPE } from './dictionary/flx-template.dictionary'
import { AsyncValidatorFnFactory, ValidatorFnFactory } from './dictionary/flx.dictionary'
import { FlxValidatorResolver } from './services/flx-validator-resolver.service'
import { Observable } from 'rxjs'
import { processDataValueFromPath$ } from './store/process-data.store'

export function hasAllRequiredProps(object: Record<any, any>, props: any[]): boolean {
  let requiredPropsMissing = false

  _forEach(props, (key) => {
    requiredPropsMissing = _isNil(object[key])
    return !requiredPropsMissing
  })

  return !requiredPropsMissing
}

export function flattenAndOrderTemplateConfig(arr): any[] {
  return arr.reduce((acc, item) => {
    acc = [...acc, item]
    if (item.templateConfig) {
      acc = [
        ...acc,
        ...flattenAndOrderTemplateConfig(_orderBy('order', 'asc', item.templateConfig)),
      ]
    }
    return acc
  }, [])
}

export function unflatten(data): object {
  const result = []
  Object.keys(data).forEach((flatKey) => {
    const keys = flatKey.split('.')
    let unftn = { [keys[keys.length - 1]]: data[flatKey] }
    for (let i = keys.length - 1; i--; ) {
      unftn = { [keys[i]]: unftn }
    }
    result.push(unftn)
  })

  return _merge({}, ...result)
}

export function replaceTemplateLiterals(text: string, params: object): string {
  const exp = /\{{(.*?)}}/g
  const matched = text.match(exp)
  if (matched && matched.length) {
    matched
      .map((match) => match.replace('{{', '').replace('}}', ''))
      .forEach((placeholder) => {
        if (Object.keys(params).includes(placeholder)) {
          text = text.replace(`\{\{${placeholder}\}\}`, params[placeholder])
        }
      })
  }

  return text
}

export function replaceExpressionPlaceholders({
  text,
  model,
  keepStringValueQuotes = true,
}: {
  text: string
  model: object
  keepStringValueQuotes?: boolean
}): string {
  const exp = /\${(.*?)}/g
  const matched = text?.match(exp)
  if (matched) {
    matched
      .map((match: string) => match.replace('${', '').replace('}', ''))
      .forEach((keyPath: string) => {
        let replaceValue: any
        // TODO: Maybe we can remove this if-else (_get already returns undefined)
        if (!_get(keyPath, model)) {
          replaceValue = undefined
        } else {
          replaceValue =
            _isString(_get(keyPath, model)) && keepStringValueQuotes
              ? `'${_get(keyPath, model)}'`
              : _get(keyPath, model)
        }
        if (replaceValue instanceof Array) {
          text = text.replace(`\$\{${keyPath}\}`, JSON.stringify(replaceValue))
        } else {
          text = text.replace(`\$\{${keyPath}\}`, replaceValue)
        }
      })
  }

  return text
}

export function extractPlaceholders(text): string[] {
  if (!text) {
    return []
  }
  const exp = /\${(.*?)}/g
  const matched = text.match(exp)

  return matched?.length
    ? matched.map((match: string) => match.replace('${', '').replace('}', ''))
    : []
}

export function buildFieldValidators(
  validators: FlxValidators,
  validatorResolver: FlxValidatorResolver
): ValidatorFn[] {
  if (validators) {
    return Object.keys(validators)
      .map((validatorName) => {
        const validator = validators[validatorName]
        if (validator.type === VALIDATOR_TYPE.SYNC) {
          return validator.params
            ? (validatorResolver.resolveValidator(validatorName) as ValidatorFnFactory)(
                ...validator.params
              )
            : (validatorResolver.resolveValidator(validatorName) as ValidatorFn)
        }
      })
      .filter((validator) => Boolean(validator))
  }
  return []
}

export function buildAsyncValidators(
  processInstanceUuid: string,
  validators: FlxValidators,
  validatorResolver: FlxValidatorResolver
): AsyncValidatorFn[] {
  if (validators) {
    return Object.keys(validators)
      .map((validatorName) => {
        const validator = validators[validatorName]
        if (validator.type === VALIDATOR_TYPE.ASYNC) {
          const params$: Observable<any>[] = validator.params.map((param) => {
            return processDataValueFromPath$(processInstanceUuid, param)
          })
          return params$.length
            ? (validatorResolver.resolveAsyncValidator(validatorName) as AsyncValidatorFnFactory)(
                params$
              )
            : (validatorResolver.resolveAsyncValidator(validatorName) as AsyncValidatorFn)
        }
      })
      .filter((validator) => Boolean(validator))
  }
  return []
}

export const mergeWithArrayOverwrite = _mergeWith((value, srcValue) => {
  if (_isArray(value) || _isArray(srcValue)) {
    return srcValue
  }
})

export function mergeArrayByKey(dest, source, key): any[] {
  return dest.map((item, i) => {
    if (source[i]) {
      if (item[key] === source[i][key]) {
        return Object.assign({}, source[i], item)
      }
    } else {
      return Object.assign({}, item)
    }
  })
}
