import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core'

import { AbstractControl, FormControl, FormGroup } from '@angular/forms'
import { Observable, of, Subscription } from 'rxjs'
import { filter, map, tap, withLatestFrom } from 'rxjs/operators'

import {
  set as _set,
  get as _get,
  has as _has,
  isEqual as _isEqual,
  isNil as _isNil,
} from 'lodash/fp'

import {
  buildAsyncValidators,
  buildFieldValidators,
  extractPlaceholders,
  replaceExpressionPlaceholders,
} from '../../flx.utils'

import {
  processDataValueFromPath$,
  updateProcessData,
  resetHiddenField,
  unfoldedProcessData$,
  isHidden$,
  getProcessDataValueById,
} from '../../store/process-data.store'

import { FlxValidatorResolver } from '../../services/flx-validator-resolver.service'
import { FieldConfigInterface } from '../../dictionary/flx-template.dictionary'
import { DEFAULT_INITIAL_FORM_VALUES } from '../../dictionary/flx.constants'

@Component({
  selector: 'flx-form-field',
  templateUrl: './flx-form-field.component.html',
  styleUrls: ['./flx-form-field.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FlxFormFieldComponent implements OnInit, OnDestroy {
  private subscriptions = new Subscription()

  public formControl: FormControl
  public errors: any[]
  public showErrors$: Observable<boolean>

  @Input() fieldConfig: FieldConfigInterface = null
  @Input() form: FormGroup
  @Input() formSubmitted$: Observable<boolean>
  @Input() validateOn: string

  public isHidden$: Observable<boolean>
  private disabledDeps
  private hiddenDeps
  private hasAsyncValidators

  constructor(private cdr: ChangeDetectorRef, private validatorResolver: FlxValidatorResolver) {}

  ngOnInit(): void {
    this.hasAsyncValidators =
      this.fieldConfig.validators &&
      Boolean(
        Object.keys(this.fieldConfig.validators).filter(
          (validator) => this.fieldConfig.validators[validator].type === 'async'
        ).length
      )

    this.formControl = this.form.controls[this.fieldConfig.key] as FormControl
    this.disabledDeps = extractPlaceholders(this.fieldConfig?.displayOptions?.flowxProps?.disabled)
    this.hiddenDeps = extractPlaceholders(this.fieldConfig?.expressions?.hide)

    this.isHidden$ = isHidden$(this.hiddenDeps, this.fieldConfig)

    if (this.formControl instanceof AbstractControl) {
      this.setErrors()

      if (this.noValueInDataStoreForKey(this.fieldConfig.key)) {
        this.putInitValueInStore()
      }

      this.subscriptions.add(
        // Param alwaysEmit needs to be true for async validators work.
        // processDataValueFromPath$ needs to emit even if the value hasn't changed so updateValueAndValidity and setErrors are executed.
        // Maybe find a way to listen for validity changes
        processDataValueFromPath$(
          this.fieldConfig.processInstanceUuid,
          this.fieldConfig.key,
          this.hasAsyncValidators
        )
          .pipe(
            tap((valueFromModel) => {
              const controlValue = this.formControl.value?.value || this.formControl.value

              if (this.noValueInDataStoreForKey(this.fieldConfig.key)) {
                this.putInitValueInStore()
                return
              }

              if (!_isEqual(valueFromModel, controlValue)) {
                this.formControl.setValue(valueFromModel, { emitEvent: false, onlySelf: true })

                // SUBMIT BUTTONS ARE DISABLED WHILE FORMS ARE PRISTINE,SO WE NEED TO FORCE FORMS TO BE DIRTY
                this.form.markAsDirty({ onlySelf: true })
              }

              // FORCE STATUS CHANGE FOR ASYNC VALIDATION
              this.formControl.updateValueAndValidity({ onlySelf: true, emitEvent: false })
              this.setErrors()
              this.cdr.markForCheck()
            })
          )
          .subscribe()
      )
      if (this.fieldConfig?.displayOptions?.flowxProps?.hasOwnProperty('disabled')) {
        this.subscriptions.add(
          unfoldedProcessData$(this.fieldConfig.processInstanceUuid, this.disabledDeps)
            .pipe(
              tap((dataSlice) => {
                this.toggleDisableControl(dataSlice)
              })
            )
            .subscribe()
        )
      }

      this.subscriptions.add(
        this.formControl.valueChanges
          .pipe(
            map((value) => this.mapObjectsToValues(value)),
            tap((value) => {
              const newValue = _set(this.fieldConfig.key, value, {})
              updateProcessData(this.fieldConfig.processInstanceUuid, newValue)
            })
          )
          .subscribe()
      )

      /**
       * When the field isHidden, reset the corresponding value in the store.
       */
      this.subscriptions.add(
        this.isHidden$
          .pipe(
            withLatestFrom(
              processDataValueFromPath$(this.fieldConfig.processInstanceUuid, this.fieldConfig.key)
            ),
            filter(([isHidden, previousValue]) => isHidden && !_isNil(previousValue)),
            tap(() => {
              resetHiddenField(this.fieldConfig.processInstanceUuid, this.fieldConfig.key)
            })
          )
          .subscribe()
      )
    }

    this.showErrors$ =
      this.validateOn === 'submit'
        ? this.formSubmitted$.pipe(
            tap((formSubmitted) => {
              this.setErrors()
              return formSubmitted && this.errors.length
            })
          )
        : of(this.validateOn === 'blur')
  }

  putInitValueInStore(): void {
    const initValue =
      this.fieldConfig.dataSource?.defaultValue ??
      DEFAULT_INITIAL_FORM_VALUES[this.fieldConfig.displayOptions?.flowxProps?.inputType]

    const newValue = _set(this.fieldConfig.key, initValue, {})

    updateProcessData(this.fieldConfig.processInstanceUuid, newValue)
  }

  noValueInDataStoreForKey(key: string): boolean {
    const dataStoreValue = getProcessDataValueById(this.fieldConfig.processInstanceUuid)
    return !_has(this.fieldConfig.key, dataStoreValue)
  }

  toggleDisableControl(model): void {
    const flowxProps = this.fieldConfig?.displayOptions?.flowxProps
    if (!flowxProps || !flowxProps.hasOwnProperty('disabled')) {
      return
    }
    try {
      if (
        eval(
          replaceExpressionPlaceholders({
            text: this.fieldConfig.displayOptions?.flowxProps?.disabled,
            model,
          })
        )
      ) {
        this.formControl.disable({ emitEvent: false })
        this.formControl.clearValidators()
        this.formControl.clearAsyncValidators()
      } else {
        this.formControl.setAsyncValidators(
          buildAsyncValidators(
            this.fieldConfig.processInstanceUuid,
            this.fieldConfig.validators,
            this.validatorResolver
          )
        )
        this.formControl.setValidators(
          buildFieldValidators(this.fieldConfig.validators, this.validatorResolver)
        )

        this.formControl.enable({ emitEvent: false })
      }
      this.cdr.markForCheck()
    } catch (e) {
      throw Error(
        `${this.fieldConfig.displayOptions?.flowxProps?.disabled} is not a valid js expression`
      )
    }
  }

  setErrors(): void {
    this.errors =
      this.formControl?.errors && Object.keys(this.formControl.errors).length
        ? Object.keys(this.formControl.errors).map((error) => {
            return this.fieldConfig?.validators ? this.fieldConfig.validators[error]?.message : ''
          })
        : []

    if (this.fieldConfig.componentIdentifier === 'DATEPICKER') {
      const matDatepickerMaxError =
        this.formControl?.errors?.matDatepickerMax &&
        this.fieldConfig?.displayOptions?.flowxProps?.maxDate &&
        this.fieldConfig?.displayOptions?.flowxProps?.matDatepickerMax
      const matDatepickerMinError =
        this.formControl?.errors?.matDatepickerMin &&
        this.fieldConfig?.displayOptions?.flowxProps?.minDate &&
        this.fieldConfig?.displayOptions?.flowxProps?.matDatepickerMin
      this.errors = [
        ...this.errors,
        ...(matDatepickerMaxError ? [matDatepickerMaxError] : []),
        ...(matDatepickerMinError ? [matDatepickerMinError] : []),
      ].filter(Boolean)
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe()
  }

  /**
   * Maps the value from the forms to values accepted by the BE.
   * (Now it maps a select nomenclator object to a primitive value)
   *
   * @param value Any value from the form field, can be a primitve or an object.
   */
  private mapObjectsToValues(value: any): string | number {
    return value?.value ?? value
  }
}
