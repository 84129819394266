import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { SpinnerComponent } from './spinner.component'
import { IconModule } from '../icon/icon.module'
import { user } from '../icon/ppfIcons'

@NgModule({
  declarations: [SpinnerComponent],
  imports: [CommonModule, IconModule.forChild({ icons: [user] })],
  exports: [SpinnerComponent],
})
export class SpinnerModule {}
