import { BehaviorSubject, Observable } from 'rxjs'
import { map } from 'rxjs/operators'

export type SubstitutionTags = Record<string, string>

const substitutionTagsStore = new BehaviorSubject<SubstitutionTags>({})

export const substitutionTags$ = substitutionTagsStore.asObservable()

export function setSubstitutionTags(substitutionTags: any[]): void {
  const _substitutionTags = substitutionTags.reduce((acc, tag) => {
    return { ...acc, [tag.id]: tag.label }
  }, {})
  substitutionTagsStore.next(_substitutionTags)
}

export function getSubstitutionTagByKey$(code: string): Observable<string> {
  return substitutionTags$.pipe(
    map((tags) => {
      return tags[code]
    })
  )
}
export function getSubstitutionTagByKey(code: string): string {
  return substitutionTagsStore.value[code]
}
