import { ChangeDetectorRef, Component, DoCheck } from '@angular/core'
import { FlxWrapperComponent } from '../../../flx-wrapper'

@Component({
  selector: 'flx-input-wrapper',
  templateUrl: './input-wrapper.component.html',
  styleUrls: ['./input-wrapper.component.scss'],
})
export class InputWrapperComponent extends FlxWrapperComponent implements DoCheck {
  constructor(private cdr: ChangeDetectorRef) {
    super()
  }

  ngDoCheck(): void {
    // TODO Find a better way to do this.
    this.cdr.detectChanges()
  }

  get inputType(): string {
    return this.config.displayOptions?.flowxProps?.inputType || 'text'
  }
  get suffix(): string {
    return this.config.displayOptions?.flowxProps?.suffix || ''
  }
  get prefix(): string {
    return this.config.displayOptions?.flowxProps?.prefix || ''
  }
}
