import { FormGroup } from '@angular/forms'
import { processDataHasKey, processDataValueFromPath$ } from '../store/process-data.store'
import { merge, Observable, of } from 'rxjs'
import { filter, switchMap, tap } from 'rxjs/operators'
import { isNil as _isNil } from 'lodash/fp'

import { NomenclatorValue } from '../dictionary/flx-nomenclator.dictionary'
import { DataSource, DataSourceType } from '../dictionary/flx-template.dictionary'
import { FlxNomenclatorRepository } from '../flx-nomenclator.repository'

export const getOptionsObs = (
  dataSource: DataSource,
  formGroup: FormGroup,
  nomenclatorRepository: FlxNomenclatorRepository,
  processInstanceUuid: string
): Observable<any> => {
  const options = dataSource?.options

  if (!dataSource?.sourceType) {
    return getOptionsObsFallback(dataSource, formGroup, nomenclatorRepository)
  }

  switch (dataSource.sourceType) {
    case DataSourceType.PROCESS_DATA:
      return getOptionsFromProcessData(dataSource, processInstanceUuid)
    case DataSourceType.ENUMERATION:
      return getOptionsFromEnumeration(dataSource, formGroup, nomenclatorRepository)
    case DataSourceType.STATIC:
      return of(options)
  }
}

const getOptionsFromProcessData = (
  dataSource: DataSource,
  processInstanceUuid: string
): Observable<any> => {
  const filterOutNil = filter((options) => !_isNil(options))

  const { key: sourceKey, parentKey } = dataSource.processData

  if (parentKey) {
    if (!processDataHasKey(processInstanceUuid, parentKey)) {
      console.warn(processDataKeyWarning('parentKey', parentKey))
    }

    return processDataValueFromPath$(processInstanceUuid, parentKey).pipe(
      switchMap((optionsKeyName) => {
        const pathToList = `${sourceKey}.${optionsKeyName}`

        if (!processDataHasKey(processInstanceUuid, pathToList)) {
          console.warn(processDataKeyWarning('parentKeyValue', pathToList))
        }

        return processDataValueFromPath$(processInstanceUuid, pathToList)
      }),
      filterOutNil
    )
  }

  if (!processDataHasKey(processInstanceUuid, sourceKey)) {
    console.warn(processDataKeyWarning('key', sourceKey))
  }

  return processDataValueFromPath$(processInstanceUuid, sourceKey).pipe(filterOutNil)
}

const processDataKeyWarning = (type: 'key' | 'parentKey' | 'parentKeyValue', key: string) => {
  const prefixesByType = {
    key: `Key set as datasource`,
    parentKey: `Key set as parent for datasource`,
    parentKeyValue: `Key received from parent`,
  }

  return `[SDK] ${prefixesByType[type]} ${key} does not exist in the process data store. If a select, checkbox or radio input doesn't work , this might be the reason. If everything works, ignore this message.`
}

const getOptionsFromEnumeration = (
  dataSource: DataSource,
  formGroup: FormGroup,
  nomenclatorRepository: FlxNomenclatorRepository
): Observable<any> => {
  if (dataSource.nomenclator.name) {
    return getNomenclatorObservable(dataSource.nomenclator.name, nomenclatorRepository)
  }

  const parentKey = dataSource.nomenclator.parentKey

  if (!formGroup.controls[parentKey]) {
    throw new Error(`Parent key: ${parentKey}. No input is found with key ${parentKey}!`)
  }
  return merge(
    of(formGroup.controls[parentKey]?.value),
    formGroup.controls[parentKey]?.valueChanges
  ).pipe(
    filter((value: NomenclatorValue) => !!(value && value.nomenclatorId)),
    switchMap((nomenclatorValue: NomenclatorValue) => {
      return getNomenclatorObservable(nomenclatorValue.nomenclatorId, nomenclatorRepository)
    })
  )
}

/**
 * Old metod of deciding where to get the options from (enumeration || static options)
 * Needed until the migration is done
 */
const getOptionsObsFallback = (
  dataSource: DataSource,
  formGroup: FormGroup,
  nomenclatorRepository: FlxNomenclatorRepository
): Observable<any> => {
  const nomenclatorIdentifier = dataSource?.nomenclator?.name
  const parentKey = dataSource?.nomenclator?.parentKey
  const options = dataSource?.options

  if (nomenclatorIdentifier) {
    return getNomenclatorObservable(nomenclatorIdentifier, nomenclatorRepository)
  }

  if (parentKey) {
    if (!formGroup.controls[parentKey]) {
      throw new Error(`Parent key: ${parentKey}. No input is found with key ${parentKey}!`)
    }
    return merge(
      of(formGroup.controls[parentKey]?.value),
      formGroup.controls[parentKey]?.valueChanges
    ).pipe(
      filter((value: NomenclatorValue) => !!(value && value.nomenclatorId)),
      switchMap((nomenclatorValue: NomenclatorValue) => {
        return getNomenclatorObservable(nomenclatorValue.nomenclatorId, nomenclatorRepository)
      })
    )
  }

  if (options) {
    return of(options)
  }

  return of([])
}

const getNomenclatorObservable = (
  nomenclatorIdentifier: string | number,
  nomenclatorRepository: FlxNomenclatorRepository
): Observable<any> => {
  return nomenclatorRepository.nomenclator.get(nomenclatorIdentifier)
}
