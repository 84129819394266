import { ComponentRef, Injectable } from '@angular/core'
import { HttpClient, HttpHeaders, HttpRequest } from '@angular/common/http'
import { FormGroup } from '@angular/forms'
import { combineLatest, EMPTY, Observable, of } from 'rxjs'
import { catchError, filter, map, switchMap, take, tap } from 'rxjs/operators'
// @ts-ignore
import io from 'socket.io-client'
import { isEmpty as _isEmpty } from 'lodash/fp'

import * as ProcessInstanceService from './process-instance.service'
import * as ProcessInstanceStore from '../store/process-instance.store'
import * as TemplateConfigStore from '../store/template-config.store'
import * as ProcessActionsStore from '../store/process-actions.store'

import {
  ProcessAction,
  ProcessActionType,
  ProcessActionMap,
  ProgressUpdateDTO,
  SOCKET_MESSAGE_TYPE,
  StartProcessResponse,
  ExecuteActionResponse,
} from '../dictionary/flx-process.dictionary'
import { ProcessConfig } from '../dictionary/flx-process.dictionary'
import { PROCESS_CONFIG_STORAGE_KEY } from '../dictionary/flx.constants'
import { FlxFormService } from './flx-form.service'
import { FlxClientStoreRepository } from '../flx-client-store.repository'
import { ActivatedRoute, Router } from '@angular/router'
import {
  updateProcessData,
  reset as resetProcessDataStore,
  unfoldedProcessData$,
  getProcessDataValueById,
  unfoldedProcessData,
} from '../store/process-data.store'
import { setConfig, getConfig } from '../store/process-config'
import { setLoader } from '../store/ui.store'
import {
  getProcessInstanceValueById,
  processInstances$,
  setActiveProcessInstance,
} from '../store/process-instance.store'
import { getLocalStorageItem, removeLocalStorageItem, setLocalStorageItem } from '../local-storage'
import { processActionsByTemplateId$ } from '../store/process-actions.store'
import { unflatten } from '../flx.utils'
import * as ProcessNotificationStore from '../store/process-notifications.store'
import * as FlxEnumerationsService from '../services/flx-enumerations.service'
import { setSubstitutionTags } from '../store/substitution-tags.store'
import { ENUMERATION_TYPES } from './flx-enumerations.service'
import { dismissProcess, removeAllProcessData } from './process-instance.service'
import { LocalStorageEvent } from '../reactive-local-storage'
import { LOCAL_STORAGE_EVENT_KEY } from '../dictionary/flx.dictionary'
import { fetchProcessInstanceStatus } from './process-instance.gateway'

/**
 * This service is the entry point of the library that can be used by
 * a client app to configure and start a process instance. This service
 * will then orchestrate the running of the process.
 */
@Injectable({
  providedIn: 'root',
})
export class FlxProcessService {
  private websocket: Record<number, SocketIOClient.Socket | null> = {}
  private headers = new HttpHeaders({
    'content-type': 'application/problem+json',
  })

  private authTokenListener = null

  constructor(
    private http: HttpClient,
    private formService: FlxFormService,
    private clientStoreRepository: FlxClientStoreRepository,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  init(config: ProcessConfig): void {
    const {
      processStartData,
      apiUrl,
      processName,
      processApiPath,
      isDraft,
      debugLogs = false,
      keepState,
      debugCustomComponents,
      language,
    } = config
    const processUuid = this.route.snapshot.queryParams?.processUuid

    setConfig({
      apiUrl,
      processName,
      processStartData,
      processApiPath,
      isDraft,
      debugLogs,
      keepState,
      language,
      debugCustomComponents,
    })

    const substitutionTags$ = FlxEnumerationsService.get(ENUMERATION_TYPES.SUBSTITUTION_TAGS, {
      language,
    }).pipe(
      tap((response) => {
        setSubstitutionTags(response)
      })
    )

    const startProcess$ = processInstances$.pipe(
      take(1),
      switchMap((processInstances) => {
        const localStorageProcessInstances = getLocalStorageItem(PROCESS_CONFIG_STORAGE_KEY)

        if (processUuid) {
          /** Found process in url, so get process status */
          return fetchProcessInstanceStatus(processUuid)
          // return this.getProcessInstanceByUuid(apiUrl, processApiPath, processUuid)
        } else if (localStorageProcessInstances && localStorageProcessInstances[processName]) {
          /** Found process in localStorage */
          if (processInstances[localStorageProcessInstances[processName]]) {
            setActiveProcessInstance(localStorageProcessInstances[processName])
            this.updateUrl(localStorageProcessInstances[processName])
            return of(null)
          } else {
            return fetchProcessInstanceStatus(localStorageProcessInstances[processName])
            // return this.getProcessInstanceByUuid(
            //   apiUrl,
            //   processApiPath,
            //   localStorageProcessInstances[processName]
            // )
          }
        } else {
          setLoader(true)
          return this.startProcess(processStartData).pipe(
            map((response: StartProcessResponse) => {
              const { tokens, ..._startProcessResponse } = response
              return _startProcessResponse
            })
          )
        }
      }),

      tap((processInstanceMetadata: StartProcessResponse) => {
        if (processInstanceMetadata) {
          this.handleResponse(processInstanceMetadata)
        }
      })
    )

    substitutionTags$
      .pipe(
        switchMap(() => {
          return startProcess$
        }),
        catchError((err) => {
          console.error(`NO SUBSTITUTION TAGS FOUND!!`)
          return startProcess$
        })
      )
      .subscribe()
  }

  private updateUrl(processInstanceUuid: string): void {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: { processUuid: processInstanceUuid },
      queryParamsHandling: 'merge',
      replaceUrl: true,
    })
  }

  private handleResponse(processInstanceMetadata: StartProcessResponse): void {
    ProcessInstanceService.handleNewProcessInstance(processInstanceMetadata)
    if (processInstanceMetadata.subprocessesUuids?.length) {
      console.log(
        'processInstanceMetadata.subprocessesUuids',
        processInstanceMetadata.subprocessesUuids
      )
      processInstanceMetadata.subprocessesUuids.forEach((subprocessUuid) => {
        ProcessInstanceService.handleProcessInstanceUpdate({
          processInstanceUuid: subprocessUuid,
          tokenUuid: null,
          currentNodeId: null,
        })
      })
    }

    if (getConfig().keepState) {
      FlxProcessService.updateLocalStorage(processInstanceMetadata)
    }

    this.updateUrl(processInstanceMetadata.uuid)

    this.initSocketConnection(processInstanceMetadata.webSocketPath, processInstanceMetadata.uuid)

    // handleResponse is called for every process but listener must be
    // setup up only once (it already iterates through the processes socket list)
    if (!this.authTokenListener) {
      this.rebuildSocketConnectionsWhenAuthTokenIsChanged()
    }
  }

  private static updateLocalStorage(processInstanceMetadata: StartProcessResponse): void {
    const processInstances = getLocalStorageItem(PROCESS_CONFIG_STORAGE_KEY)

    setLocalStorageItem(PROCESS_CONFIG_STORAGE_KEY, {
      ...processInstances,
      [getConfig().processName]: processInstanceMetadata.uuid,
    })
  }

  startProcess(processStartData: Record<string, any>): Observable<any> {
    return this.http.post(this.buildProcessStartUrl(), processStartData, {
      headers: this.headers,
    })
  }

  getProcessInstanceByUuid(
    apiUrl: string,
    processApiPath: string,
    processUuid: string
  ): Observable<any> {
    return this.http.get(`${apiUrl}${processApiPath}/api/process/${processUuid}/status`, {
      headers: this.headers,
    })
  }

  private initSocketConnection(webSocketPath: string, processInstanceUuid: string): void {
    if (!processInstanceUuid) {
      throw new Error('The processInstanceUuid has a null/undefined value!')
    }
    if (!webSocketPath) {
      throw new Error('The webSocketPath has a null/undefined value!')
    }

    // Close any existing websocket connections
    if (this.websocket[processInstanceUuid]) {
      this.websocket[processInstanceUuid].close()
    }

    // TODO: find a better solution for removing https
    const webSocketUrl = getConfig().apiUrl.replace('https://', 'wss://')

    const token = localStorage.getItem('access_token')

    this.websocket[processInstanceUuid] = io(`${webSocketUrl}/${processInstanceUuid}`, {
      path: webSocketPath,
      query: {
        ...(token && { authtoken: token }),
      },
    })

    this.websocket[processInstanceUuid].on(SOCKET_MESSAGE_TYPE.Connect, () => {
      this.websocket[processInstanceUuid].emit(SOCKET_MESSAGE_TYPE.Status, {
        frontendStatus: 'connected',
      })
    })

    this.websocket[processInstanceUuid].on(SOCKET_MESSAGE_TYPE.Status, (data: string) => {
      console.info('WEB SOCKET STATUS EVENT', data)
    })

    this.websocket[processInstanceUuid].on(SOCKET_MESSAGE_TYPE.GeneralData, (data: string) => {
      const parsedData = this.getParsedData(data)
      if (parsedData) {
        if (parsedData.notifications?.length) {
          ProcessNotificationStore.updateNotifications(
            processInstanceUuid,
            parsedData.notifications
          )
        }
      }
    })

    this.websocket[processInstanceUuid].on(SOCKET_MESSAGE_TYPE.Data, (data: string) => {
      const parsedData = this.getParsedData(data)
      if (parsedData) {
        updateProcessData(processInstanceUuid, parsedData)
      }
    })

    this.websocket[processInstanceUuid].on(SOCKET_MESSAGE_TYPE.ProcessMetadata, (data: string) => {
      const payload = this.getParsedData(data)

      if (payload?.progressUpdateDTO) {
        setLoader(false)
        ProcessInstanceService.handleProcessInstanceUpdate(
          payload.progressUpdateDTO as ProgressUpdateDTO
        )
      }
    })

    this.websocket[processInstanceUuid].on(SOCKET_MESSAGE_TYPE.RunAction, (data: string) => {
      const parsedData = this.getParsedData(data)

      setLoader(false)
      // TODO get rid of parsedData as ProcessAction
      const actionFunc = this.buildAction(
        ProcessInstanceStore.getActiveProcessInstanceIdValue(),
        parsedData as ProcessAction
      )

      actionFunc().subscribe()
    })

    if (getConfig().debugLogs) {
      this.setupWSConnectionEventsLogging(this.websocket[processInstanceUuid], processInstanceUuid)
    }
  }

  private rebuildSocketConnectionsWhenAuthTokenIsChanged(): void {
    this.authTokenListener = (event: LocalStorageEvent) => {
      if (event.key !== 'access_token') {
        return
      }

      Object.entries(this.websocket).forEach(([processInstanceUuid, value]) => {
        const processWebSocketPath = getProcessInstanceValueById(processInstanceUuid).websocketPath
        this.initSocketConnection(processWebSocketPath, processInstanceUuid)
      })
    }

    // this is a custom event defined in this library
    document.addEventListener(LOCAL_STORAGE_EVENT_KEY, this.authTokenListener)
  }

  getParsedData(data: string): Record<string, any> | null {
    let parsedData = null
    try {
      parsedData = JSON.parse(data)
    } catch (e) {
      parsedData = null
    }

    if (getConfig().debugLogs) {
      this.logWebsocketDataEvent(data, parsedData)
    }

    return parsedData
  }

  private logWebsocketDataEvent(rawData: string, parsedData: Record<string, any>): void {
    if (parsedData === null) {
      console.info('WEB SOCKET MESSAGE: ', rawData)
    }

    console.groupCollapsed('WEB SOCKET DATA EVENT')
    console.info(`=== RAW DATA ===`)
    console.info(rawData)
    console.info(`=== PARSED DATA ===`)
    console.info(parsedData)
    console.groupEnd()
  }

  private setupWSConnectionEventsLogging(
    websocket: SocketIOClient.Socket,
    processInstanceUuid: string
  ): void {
    websocket.on(SOCKET_MESSAGE_TYPE.Connect, () => {
      console.info(`WEB SOCKET: connected on ${processInstanceUuid}`)
    })

    websocket.on(SOCKET_MESSAGE_TYPE.Disconnect, () => {
      console.info(`WEB SOCKET on ${processInstanceUuid} disconnected`)
    })
  }

  public bindDataAndActionsToComponent(
    processInstanceUuid: string,
    uiTemplateId: number,
    inputKeys: string[],
    component: ComponentRef<any>
  ): void {
    component.instance.data$ = combineLatest([
      unfoldedProcessData$(processInstanceUuid, inputKeys),
      processActionsByTemplateId$(processInstanceUuid, uiTemplateId),
    ]).pipe(
      filter(([data, actions]) => !_isEmpty(data)),
      map(([data, actions]) => {
        // TODO Should we still pass actions to custom components?
        return {
          ...data,
          actions,
          ...this.buildActionFunctions(processInstanceUuid, actions),
        }
      })
    )
  }

  buildActionFunctions(processInstanceUuid: string, actions: ProcessAction[]): Record<string, any> {
    return {
      actionsFn: actions.reduce(
        (acc: any, action: ProcessAction) => ({
          ...acc,
          [action.name]: this.buildAction(processInstanceUuid, action),
        }),
        {}
      ),
    }
  }

  public buildAction(
    processInstanceUuid: string,
    action: ProcessAction
  ): (params?: Record<string, any> | FormData) => Observable<any> {
    // const dataModelSlice =
    //   // TODO: Use separate logic for custom components vs forms
    //   // action.keys?.length > 0 && processInstanceUuid
    //   //   ? unfoldedProcessData$(processInstanceUuid, action.keys).pipe(
    //   //       tap(() => console.log('unfold emit'))
    //   //     )
    //   //   : of({})

    return (params?: Record<string, any> | FormData): Observable<any> => {
      switch (action.type) {
        case ProcessActionType.EXTERNAL:
          if (action.params?.dismissProcess) {
            removeAllProcessData(processInstanceUuid)
          }
          window.open(action.endpoint, action.params?.newTab ? '_blank' : '_self')

          return of(null)

        case ProcessActionType.ACTION:
          // TODO: Figure out how to search for the form control (with dots in the name) to validate
          // const form = new FormGroup({
          //     contactData: new FormGroup({
          //       'application.client.contactData.email.emailAddress': 'form-control'
          //     }),
          //     addressData: new FormGroup({
          //       'application.client.address': 'form-control'
          //     })
          //   })
          //
          //  this.form.get(['contactData', 'application.client.contactData.email.emailAddress'])

          // The problem is: how to validate only a subset of fields from the form group (this is why we need an array)
          const formToValidate = action.params?.formKey
            ? (this.formService.getFormGroup(action.params.formKey) as FormGroup)
            : null

          // const isModelEmpty =
          //   action?.keys &&
          //   action.keys
          //     .filter((actionKey) => !_get(actionKey, dataModelSlice))
          //     .map((item) => Boolean(item)).length

          const processData = getProcessDataValueById(processInstanceUuid)

          const dataModelSlice =
            action.keys?.length > 0 && processInstanceUuid
              ? unfoldedProcessData(processData, action.keys)
              : {}

          const payload = {
            ...(!_isEmpty(dataModelSlice) ? dataModelSlice : {}),
            ...action.customBody,
            ...(params ? unflatten(params) : {}),
          }

          if (formToValidate) {
            this.formService.submittedForms$.next({
              [action.params.formKey.join('.')]: true,
            })
          }

          if (!formToValidate || formToValidate?.valid) {
            if (action.params?.blocksUi) {
              setLoader(true)
            }

            return this.buildHTTPRequest(
              new HttpRequest('POST', this.buildActionUrl(processInstanceUuid, action), payload)
            ).pipe(
              tap((actionResponse: ExecuteActionResponse) => {
                this.refreshSocket(processInstanceUuid)

                if (action.params?.dismissProcess) {
                  removeAllProcessData(processInstanceUuid)
                }

                if (formToValidate) {
                  this.formService.submittedForms$.next({
                    [action.params.formKey.join('.')]: false,
                  })

                  formToValidate.markAsPristine({ onlySelf: true })
                }

                if (actionResponse.resetToken) {
                  this.handleTokenReset(actionResponse, processInstanceUuid).subscribe()
                }
              })
            )
          }

          return of()

        case ProcessActionType.START_PROCESS_INHERIT:
          return this.clientStoreRepository.store.data$.pipe(
            take(1),
            map((clientData) => ({ ...params, ...clientData })),
            switchMap((clientData: Record<string, any>) => {
              const body = {
                ...action.params,
                ...action.clientDataKeys?.reduce(
                  (acc, paramKey) => ({
                    ...acc,
                    ...clientData[paramKey],
                  }),
                  {}
                ),
              }

              const request = new HttpRequest(
                'POST',
                this.buildStartInheritUrl(processInstanceUuid, action.processName),
                body
              )

              return this.buildHTTPRequest(request)
            }),
            tap((processInstanceMetadata: StartProcessResponse) => {
              this.refreshSocket(processInstanceUuid)

              if (processInstanceMetadata) {
                this.handleResponse(processInstanceMetadata)
              }
            })
          )

        case ProcessActionType.UPLOAD:
          return this.buildHTTPRequest(
            new HttpRequest('POST', this.buildActionUrl(processInstanceUuid, action), params, {
              // Delete the Content-Header to allow the browser to detect automatically that
              // it is a "multipart/form-data" and to calculate the "boundary" param
              headers: new HttpHeaders().delete('Content-Type'),
            })
          ).pipe(
            tap((actionResponse: ExecuteActionResponse) => {
              this.refreshSocket(processInstanceUuid)

              if (action.params?.dismissProcess) {
                removeAllProcessData(processInstanceUuid)
              }

              if (actionResponse.resetToken) {
                this.handleTokenReset(actionResponse, processInstanceUuid).subscribe()
              }
            })
          )

        case ProcessActionType.DISMISS:
          return dismissProcess(processInstanceUuid)

        default:
          throw new Error(`No action handler for action type ${action.type}`)
      }
    }
  }

  private refreshSocket(processInstanceUuid: string): void {
    let processUuid = processInstanceUuid

    // Find the first parent process which has a websocket connection
    while (!this.websocket[processUuid]) {
      processUuid = getProcessInstanceValueById(processUuid).parentProcessUuid
    }

    if (this.websocket[processUuid]?.connected) {
      return
    }

    if (getConfig().debugLogs) {
      console.info('WEB SOCKET disconnected: reconnecting...')
    }

    this.websocket[processUuid].connect()
  }

  private buildHTTPRequest(request): Observable<any> {
    // if response type === 0, it means it's the response to the preflight request
    return this.http.request(request).pipe(
      filter((response) => response.type !== 0),
      map((response: any) => response.body)
    )
  }

  private buildProcessStartUrl(): string {
    const { apiUrl, processName, processApiPath, isDraft } = getConfig()
    return isDraft
      ? `${apiUrl}${processApiPath}/api/internal/process/${processName}/start`
      : `${apiUrl}${processApiPath}/api/process/${processName}/start`
  }

  private buildStartInheritUrl(processInstanceUuid, processName): string {
    const { apiUrl, processApiPath } = getConfig()
    return `${apiUrl}${processApiPath}/api/process/${processName}/start/inheritFrom/${processInstanceUuid}`
  }

  private buildActionUrl(processInstanceUuid: string, action: ProcessAction): string {
    const tokenUuid =
      ProcessInstanceStore.getProcessInstanceValueById(processInstanceUuid).currentTokenUuid

    const { apiUrl, processApiPath } = getConfig()
    return `${apiUrl}${processApiPath}/api/process/${processInstanceUuid}/token/${tokenUuid}/action/${
      action.actionName
    }/${ProcessActionMap[action.type]}`
  }

  private handleTokenReset(
    actionResponse: ExecuteActionResponse,
    processInstanceUuid: string
  ): Observable<any> {
    ProcessInstanceStore.updateProcessInstanceCurrentState(
      processInstanceUuid,
      actionResponse.currentNodeId,
      actionResponse.tokenUuid
    )

    resetProcessDataStore()

    return ProcessInstanceService.getDataForTemplateSequences(
      processInstanceUuid,
      actionResponse.templatesSequence
    )
  }

  reset(): void {
    // Which websocket connection am i closing? all of them? just the current one? and when
    // this.websocket?.close()

    TemplateConfigStore.reset()
    ProcessInstanceStore.reset()
    ProcessActionsStore.resetActions()
    resetProcessDataStore()
    ProcessNotificationStore.resetNotifications()

    this.formService.resetForm()
    removeLocalStorageItem(PROCESS_CONFIG_STORAGE_KEY)
    this.clientStoreRepository.store.reset()

    if (this.authTokenListener) {
      document.removeEventListener(LOCAL_STORAGE_EVENT_KEY, this.authTokenListener)
    }
  }
}
