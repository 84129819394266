import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IconModule } from '../icon/icon.module';
import { AccordionComponent } from './accordion.component';
import { AccordionItemComponent } from './accordion-item.component'
import { arrow, menu, close, success, playVertical, calendar } from '../icon/ppfIcons'

@NgModule({
  declarations: [AccordionComponent, AccordionItemComponent],
  imports: [CommonModule, IconModule.forChild({ icons: [arrow, menu, close, success, playVertical, calendar] })],
  exports: [AccordionComponent, AccordionItemComponent],
})
export class AccordionModule {}
