import { Component } from '@angular/core'
import { getConfig } from '../../../store/process-config'
import { TemplateConfigInterface } from '../../../dictionary/flx-template.dictionary'
import { FlxWrapperComponent } from '../../../flx-wrapper'

@Component({
  selector: 'flx-message-wrapper',
  templateUrl: './message-wrapper.component.html',
  styleUrls: ['./message-wrapper.component.scss'],
})
export class MessageWrapperComponent extends FlxWrapperComponent<TemplateConfigInterface> {
  onActionClick(action): void {
    if (action?.url) {
      window.open(action.url, '_blank')
    } else if (action?.src) {
      const processConfig = getConfig()
      window.open(`${processConfig.apiUrl}/documents/${action.src}`, '_blank')
    }
  }
}
