import { Component, Input } from '@angular/core'

import {
  DisplayOptionsInterface,
  TemplateConfigInterface,
} from '../../dictionary/flx-template.dictionary'
import { FlxWrapperComponent } from '../../flx-wrapper'

@Component({
  selector: 'flx-page',
  templateUrl: './flx-page.component.html',
  styleUrls: ['./flx-page.component.scss'],
})
export class FlxPageComponent extends FlxWrapperComponent<{
  displayOptions: DisplayOptionsInterface
  templateConfig: TemplateConfigInterface
}> {}
