import { ComponentRef } from '@angular/core'
import { OverlayRef } from '@angular/cdk/overlay'
import { Observable } from 'rxjs'

import { ModalComponentType } from './modal.dictionary'

export class ModalRef {
  data$: Observable<any>
  componentRef: ComponentRef<any>

  constructor(private readonly overlayRef: OverlayRef) {}

  close() {
    this.overlayRef.dispose()
  }
}
