import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { PaginationComponent } from './pagination.component'
import { IconModule } from '../icon/icon.module'
import { arrow } from '../icon/ppfIcons'

@NgModule({
  declarations: [PaginationComponent],
  imports: [CommonModule, IconModule.forChild({ icons: [arrow] })],
  exports: [PaginationComponent],
})
export class PaginationModule {}
