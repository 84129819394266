import { LOCAL_STORAGE_EVENT_KEY } from './dictionary/flx.dictionary'

const originalSetItem = localStorage.setItem

export type LocalStorageEvent = Event & {
  key: string
  value: string
}

localStorage.setItem = function (key: string, value: string) {
  originalSetItem.bind(this)(key, value)

  const event = new Event(LOCAL_STORAGE_EVENT_KEY)
  // @ts-ignore
  event.key = key
  // @ts-ignore
  event.value = value

  document.dispatchEvent(event)
}
