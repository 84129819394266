import { BehaviorSubject } from 'rxjs'
import { tap } from 'rxjs/operators'

export type UiStore = {
  showLoader: boolean
}

const uiStoreInitialState: UiStore = {
  showLoader: false,
}

const uiStore = new BehaviorSubject<UiStore>(uiStoreInitialState)

export const uiStore$ = uiStore.asObservable()

export function setLoader(showLoader: boolean): void {
  const state = uiStore.value

  uiStore.next({
    ...state,

    showLoader,
  })
}
