import { Pipe, PipeTransform } from '@angular/core'
import { getSubstitutionTagByKey } from '../store/substitution-tags.store'

export const LOCALIZATION_IDENTIFIER = '@@'
@Pipe({
  name: 'flxLocalize',
})
export class FlxLocalizePipe implements PipeTransform {
  transform(value: string): string {
    if (value) {
      return value.substring(0, 2) === LOCALIZATION_IDENTIFIER
        ? getSubstitutionTagByKey(value?.replace(LOCALIZATION_IDENTIFIER, ''))
        : value
    }
  }
}
