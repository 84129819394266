export type BadgeType = 'warning' | 'info' | 'success' | 'error'
export type DisplayType = 'fill' | 'border'

export const BADGE_TYPES = {
  SUCCESS: 'success',
  ERROR: 'error',
  WARNING: 'warning',
  INFO: 'info',
  FILL: 'fill',
  BORDER: 'border',
}

export const DEFAULT_DISPLAY_TYPE = 'fill'
export const DEFAULT_MESSAGE_TYPE = 'success'
