import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { IconModule } from '../icon/icon.module'
import { FileUploadComponent } from './file-upload.component'

@NgModule({
  declarations: [FileUploadComponent],
  imports: [CommonModule, IconModule.forChild()],
  exports: [FileUploadComponent],
})
export class FileUploadModule {}
