import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core'
import { ButtonType } from '../button/dictionary'
import { DomSanitizer } from '@angular/platform-browser'
export type FlxFileSelectEvent = {
  originalEvent: any
  validFiles: File[]
  files: any
  errors: Record<string, string>
}

@Component({
  selector: 'ppf-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FileUploadComponent {
  @Input() mode = 'basic'
  @Input() accept: string
  @Input() auto = true
  @Input() maxFileSize: number
  @Input() multiple = false
  @Input() disabled: boolean
  @Input() fileLimit: number
  @Input() type: ButtonType = 'fill'
  @Input() rightIconName: string | null = null
  @Input() leftIconName: string | null = null
  @Input() label = 'Upload file'

  @Input() set files(files) {
    this._files = files.filter((file) => this.isFileValid(file))
  }

  get files(): File[] {
    return this._files
  }

  @Output() selectFile: EventEmitter<FlxFileSelectEvent> = new EventEmitter()

  @ViewChild('basicfileinput') basicFileInput: ElementRef

  errors: Record<string, string> = {}

  public _files: File[] = []

  constructor(public sanitizer: DomSanitizer) {}

  onBasicUploaderClick(): void {
    if (this.hasFiles()) {
      this.upload()
    } else {
      this.basicFileInput.nativeElement.click()
    }
  }

  upload(): void {}

  onFileSelect(event: any): void {
    this.errors = {}
    if (this.multiple) {
      this.files = []
    }
    const files = event.target.files
    for (const file of files) {
      if (this.isFileValid(file)) {
        if (this.isImage(file)) {
          file.objectURL = this.sanitizer.bypassSecurityTrustUrl(window.URL.createObjectURL(file))
        }
        this.files.push(file)
      }
    }
    this.selectFile.emit({
      originalEvent: event,
      files,
      validFiles: this.files,
      errors: this.errors,
    })
    this.clearInputElement()
  }

  isFileValid(file: File): boolean {
    return (
      (!this.accept || (this.accept && this.isFileTypeValid(file))) &&
      !this.isMaxFileSizeExceeded(file.size)
    )
  }

  private isFileTypeValid(file: File): boolean {
    const acceptableTypes = this.accept.split(',').map((type) => type.trim())
    for (const type of acceptableTypes) {
      const acceptable = this.isWildcard(type)
        ? this.getTypeClass(file.type) === this.getTypeClass(type)
        : file.type === type || this.getFileExtension(file).toLowerCase() === type.toLowerCase()

      if (acceptable) {
        return true
      }
    }
    this.errors = { ...this.errors, ['invalidFileType']: 'File type is invalid' }

    return false
  }

  isWildcard(fileType: string): boolean {
    return fileType.includes('*')
  }

  isMaxFileSizeExceeded(fileSize): boolean {
    const fileSizeExceeded = this.maxFileSize && fileSize > this.maxFileSize
    if (fileSizeExceeded) {
      this.errors = { ...this.errors, ['fileSizeExceeded']: 'File size exceeded' }
    }
    return fileSizeExceeded
  }

  getTypeClass(fileType: string): string {
    return fileType.substring(0, fileType.indexOf('/'))
  }

  getFileExtension(file: File): string {
    return `.${file.name.split('.').pop()}`
  }

  hasFiles(): boolean {
    return this.files?.length > 0
  }

  isImage(file: File): boolean {
    return /^image\//.test(file.type)
  }

  clearInputElement() {
    if (this.basicFileInput && this.basicFileInput.nativeElement) {
      this.basicFileInput.nativeElement.value = ''
    }
  }
}
