import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core'
import { FormControl, FormGroup } from '@angular/forms'
import { Subscription } from 'rxjs'
import { tap } from 'rxjs/operators'

import { processDataValueFromPath$ } from '../../../store/process-data.store'
import { NomenclatorValue } from '../../../dictionary/flx-nomenclator.dictionary'
import { FlxNomenclatorRepository } from '../../../flx-nomenclator.repository'
import { getOptionsObs } from '../../../services/options-field-helper'
import { FlxWrapperComponent } from '../../../flx-wrapper'

@Component({
  selector: 'flx-checkbox-wrapper',
  templateUrl: './checkbox-wrapper.component.html',
  styleUrls: ['./checkbox-wrapper.component.scss'],
})
export class CheckboxWrapperComponent extends FlxWrapperComponent implements OnInit, OnDestroy {
  options: NomenclatorValue[] = []
  checkboxesFormGroup: FormGroup
  subscriptions: Subscription = new Subscription()

  constructor(
    private nomenclatorRepository: FlxNomenclatorRepository,
    private cdr: ChangeDetectorRef
  ) {
    super()
  }

  ngOnInit(): void {
    const parentFormGroup = this.config.control.parent as FormGroup

    this.subscriptions.add(
      getOptionsObs(
        this.config.dataSource,
        parentFormGroup,
        this.nomenclatorRepository,
        this.config.processInstanceUuid
      )
        .pipe(tap((options: NomenclatorValue[]) => this.createControls(options)))
        .subscribe()
    )
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe()
  }

  isChecked(value: any): boolean {
    let isChecked: boolean
    this.subscriptions.add(
      processDataValueFromPath$(this.config.processInstanceUuid, this.config.key)
        .pipe(tap((values: any[]) => (isChecked = values ? values.includes(value) : false)))
        .subscribe()
    )
    return isChecked
  }

  trackByFn(index: number, item: { id: number }): number {
    return item.id
  }

  private checkboxObjectToValuesArray(object: Record<string, boolean>): string[] {
    return Object.keys(object).reduce((acc: string[], key: string) => {
      return object[key] ? [...acc, key] : acc
    }, [])
  }

  private createControls(options: NomenclatorValue[]): void {
    const group = {}

    this.options = options

    this.options.forEach((option) => {
      group[option.value] = new FormControl(this.isChecked(option.value))
    })

    this.checkboxesFormGroup = new FormGroup(group)

    this.subscriptions.add(
      this.checkboxesFormGroup.valueChanges
        .pipe(tap((value) => this.config.control.setValue(this.checkboxObjectToValuesArray(value))))
        .subscribe()
    )
    this.cdr.detectChanges()
  }
}
