import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { InputRefDirective } from './input.directive'
import { InputMaskRefDirective } from './input-mask.directive';

@NgModule({
  declarations: [InputRefDirective, InputMaskRefDirective],
  imports: [CommonModule],
  exports: [InputRefDirective, InputMaskRefDirective],
})
export class SharedModule {}
