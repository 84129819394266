import { Injectable, ComponentFactoryResolver, ViewContainerRef, ComponentRef } from '@angular/core'

import { FlxComponentResolver } from './flx-component-resolver.service'

/**
 * This service is used internally to render a component with a certain ID in a given
 * viewContainerRef (viewport) and to setup the component data and action bindings.
 */
@Injectable({
  providedIn: 'root',
})
export class FlxComponentRendererService {
  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private flxComponentResolver: FlxComponentResolver
  ) {}

  render(viewport: ViewContainerRef, componentId: string, config?: any): ComponentRef<any> | null {
    const componentType = this.flxComponentResolver.resolveComponent(componentId)
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(componentType)

    if (!viewport) {
      throw new Error('The provided viewport has a null/undefined value!')
    }

    viewport.clear()
    const componentRef = viewport.createComponent(componentFactory)
    componentRef.instance.config = config

    return componentRef
  }
}
