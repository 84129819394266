import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { IconModule } from '../icon/icon.module'
import { AvatarComponent } from './avatar.component'
import { user } from '../icon/ppfIcons'

@NgModule({
  declarations: [AvatarComponent],
  imports: [CommonModule, IconModule.forChild({ icons: [user] })],
  exports: [AvatarComponent],
})
export class AvatarModule {}
