import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { MessageComponent } from './message.component'
import { IconModule } from '../icon/icon.module'
import { message, play, pause, success, error, warning, close, info } from '../icon/ppfIcons'

@NgModule({
  declarations: [MessageComponent],
  imports: [CommonModule, IconModule.forChild({ icons: [message, play, pause, success, error, warning, close, info] })],
  exports: [MessageComponent],
})
export class MessageModule {}
