import { Component, Input, HostBinding, ChangeDetectionStrategy, OnInit } from '@angular/core'
import {
  MessageType,
  DisplayType,
  LayoutType,
  DEFAULT_DISPLAY_TYPE,
  DEFAULT_MESSAGE_TYPE,
  DEFAULT_LAYOUT_TYPE,
} from './message.dictionary'

@Component({
  selector: 'ppf-message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MessageComponent {
  private _messageType: MessageType = DEFAULT_MESSAGE_TYPE
  private _layoutType: LayoutType = DEFAULT_LAYOUT_TYPE
  @Input() text: string

  @Input() set layoutType(layoutType: LayoutType) {
    this._layoutType = layoutType || DEFAULT_LAYOUT_TYPE
  }

  get layoutType(): LayoutType {
    return this._layoutType
  }

  @Input() set messageType(messageType: MessageType) {
    this._messageType = messageType || DEFAULT_MESSAGE_TYPE
  }

  get messageType(): MessageType {
    return this._messageType
  }

  @Input() displayType: DisplayType = DEFAULT_DISPLAY_TYPE

  @HostBinding('class')
  get classes() {
    return `ppf-message-${this.messageType} ppf-message-${this.displayType} ppf-message-${this.layoutType}`
  }
}
