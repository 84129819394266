import { Component } from '@angular/core'
import { FlxWrapperComponent } from '../../../flx-wrapper'
import moment from 'moment'

@Component({
  selector: 'flx-datepicker-wrapper',
  templateUrl: './datepicker-wrapper.component.html',
  styleUrls: ['./datepicker-wrapper.component.css'],
})
export class DatepickerWrapperComponent extends FlxWrapperComponent {
  get minDate(): Date | null {
    return this.config.displayOptions?.flowxProps?.minDate
      ? moment(this.config.displayOptions.flowxProps.minDate).local().toDate()
      : null
  }
  get maxDate(): Date | null {
    return this.config.displayOptions?.flowxProps?.maxDate
      ? moment(this.config.displayOptions.flowxProps.maxDate).local().toDate()
      : null
  }
}
