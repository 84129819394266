import { Component, Input, ChangeDetectorRef, ContentChild, HostBinding } from '@angular/core'

// Separate import as a workaround for "...imported but not used" ngc warning
import { AfterViewInit } from '@angular/core'
import { CheckboxRefDirective } from './checkbox.directive'

let nextUniqueId = 0

@Component({
  selector: 'ppf-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
})
export class CheckboxComponent implements AfterViewInit {
  @ContentChild(CheckboxRefDirective) checkbox: CheckboxRefDirective | null
  @Input() label: string = ''
  @Input() iconName: string = 'checkmark'

  checkboxId: string

  constructor(private changeDetectoRef: ChangeDetectorRef) {}

  @HostBinding('class.disabled-state')
  get isDisabled() {
    return this.checkbox ? this.checkbox.isDisabled : false
  }

  ngAfterViewInit(): void {
    if (!this.checkbox) {
      throw Error(
        `You must provide a child input of type 'checkbox' using 'ppfCheckbox' directive to ppf-checkbox!`
      )
    }

    this.setupCheckboxId()
  }

  private setupCheckboxId() {
    this.checkboxId = this.checkbox.id
      ? `${this.checkbox.id}-${nextUniqueId++}`
      : `ppf-checkbox-${nextUniqueId++}`
    this.changeDetectoRef.detectChanges()
  }
}
