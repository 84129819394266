import { ProcessConfig } from '../dictionary/flx-process.dictionary'
import axios, { AxiosInstance } from 'axios'

export function getFlowXEngineAxiosInstance(config: ProcessConfig): AxiosInstance {
  return axios.create({
    baseURL: `${config.apiUrl}${config.processApiPath}`,
  })
}

export function getFlowXAdminAxiosInstance(config: ProcessConfig): AxiosInstance {
  return axios.create({
    baseURL: `${config.apiUrl}/admin`,
  })
}

export function getFlowXCMSAxiosInstance(config: ProcessConfig): AxiosInstance {
  return axios.create({
    baseURL: `${config.apiUrl}/cms`,
  })
}

export function buildAuthHeader(): { Authorization: string } | {} {
  const token = localStorage.getItem('access_token')

  return token
    ? {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      }
    : {}
}
