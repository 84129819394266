export type FieldType = 'box' | 'line'
export type StatusType = 'error' | 'success' | 'warning'

export const INPUT_TYPES = {
  box: 'type-box',
  line: 'type-line',
}

export const FIELD_BORDER = {
  error: 'error-border',
  warning: 'warning-border',
  success: 'success-border',
}

export const FIELD_STATE = {
  error: 'error-state',
  warning: 'warning-state',
  success: 'success-state',
}
