import { Component, HostBinding, Input, OnInit } from '@angular/core'
import { FormGroup } from '@angular/forms'
import { FlxFormGroup, TemplateConfigInterface } from '../../dictionary/flx-template.dictionary'
import { FlxFormService } from '../../services/flx-form.service'
import { combineLatest, Observable, of } from 'rxjs'
import { map } from 'rxjs/operators'

import { extractPlaceholders, replaceExpressionPlaceholders } from '../../flx.utils'
import { isHidden$, unfoldedProcessData$ } from '../../store/process-data.store'

@Component({
  selector: 'flx-form-group',
  templateUrl: './flx-form-group.component.html',
  styleUrls: ['./flx-form-group.component.scss'],
})
export class FlxFormGroupComponent implements OnInit {
  isHidden$: Observable<boolean>
  title$: Observable<string>
  subtitle$: Observable<string>
  @HostBinding('class')
  classes = ''

  @Input() form: FormGroup

  private _config: FlxFormGroup
  private hiddenDeps
  public isOpen

  @Input()
  set config(data: FlxFormGroup) {
    this._config = data
    this.classes = this._config?.templateConfig?.displayOptions?.class || ''
  }

  get templateConfig(): TemplateConfigInterface {
    return this._config.templateConfig
  }

  get isDisabled$(): Observable<boolean> {
    return this._config.isDisabled$
  }

  get isCurrent$(): Observable<boolean> {
    return this._config.isCurrent$
  }

  get style(): string {
    return this._config?.templateConfig?.displayOptions?.style || ''
  }

  context$: Observable<{
    isDisabled: boolean
    isCurrent: boolean
    isHidden: boolean
  }>

  constructor(private flxFormService: FlxFormService) {}

  ngOnInit(): void {
    this.buildForm()
    this.form = this.flxFormService.getFormGroup(this._config.templateConfig.key)
    this.hiddenDeps = extractPlaceholders(this._config.templateConfig?.expressions?.hide)

    this.isHidden$ = isHidden$(this.hiddenDeps, this._config.templateConfig)

    this.title$ = unfoldedProcessData$(
      this._config.templateConfig.processInstanceUuid,
      extractPlaceholders(this._config.templateConfig.displayOptions?.flowxProps?.label)
    ).pipe(
      map((model) => {
        return this._config.templateConfig.displayOptions?.flowxProps?.hasOwnProperty('label')
          ? replaceExpressionPlaceholders({
              text: this._config.templateConfig.displayOptions?.flowxProps?.label,
              model,
              keepStringValueQuotes: false,
            })
          : ''
      })
    )

    this.subtitle$ = unfoldedProcessData$(
      this._config.templateConfig.processInstanceUuid,
      extractPlaceholders(this._config?.templateConfig?.displayOptions?.flowxProps?.message)
    ).pipe(
      map((model) => {
        return this._config.templateConfig.displayOptions?.flowxProps?.hasOwnProperty('message')
          ? replaceExpressionPlaceholders({
              text: this._config.templateConfig.displayOptions?.flowxProps?.message,
              model,
              keepStringValueQuotes: false,
            })
          : ''
      })
    )
    this.context$ = combineLatest([this.isDisabled$, this.isCurrent$, this.isHidden$]).pipe(
      map(([isDisabled, isCurrent, isHidden]) => ({
        isDisabled,
        isCurrent,
        isHidden,
      }))
    )
  }

  onOpenChange(isOpen): void {
    this.isOpen = isOpen
  }

  buildForm(): void {
    this.flxFormService.buildForm(this._config.templateConfig)
  }
}
