import { Component, Input } from '@angular/core'
import { PanelMenuItem } from './panel-menu.dictionary'

@Component({
  selector: 'ppf-panel-menu',
  templateUrl: './panel-menu.component.html',
  styleUrls: ['./panel-menu.component.scss'],
})
export class PanelMenuComponent {
  @Input() menuItems: PanelMenuItem<any>[]
}
