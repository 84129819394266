import { TemplateConfigInterface } from './flx-template.dictionary'

export type ProcessConfig = {
  apiUrl: string
  processApiPath: string
  processName: string
  processStartData: Record<string, any>
  debugLogs?: boolean
  tokens?: any[]
  isDraft: boolean
  keepState: boolean
  debugCustomComponents: boolean
  language?: string
}

export enum ProcessActionType {
  DISMISS = 'DISMISS',
  ACTION = 'ACTION',
  START_PROCESS_INHERIT = 'START_PROCESS_INHERIT',
  UPLOAD = 'UPLOAD',
  EXTERNAL = 'EXTERNAL',
}

export const ProcessActionMap = {
  [ProcessActionType.ACTION]: 'execute',
  [ProcessActionType.UPLOAD]: 'upload',
}

export type ProcessAction = {
  name: string
  actionName: string
  keys: any[]
  type: ProcessActionType
  httpVerb: 'POST'
  endpoint: string
  params: ProcessActionParams
  customBody: any

  processName?: string
  uiTemplateId?: number

  clientDataKeys?: string[]
}

export type ExecuteActionResponse = {
  resetToken: boolean
  tokenUuid: string
  currentNodeId: number
  templatesSequence: number[]
  backAction: boolean
}

export type ProcessActionParams = {
  validate: boolean
  formKey: string[]
  blocksUi: boolean
  dismissProcess?: boolean
  newTab?: boolean
}

export type ProcessToken = {
  uuid: string
  currentNodeId: number
}

export enum SOCKET_MESSAGE_TYPE {
  Component = 'component',
  Message = 'message',
  ProcessMetadata = 'processMetadata',
  Data = 'data',
  RunAction = 'runAction',
  Status = 'status',
  Connect = 'connect',
  Disconnect = 'disconnect',
  GeneralData = 'generalData',
}

// ? E ok process state, sau ar trebui sa fie token state
export enum PROCESS_STATE {
  STARTED = 'STARTED',
}

// ? E ok sau ar trebui sa fie acelasi cu PROCESS_STATE
export enum TOKEN_STATE {
  ACTIVE = 'ACTIVE',
}

// ? E ok sau ar trebui sa fie acelasi cu PROCESS_STATE sau TOKEN_STATE
export enum NODE_STATE {
  ARRIVED = 'ARRIVED',
}

export type StartProcessResponse = {
  uuid: string
  templateConfig: TemplateConfigInterface[]
  tokens: ProcessToken[]
  webSocketPath: string
  templatesSequence?: number[]
  subprocessesUuids: any[]
  generalData: { notifications?: any[] }
}

export type ProgressUpdateDTO = {
  currentNodeId: number
  processInstanceUuid: string
  tokenUuid: string
}
