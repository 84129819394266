import {
  Directive,
  HostListener,
  Input,
  ElementRef,
  AfterViewInit,
  Provider,
  forwardRef,
  HostBinding,
} from '@angular/core'
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms'

export const MASKEDINPUT_VALUE_ACCESSOR: Provider = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => InputMaskRefDirective),
  multi: true,
}

@Directive({
  selector: '[ppfInputMask]',
  providers: [MASKEDINPUT_VALUE_ACCESSOR],
})
export class InputMaskRefDirective implements ControlValueAccessor, AfterViewInit {
  placeholder: string
  tests: any[] = []

  // keep the temporary valid chars from input
  buffer: any[] = []
  valid: boolean = false
  focus: boolean = false
  patterns = {
    '9': '[0-9]',
    'a': '[A-Za-z]',
    '*': '[A-Za-z]|[0-9]',
  }

  @Input() replaceChar: string = '_'
  @Input() mask: string
  @Input() disabled: boolean

  @HostBinding('disabled')
  get isDisabled() {
    return typeof this.disabled !== 'undefined' ? this.disabled : this.el.nativeElement.disabled
  }

  @HostListener('blur')
  onBlur() {
    this.focus = false

    // set value and model with empty string if the value does not meet the mask requirements
    if (!this.valid) {
      this.el.nativeElement.value = ''
      this.onChange(this.el.nativeElement.value)
    }
  }

  @HostListener('focus')
  onFocus() {
    this.focus = true

    // reset the mask and move cursor at he begining if the input is not valid
    if (!this.valid) {
      this.initMask()
      this.caret(0, 1)
    } else {
      this.caret(0, this.el.nativeElement.value.length)
    }
  }

  @HostListener('input', ['$event'])
  change(e) {
    this.checkVal()
  }

  @HostListener('keydown', ['$event'])
  onKeyDown(e) {
    const key = e.which || e.keyCode

    // specific rule for backspace and delete
    if (key === 8 || key === 46) {
      this.removeLastChar()
      return
    }
  }

  registerOnChange(fn: (_: any) => void): void {
    this.onChange = fn
  }
  registerOnTouched(fn: () => void): void {
    this.onTouched = fn
  }
  onChange = (_: any) => {}
  onTouched = (_: any) => {}

  constructor(private el: ElementRef) {}

  /* 
  the mask is splitted in an array of tests
  every char from mask has a coresponded RegExp pattern
  9 - number
  a - character a-z
  * - number or character
  symbols - are ignored, a null test is pushed
  */
  initMask() {
    this.tests = []
    let maskTokens = this.mask.split('')

    for (let i = 0; i < maskTokens.length; i++) {
      const c = maskTokens[i]
      if (this.patterns[c]) {
        this.tests.push(new RegExp(this.patterns[c]))
      } else {
        this.tests.push(null)
      }
    }

    this.resetBuffer()
    this.writeBuffer()
  }

  /*
  clear the temporary values
  */
  resetBuffer(): void {
    let maskTokens = this.mask.split('')
    const buffer = []
    for (let i = 0; i < maskTokens.length; i++) {
      let c = maskTokens[i]

      if (this.patterns[c]) {
        buffer.push(this.replaceChar)
      } else {
        buffer.push(c)
      }
    }
    this.buffer = buffer
  }

  checkVal(): void {
    this.resetBuffer()
    let lastGoodPos: number = 0

    let val = this.el.nativeElement.value
    val = val.replace(/[\W_-]/g, '')

    for (let i = 0, pos = 0; i < this.tests.length; i++) {
      if (this.tests[i]) {
        // for the symbols the pattern test is ignored
        if (this.tests[i] && val[pos] && this.tests[i].test(val[pos])) {
          // keep the last position of char and update the buffer  when the test of RegExp pattern is valid
          lastGoodPos = i
          this.buffer[i] = val[pos]
          pos++
        } else {
          // if the test is invalid mark the value as invalid, write the previous correct value and move cursor at the last character
          this.writeBuffer()
          this.valid = false
          if (i === 0) {
            this.caret(lastGoodPos, lastGoodPos + 1)
          }

          return
        }
      } else {
        lastGoodPos = i
      }

      this.valid = true
      this.writeBuffer()
      this.caret(lastGoodPos + 1, lastGoodPos + 2)
    }
  }

  // write the temporary values to input value and update the model
  writeBuffer(): void {
    this.el.nativeElement.value = this.buffer.join('')
    this.onChange(this.el.nativeElement.value)
  }

  /*
  remove the last char from input ignoring the symbols
  */
  removeLastChar(): void {
    const reverseIndex = this.buffer.length - 1
    for (let i = reverseIndex; i >= 0; i--) {
      const char = this.buffer[i].replace(/[\W_-]/g, '')
      if (char !== '') {
        this.buffer[i] = this.replaceChar
        this.writeBuffer()
        return
      }
    }
  }

  writeValue(value): void {
    this.el.nativeElement.value = value
  }

  setDisabledState(disabledFlag: boolean) {
    this.el.nativeElement.disabled = disabledFlag
  }

  ngAfterViewInit(): void {
    this.placeholder = this.el.nativeElement.placeholder
    this.el.nativeElement.placeholder = ''
  }

  // move the cursor within input value
  caret(first?: number, last?: number): void {
    const begin = first
    const end = last || begin
    if (this.el.nativeElement.setSelectionRange) {
      this.el.nativeElement.setSelectionRange(begin, end)
    }
  }
}
