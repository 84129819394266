import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { PanelMenuComponent } from './panel-menu.component'
import { IconModule } from '../icon/icon.module'
import { ButtonModule } from '../button/button.module'
import { CardModule } from '../card/card.module'
import { RouterModule } from '@angular/router'
import { message, play, pause } from '../icon/ppfIcons'

@NgModule({
  declarations: [PanelMenuComponent],
  imports: [CommonModule, IconModule.forChild({ icons: [message, play, pause] }), ButtonModule, CardModule, RouterModule],
  exports: [PanelMenuComponent],
})
export class PanelMenuModule {}
