import { NgModule, ModuleWithProviders, InjectionToken, Optional, SkipSelf } from '@angular/core'
import { CommonModule } from '@angular/common'
import { DatePickerComponent } from './date-picker.component'
import { MatDatepickerModule } from '@angular/material/datepicker'
import {
  MatNativeDateModule,
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
  MatDateFormats,
} from '@angular/material/core'
import { InputModule } from '../input/input.module'
import { IconModule } from '../icon/icon.module'
import { SharedModule } from '../shared/shared.module'

import { ReactiveFormsModule } from '@angular/forms'

import { MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter'
import { CustomDateAdapter } from './custom-date-adapter'
import { calendar, error, success, warning } from '../icon/ppfIcons'
import { MatInputModule } from '@angular/material/input'

const DEFAULT_DATE_FORMAT = {
  parse: {
    dateInput: 'DD.MM.YYYY',
  },
  display: {
    dateInput: 'DD.MM.YYYY',
    monthYearLabel: 'MMMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
}

export const DATE_PICKER_MODULE_FORROOT_GUARD = new InjectionToken<void>(
  'DATE_PICKER_MODULE_FORROOT_GUARD'
)

export function provideForRootGuardDatePicker(datePickerModule: DatePickerModule): string {
  if (datePickerModule) {
    throw Error('DatePickerModule is already loaded. Import it in the AppModule only')
  }

  return 'guarded'
}

@NgModule({
  declarations: [DatePickerComponent],
  imports: [
    CommonModule,
    MatDatepickerModule,
    MatNativeDateModule,
    InputModule,
    IconModule.forChild({ icons: [calendar, error, success, warning] }),
    SharedModule,
    ReactiveFormsModule,
    MatInputModule,
  ],
  exports: [DatePickerComponent],
})
export class DatePickerModule {
  static forRoot(
    dateFormat: MatDateFormats = DEFAULT_DATE_FORMAT,
    adapterClass = CustomDateAdapter
  ): ModuleWithProviders<DatePickerModule> {
    return {
      ngModule: DatePickerModule,
      providers: [
        {
          provide: DATE_PICKER_MODULE_FORROOT_GUARD,
          useFactory: provideForRootGuardDatePicker,
          deps: [[DatePickerModule, new Optional(), new SkipSelf()]],
        },
        {
          provide: DateAdapter,
          useClass: adapterClass,
          deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
        },
        {
          provide: MAT_DATE_LOCALE,
          useValue: 'ro',
        },
        {
          provide: MAT_DATE_FORMATS,
          useValue: dateFormat,
        },
      ],
    }
  }

  static forChild(): ModuleWithProviders<DatePickerModule> {
    return {
      ngModule: DatePickerModule,
    }
  }
}
