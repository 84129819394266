import { BehaviorSubject, combineLatest, NEVER, Observable, of } from 'rxjs'
import { distinctUntilChanged, filter, map, switchMap } from 'rxjs/operators'
import {
  findLastIndexFrom as _findLastIndexFrom,
  flow as _flow,
  isEmpty as _isEmpty,
  isEqual as _isEqual,
  isNil as _isNil,
  orderBy as _orderBy,
  uniqBy as _uniqBy,
} from 'lodash/fp'

import {
  ComponentIdentifierEnum,
  TemplateConfigInterface,
  TemplateConfigType,
} from '../dictionary/flx-template.dictionary'
import {
  activeProcessInstanceId$,
  ProcessInstance,
  processInstances$,
} from './process-instance.store'

export type TemplateConfigsStore = {
  [processInstanceUuid: string]: TemplateConfigInterface[]
}

export type FlattenedTemplateConfigsStore = {
  [processInstanceUuid: string]: TemplateConfigInterface[]
}

const templateConfigsStore = new BehaviorSubject<TemplateConfigsStore>({})
const flattenedTemplateConfigsStore = new BehaviorSubject<FlattenedTemplateConfigsStore>({})

let currentRootTemplateConfigs: TemplateConfigInterface[] | null = []

export const templateConfigs$ = templateConfigsStore.pipe(
  switchMap((templateConfigs) => (_isEmpty(templateConfigs) ? NEVER : of(templateConfigs)))
)

export const flattenedTemplateConfigs$ = flattenedTemplateConfigsStore.pipe(
  switchMap((flattenedTemplateConfigs) =>
    _isEmpty(flattenedTemplateConfigs) ? NEVER : of(flattenedTemplateConfigs)
  )
)

export function addTemplateConfig(
  processInstanceUuid: string,
  templateConfig: TemplateConfigInterface[]
): void {
  const decoratedTemplateConfigs = decorateWithProcessInstanceUuidAndRootTemplateId(
    templateConfig,
    processInstanceUuid
  )

  templateConfigsStore.next({
    ...templateConfigsStore.value,
    [processInstanceUuid]: decoratedTemplateConfigs,
  })

  flattenedTemplateConfigsStore.next({
    ...flattenedTemplateConfigsStore.value,
    [processInstanceUuid]: flattenAndOrderTemplateConfig(decoratedTemplateConfigs),
  })
}

// TODO: Check how we can stop this combineLatest from executing until templateConfigStore has the values
// TODO: Or if this makes sense instead of the filter operator which is currently implemented
export const screenTemplateConfig$ = combineLatest([
  templateConfigsStore,
  activeProcessInstanceId$,
  processInstances$,
  flattenedTemplateConfigs$,
]).pipe(
  filter(([templateConfigs, activeProcessInstanceId, processInstances, flattenedTemplateConfigs]) =>
    Boolean(
      templateConfigs[activeProcessInstanceId] &&
        processInstances[activeProcessInstanceId] &&
        flattenedTemplateConfigs[activeProcessInstanceId]
    )
  ),
  map(([templateConfigs, activeProcessInstanceId, processInstances, flattenedTemplateConfigs]) =>
    _flow(composeScreenTemplateConfig, wrapTemplateConfigArray)(
      activeProcessInstanceId,
      processInstances,
      templateConfigs,
      flattenedTemplateConfigs
    )
  ),
  distinctUntilChanged((prev, curr) => _isEqual(prev, curr))
)

export const rootTemplateConfigs$ = screenTemplateConfig$.pipe(
  map((rootTemplateConfig) => {
    return {
      ...rootTemplateConfig,
      templateConfig: rootTemplateConfig.templateConfig.filter(
        (tc) => tc.componentIdentifier !== ComponentIdentifierEnum.MODAL
      ),
    }
  }),
  distinctUntilChanged((prev, curr) => _isEqual(prev, curr))
)
export const modalTemplateConfigs$ = screenTemplateConfig$.pipe(
  map((rootTemplateConfig) => {
    return {
      ...rootTemplateConfig,
      templateConfig: rootTemplateConfig.templateConfig.filter(
        (tc) => tc.componentIdentifier === ComponentIdentifierEnum.MODAL
      ),
    }
  }),
  distinctUntilChanged((prev, curr) => _isEqual(prev, curr))
)

// PURE
export function composeScreenTemplateConfig(
  processInstanceUuid: string,
  processInstances: Record<number, ProcessInstance>,
  templateConfigs: TemplateConfigsStore,
  flattenedTemplateConfigs: FlattenedTemplateConfigsStore
): TemplateConfigInterface[] {
  // Because the currentNodeId can be deeply nested inside a templateConfig tree
  // we need to search for currentTemplate in flattenedTemplateConfigs and get its rootTemplateConfig
  // Find templateConfig with nodeDefinitionId matching the currentNodeId from WS update
  const parentProcessUuid = processInstances[processInstanceUuid].parentProcessUuid

  const currentTemplate = flattenedTemplateConfigs[processInstanceUuid].find(
    (tc) => tc.nodeDefinitionId === processInstances[processInstanceUuid].currentNodeId
  )
  // Because BE now returns all templateConfigs for a processInstance we need to filter by currentTemplate's  rootTemplateConfigId
  const currentRootTemplate = templateConfigs[processInstanceUuid].find(
    (tc) => tc.id === currentTemplate?.rootTemplateConfigId
  )

  /** Template sequence is used to keep template config rendering history */
  // When refreshing the page (status endpoint) use BE templateSequence
  // Else (start endpoint) build templateSequence from global var currentRootTemplateConfigs

  const templateSequence =
    processInstances[processInstanceUuid].templatesSequence ||
    currentRootTemplateConfigs.map((template) => template?.id)

  const screenTemplateConfigs: TemplateConfigInterface[] = getTemplateConfigsByTemplateSequence(
    [...templateConfigs[processInstanceUuid], ...(templateConfigs[parentProcessUuid] || [])],
    [...templateSequence, currentRootTemplate?.id]
  )

  // Update global currentRootTemplateConfigs var
  currentRootTemplateConfigs = [...screenTemplateConfigs]

  return screenTemplateConfigs
}

// get templateConfigs up until the first container element(PAGE OR STEPPER)
export function getTemplateConfigsByTemplateSequence(
  templateConfigs: TemplateConfigInterface[],
  templatesSequence: number[]
): TemplateConfigInterface[] {
  let templateConfigsFromTemplateSequence: TemplateConfigInterface[] = []
  const templateConfigObjects = templateConfigs.reduce((acc, tc) => {
    return { ...acc, [tc.id]: tc }
  }, {})

  if (templatesSequence?.length) {
    for (let i = templatesSequence.length - 1; i >= 0; i--) {
      const sequenceId = templatesSequence[i]

      if (templateConfigObjects[sequenceId]) {
        templateConfigsFromTemplateSequence = [
          ...templateConfigsFromTemplateSequence,
          templateConfigObjects[sequenceId],
        ]
        // Break if componentIdentifier is PAGE or root STEPPER ( ex STEPPER IN STEPPER LOGIC)
        if (
          templateConfigObjects[sequenceId].componentIdentifier === ComponentIdentifierEnum.PAGE ||
          (templateConfigObjects[sequenceId].componentIdentifier ===
            ComponentIdentifierEnum.STEPPER &&
            templateConfigObjects[sequenceId].id ===
              templateConfigObjects[sequenceId].rootTemplateConfigId)
        ) {
          break
        }
      }
    }
  }

  return _uniqBy('id', templateConfigsFromTemplateSequence).filter((tc) => Boolean(tc))
}

// PURE
export function wrapTemplateConfigArray(templateConfig: TemplateConfigInterface[]): {
  templateConfig: TemplateConfigInterface[]
} {
  return { templateConfig }
}

// PURE
export function flattenAndOrderTemplateConfig(arr: TemplateConfigInterface[]): any[] {
  return arr.reduce((acc, item) => {
    acc = [...acc, item]
    if (item.templateConfig) {
      acc = [
        ...acc,
        ...flattenAndOrderTemplateConfig(_orderBy('order', 'asc', item.templateConfig)),
      ]
    }
    return acc
  }, [])
}

// ! This is a good example for why Purescript is a powerfull language
// ! A templateConfig is a specific case of a tree structure that uses objects and arrays
// ! As a functional concept, I should be able to declare a type and create a Foldable
// ! type class instance for it, instructing it how to map over this structure
// export function mapTemplateConfig<A>(templateConfig: TemplateConfigInterface[], fn: (templateConfig: TemplateConfigInterface) => )

// TODO Fix template config having itself as rootTemplateConfigId (probably same for parentTemplateConfigId)
export function decorateWithProcessInstanceUuidAndRootTemplateId(
  templateConfig: TemplateConfigInterface[],
  processInstanceUuid: string,
  rootTemplateConfigId?: number,
  parentTemplateConfigId?: number
): TemplateConfigInterface[] {
  return templateConfig.map((templateConfigElm) => ({
    ...templateConfigElm,
    processInstanceUuid,
    rootTemplateConfigId: rootTemplateConfigId ? rootTemplateConfigId : templateConfigElm.id,
    parentTemplateConfigId,

    templateConfig: decorateWithProcessInstanceUuidAndRootTemplateId(
      templateConfigElm.templateConfig,
      processInstanceUuid,
      rootTemplateConfigId ? rootTemplateConfigId : templateConfigElm.id,
      templateConfigElm.id
    ),

    formFields: templateConfigElm.formFields.map((formField) => ({
      ...formField,
      processInstanceUuid,
    })),
  }))
}

// TODO This is a good place for memoization
export function isFormGroupDisabled$(
  processInstanceUuid: string,
  formGroup: TemplateConfigInterface
): Observable<boolean> {
  return combineLatest([flattenedTemplateConfigsStore, processInstances$]).pipe(
    filter(([_flattenedTemplateConfigsStore, processInstances]) =>
      Boolean(processInstances[processInstanceUuid])
    ),
    map(([flattenedTemplateConfigs, processInstances]) =>
      isFormGroupDisabled(
        processInstances[processInstanceUuid].currentNodeId,
        formGroup,
        flattenedTemplateConfigs[processInstanceUuid]
      )
    ),
    distinctUntilChanged((prev, curr) => _isEqual(prev, curr))
  )
}

// PURE
// TODO This is a good place for memoization
export function isFormGroupDisabled(
  currentNodeId: number,
  formGroup: TemplateConfigInterface,
  flattenedTemplateConfigs: TemplateConfigInterface[]
): boolean {
  const formGroupIndex = flattenedTemplateConfigs.findIndex(
    (templateElement) => templateElement.nodeDefinitionId === formGroup.nodeDefinitionId
  )

  const currentFormGroupIndex = flattenedTemplateConfigs.findIndex(
    (templateElement) => templateElement.nodeDefinitionId === currentNodeId
  )

  const cantGoBackIndex = _findLastIndexFrom(
    (tc: TemplateConfigInterface) => !_isNil(tc.canGoBack) && !tc.canGoBack,
    currentFormGroupIndex - 1,
    flattenedTemplateConfigs
  )

  const isBehindCantGoBack = cantGoBackIndex > 0 ? formGroupIndex <= cantGoBackIndex : false
  const isFutureCard = formGroupIndex > currentFormGroupIndex

  return currentFormGroupIndex > 0 ? isFutureCard || isBehindCantGoBack : true
}

// PURE

// By interactive we mean those template elements that are the root of the
// USER TASKs
export function userTasksNodeDefinitionIds(
  flattenedTemplateConfigs: TemplateConfigInterface[]
): number[] {
  return flattenedTemplateConfigs
    .filter(
      (templateElement) =>
        templateElement.componentIdentifier === ComponentIdentifierEnum.FORM_GROUP ||
        templateElement.type === TemplateConfigType.CUSTOM
    )
    .map((formGroup) => formGroup.nodeDefinitionId)
}

export function getTemplateConfigById(
  processInstanceUuid: number
): Observable<TemplateConfigInterface[]> {
  return templateConfigsStore.pipe(map((value) => value[processInstanceUuid]))
}

export function discardTemplateConfigById(processInstanceUuid: string): void {
  const { [processInstanceUuid]: discardedTemplateConfig, ...state } = templateConfigsStore.value
  templateConfigsStore.next({ ...state })
}

export function templateConfigsByParentTemplateId$(
  uuid,
  parentTemplateId
): Observable<TemplateConfigInterface[]> {
  return flattenedTemplateConfigs$.pipe(
    map((flattenedTemplateConfigs) => {
      return flattenedTemplateConfigs[uuid].filter(
        (tc) => tc.parentTemplateConfigId === parentTemplateId
      )
    })
  )
}

// TODO Figure out if this should return an observable instead
export function templateConfigsByRootTemplateId(uuid, rootTemplateId): TemplateConfigInterface[] {
  return flattenedTemplateConfigsStore
    .getValue()
    [uuid].filter((tc) => tc.rootTemplateConfigId === rootTemplateId)
}

export function reset(): void {
  flattenedTemplateConfigsStore.next({})
  templateConfigsStore.next({})
  currentRootTemplateConfigs = []
}
