import { Injectable } from '@angular/core'
import { FormControl, FormGroup } from '@angular/forms'
import { BehaviorSubject, Observable } from 'rxjs'
import { map, take, tap } from 'rxjs/operators'
import { set as _set } from 'lodash/fp'

import {
  FieldConfigInterface,
  TemplateConfigInterface,
} from '../dictionary/flx-template.dictionary'
import { DEFAULT_INITIAL_FORM_VALUES } from '../dictionary/flx.constants'
import { buildAsyncValidators, buildFieldValidators } from '../flx.utils'
import { FlxValidatorResolver } from './flx-validator-resolver.service'
import { processDataValueFromPath$, updateProcessData } from '../store/process-data.store'

@Injectable({ providedIn: 'root' })
export class FlxFormService {
  public processForm = new FormGroup({})
  private formFields: FieldConfigInterface[] = []
  public submittedForms$ = new BehaviorSubject<any>({})

  constructor(private validatorResolver: FlxValidatorResolver) {}

  buildForm(templateConfig: TemplateConfigInterface): void {
    this.buildFormFields(templateConfig, this.formFields)
    /*Add a new nested formGroup corresponding to each FORM_GROUP key */
    this.processForm.addControl(`${templateConfig.key}`, new FormGroup({}))

    this.formFields.forEach((formField) => {
      processDataValueFromPath$(formField.processInstanceUuid, formField.key)
        .pipe(
          take(1),
          tap((processModel) => {
            /*Add the formFields as FormControls to the corresponding FormGroup  */
            const childForm = this.processForm.get(`${templateConfig.key}`) as FormGroup

            const formControlValue =
              processModel ||
              formField.dataSource?.defaultValue ||
              DEFAULT_INITIAL_FORM_VALUES[formField.displayOptions?.flowxProps?.inputType]

            const formControl = new FormControl(formControlValue, {
              validators: buildFieldValidators(formField.validators, this.validatorResolver),
              asyncValidators: buildAsyncValidators(
                formField.processInstanceUuid,
                formField.validators,
                this.validatorResolver
              ),
            })

            childForm.addControl(formField.key, formControl)
            // const storeValue = _set(formField.key, formControlValue, {})

            // update store with calculated default values
            // updateProcessData(formField.processInstanceUuid, storeValue)
          })
        )
        .subscribe()
    })

    // Reset formFields
    this.formFields = []
    // SUBMIT BUTTONS ARE DISABLED WHILE FORMS ARE PRISTINE,SO WE NEED TO FORCE FORMS TO BE DIRTY
    this.processForm.get(`${templateConfig.key}`).markAsDirty({ onlySelf: true })
  }

  getFormGroup(formKeyPath: string | string[]): FormGroup {
    // TODO: Delete this (formKeyPath is always an array)
    const formKeyPathArray = Array.isArray(formKeyPath)
      ? formKeyPath.map((id) => `${id}`)
      : [`${formKeyPath}`]

    return this.processForm.get(formKeyPathArray) as FormGroup
  }

  buildFormFields(templateConfig: TemplateConfigInterface, fields: FieldConfigInterface[]): void {
    if (templateConfig.componentIdentifier !== 'FORM') {
      /* Keep iterating if componentIdentifier is different from FORM */
      templateConfig.templateConfig.forEach((tc: TemplateConfigInterface) => {
        return this.buildFormFields(tc, fields)
      })
    }

    if (templateConfig.componentIdentifier === 'FORM') {
      /* Some formFields like BUTTON, don't have a valid key prop*/
      const fieldsWithKey = templateConfig.formFields.filter((ff) => ff.key)
      this.formFields = [...this.formFields, ...fieldsWithKey]
    }
  }

  resetForm(): void {
    this.processForm = new FormGroup({})
    this.submittedForms$.next({})
  }

  formSubmitted$(formId): Observable<boolean> {
    return this.submittedForms$.pipe(
      map((forms) => {
        return !!forms[formId]
      })
    )
  }

  checkFormValidity(formId): boolean {
    return this.processForm.get(`${formId}`).valid
  }
}
