import {
  Component,
  OnInit,
  ViewChild,
  EventEmitter,
  Output,
  Input,
  forwardRef,
  ElementRef,
  QueryList,
  ViewChildren,
  AfterViewInit,
  HostBinding,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  OnChanges,
  OnDestroy,
} from '@angular/core'
import { OverlayRef, Overlay, OverlayConfig } from '@angular/cdk/overlay'
import { ActiveDescendantKeyManager } from '@angular/cdk/a11y'
import { CdkPortal } from '@angular/cdk/portal'
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms'
import {
  Item,
  VisualType,
  SourceType,
  DEFAULT_TYPE,
  DEFAULT_SOURCE,
  SelectType,
  DEFAULT_SELECT_TYPE,
} from './select.dictionary'
import { SelectItemComponent } from './select-item.component'
import { ENTER, DOWN_ARROW, UP_ARROW, ESCAPE } from '@angular/cdk/keycodes'
import { INPUT_TYPES, FIELD_BORDER, StatusType } from '../shared/shared.dictionary'
import { Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators'
import { FixedSizeVirtualScrollStrategy, VIRTUAL_SCROLL_STRATEGY } from '@angular/cdk/scrolling'

export class CustomVirtualScrollStrategy extends FixedSizeVirtualScrollStrategy {
  constructor() {
    super(50, 250, 500)
  }
}

@Component({
  selector: 'ppf-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectComponent),
      multi: true,
    },
    { provide: VIRTUAL_SCROLL_STRATEGY, useClass: CustomVirtualScrollStrategy },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectComponent
  implements OnInit, AfterViewInit, ControlValueAccessor, OnChanges, OnDestroy {
  public selectedItem: Item<any>
  private unsubscribe$: Subject<void> = new Subject<void>()

  @HostBinding('class')
  get classes() {
    const hostClasses = [
      this.class,
      this.disabled ? 'ppf-disabled-state' : '',
      this.selectType,
      INPUT_TYPES[this.type],
      this.status ? FIELD_BORDER[this.status] : '',
    ]
    return hostClasses.join(' ')
  }

  @Input() isOpen = false
  @Input() overlayClass: string
  @Input() selectType: SelectType = DEFAULT_SELECT_TYPE
  @Input() options: Item<any>[] = []
  @Input() label: string
  @Input() placeholder: string
  @Input() type: VisualType = DEFAULT_TYPE
  @Input() searchSource?: SourceType = DEFAULT_SOURCE
  @Input() status: StatusType
  @Input() disabled: boolean
  @Input() class = ''
  @Input() showClear = false
  @Output() selectChange = new EventEmitter()
  @Output() valueChange = new EventEmitter()
  @Output() showChange = new EventEmitter()

  @ViewChildren(SelectItemComponent) items: QueryList<SelectItemComponent>
  private keyManager: ActiveDescendantKeyManager<SelectItemComponent>

  @ViewChild('portal', { read: CdkPortal })
  portal: CdkPortal

  @ViewChild('selectRef')
  selectRef: ElementRef

  @ViewChild('inputRef')
  inputRef: ElementRef

  protected overlayRef: OverlayRef
  showing = false
  filteredOptions: Item<any>[] = []
  searchControl: FormControl

  DEFAULT_SELECT_TYPE = DEFAULT_SELECT_TYPE

  constructor(protected overlay: Overlay, protected cdRef: ChangeDetectorRef) {}

  private arrayEquals(a, b) {
    return (
      Array.isArray(a) &&
      Array.isArray(b) &&
      a.length === b.length &&
      [...a.filter((item) => !b.includes(item)), ...b.filter((item) => !a.includes(item))]
        .length === 0
    )
  }

  ngOnInit(): void {
    if (this.selectType !== DEFAULT_SELECT_TYPE) {
      this.searchControl = new FormControl('')
      // TODO investigate why setTimeout is necessary for IE to properly set dropdown value.
      setTimeout(() => {
        this.searchControl.valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe((term) => {
          if (!this.showing && !this.selectedItem && Boolean(term)) {
            this.show()
          }
          if (this.searchSource === DEFAULT_SOURCE) {
            this.search(term)
          } else {
            this.valueChange.emit(term)
          }
          if (this.selectedItem && term !== this.selectedItem.label) {
            this.selectedItem = null
          }
          this.onChange('')
        })
      })
    }
  }

  // reset filteredOptions when options input changes
  ngOnChanges(changes) {
    if (
      changes.options &&
      !this.arrayEquals(changes.options.currentValue, changes.options.previousValue)
    ) {
      this.filteredOptions = this.options ? [...this.options] : []
    }
  }

  ngAfterViewInit() {
    if (this.isOpen) {
      this.show()
    }
    this.keyManager = new ActiveDescendantKeyManager(this.items).withTypeAhead()
  }

  get selectedOption() {
    return this.selectedItem ? this.selectedItem : { value: null, label: this.placeholder }
  }

  onSelectClick() {
    if (!this.disabled && !this.showing) {
      this.inputRef.nativeElement.focus()
      this.show()
    }
  }

  select(option) {
    this.selectedItem = option
    if (this.selectType !== DEFAULT_SELECT_TYPE) {
      this.searchControl.setValue(this.selectedItem.label, { emitEvent: false, onlySelf: true })
    }
    this.selectChange.emit(option)
    this.valueChange.emit(option)
    this.onChange(option)
    this.keyManager.setActiveItem(option)
    this.hide()
  }

  private onChange(value: any) {
    return value
  }

  private onTouch() {}

  registerOnChange(fn: any) {
    this.onChange = fn
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn
  }

  writeValue(value: any): void {
    if (!value) {
      this.resetSelectValue()
    } else {
      this.selectedItem = value

      if (this.selectType !== DEFAULT_SELECT_TYPE) {
        this.searchControl.setValue(this.selectedItem.label, { emitEvent: false, onlySelf: true })
      }
      this.onChange(this.selectedItem)
    }
    setTimeout(() => {
      this.cdRef.markForCheck()
    })
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled
    this.cdRef.markForCheck()
  }

  search(query: string) {
    this.filteredOptions =
      this.options &&
      this.options.filter((option) => {
        return option.label.toLocaleLowerCase().includes(query.toLocaleLowerCase())
      })
  }

  show() {
    this.showing = true
    this.overlayRef = this.overlay.create(this.getOverlayConfig())
    this.overlayRef.attach(this.portal)
    const backdropClickSub = this.overlayRef.backdropClick().subscribe(() => {
      this.hide()
      backdropClickSub.unsubscribe()
    })
    this.showChange.emit(this.showing)
  }

  hide() {
    this.overlayRef.detach()
    this.showing = false
    this.showChange.emit(this.showing)
  }

  getOverlayConfig(): OverlayConfig {
    const positionStrategy = this.overlay
      .position()
      .flexibleConnectedTo(this.selectRef)
      .withPush(false)
      .withPositions([
        {
          originX: 'start',
          originY: 'bottom',
          overlayX: 'start',
          overlayY: 'top',
        },
        {
          originX: 'start',
          originY: 'top',
          overlayX: 'start',
          overlayY: 'bottom',
        },
      ])

    const scrollStrategy = this.overlay.scrollStrategies.reposition()

    return new OverlayConfig({
      positionStrategy,
      scrollStrategy,
      hasBackdrop: true,
      backdropClass: 'cdk-overlay-transparent-backdrop',
      width: this.selectRef.nativeElement.offsetWidth,
    })
  }

  onKeyDown(event) {
    if (!this.disabled) {
      if (event.keyCode === ENTER && !this.showing) {
        this.show()
      }
      if (event.keyCode === ENTER && this.showing && this.keyManager.activeItem) {
        this.select(this.keyManager.activeItem.item)
      }
      if (event.keyCode === ESCAPE && this.showing) {
        this.hide()
      }
      if (event.keyCode === DOWN_ARROW && !this.showing) {
        this.show()
      } else {
        this.keyManager.onKeydown(event)
        const activeItem = document.getElementsByClassName('active')[0]
        if (event.keyCode === DOWN_ARROW && activeItem) {
          activeItem.scrollIntoView(true)
        }
        if (event.keyCode === UP_ARROW && activeItem) {
          activeItem.scrollIntoView(false)
        }
      }
    }
  }

  clearSelect(event: Event) {
    if (!this.disabled) {
      event.stopPropagation()
      // otherwise the blur event won't be triggered
      this.inputRef.nativeElement.focus()
      this.resetSelectValue()
      this.inputRef.nativeElement.blur()
    }
  }

  private resetSelectValue() {
    this.selectedItem = null

    if (this.selectType !== DEFAULT_SELECT_TYPE) {
      this.searchControl.setValue(null, { emitEvent: false, onlySelf: true })
      this.filteredOptions = this.options
    }
  }

  trackByFn(index, item): number {
    return item?.id || index
  }

  ngOnDestroy() {
    this.unsubscribe$.next()
    this.unsubscribe$.complete()
  }
}
