import { Component } from '@angular/core'
import { TemplateConfigInterface } from '../../../dictionary/flx-template.dictionary'
import { FlxWrapperComponent } from '../../../flx-wrapper'

@Component({
  selector: 'flx-hint-wrapper',
  templateUrl: './hint-wrapper.component.html',
  styleUrls: ['./hint-wrapper.component.scss'],
})
export class HintWrapperComponent extends FlxWrapperComponent<TemplateConfigInterface> {}
