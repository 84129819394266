/*
 * Public API Surface of flowx-process-renderer
 */

export * from './lib/services/flx-process.service'
export * from './lib/flx-process.module'
export { FlxWrapperComponent } from './lib/flx-wrapper'
export * from './lib/flx-localization.module'

export {
  NomenclatorServiceInterface,
  NomenclatorValue,
  ClientStoreInterface,
} from './lib/dictionary/flx-nomenclator.dictionary'
