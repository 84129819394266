import { Injectable, Inject } from '@angular/core'
import { PpfIcon } from '../ppfIcons'
import { IconRegistryConfig, RegistryConfig } from '../model'

@Injectable()
export class IconRegistryService {
  private icons: Map<string, string> = new Map<string, string>()

  registerIcons(icons: PpfIcon[]): void {
    icons.forEach(({ name, data }) => this.icons.set(name, data))
  }

  getIcon(iconName: string): string {
    if (!this.icons.has(iconName)) {
      throw Error(`Can't find the ${iconName} icon, make sure you registered it!`)
    }

    return this.icons.get(iconName)
  }
}
