import { Component, Input } from '@angular/core'
import { Observable } from 'rxjs'

@Component({
  selector: 'flx-modal',
  templateUrl: './flx-generic-custom.component.html',
  styleUrls: ['./flx-generic-custom.component.scss'],
})
export class FlxGenericCustomComponent {
  @Input() data$: Observable<any>
}
