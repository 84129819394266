import {
  Component,
  Input,
  HostBinding,
  ChangeDetectionStrategy,
  EventEmitter,
  Output,
} from '@angular/core'
import { CHIP_CONSTANTS, IconPosition, RoundType, ChipType } from './chip.dictionary'

@Component({
  selector: 'ppf-chip',
  templateUrl: './chip.component.html',
  styleUrls: ['./chip.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChipComponent {
  @Input() type: ChipType = CHIP_CONSTANTS.DEFAULT_CHIP_TYPE as ChipType
  @Input() roundLevel: RoundType = CHIP_CONSTANTS.DEFAULT_ROUND_TYPE as RoundType
  @Input() iconPosition: IconPosition = CHIP_CONSTANTS.DEFAULT_ICON_POSITION as IconPosition
  @Input() content: string
  @Input() image: string | null

  @HostBinding('class')
  get classes() {
    const type = this.getTypeClass()
    const hasImage = !this.image ? 'no-image' : 'with-image'
    const iconPositionClass = this.iconPosition ? `ppf-icon-pos-${this.iconPosition}` : ''

    return `ppf-round-${this.roundLevel} ${iconPositionClass} ppf-${hasImage} ${type}`
  }

  getTypeClass() {
    const typesToClassesMap = {
      flat: 'type-flat',
      fill: 'type-fill',
      border: 'type-border',
      fillAndBorder: 'type-fill-and-border',
      fillWithRedIcon: 'type-fill-with-red-icon',
    }

    return typesToClassesMap[this.type]
  }
}
