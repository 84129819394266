import { Component, Input, ContentChild, HostBinding, ChangeDetectionStrategy } from '@angular/core'
import { InputRefDirective } from '../shared/input.directive'
import { DEFAULT_TEXTAREA_TYPE } from './textarea.dictionary'
import { FIELD_BORDER, INPUT_TYPES, StatusType, FieldType } from '../shared/shared.dictionary'

@Component({
  selector: 'ppf-textarea',
  templateUrl: './textarea.component.html',
  styleUrls: ['./textarea.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TextareaComponent {
  private _type: FieldType = DEFAULT_TEXTAREA_TYPE
  @Input() label: string

  @Input() set type(type: FieldType) {
    this._type = type
  }

  get type(): FieldType {
    return this._type
  }

  @Input() status: StatusType
  @ContentChild(InputRefDirective) textarea: InputRefDirective = null

  @HostBinding('class.disabled-state') get isDisabled() {
    return this.textarea ? this.textarea.isDisabled : false
  }

  @HostBinding('class.focus-state')
  get isFocus() {
    return this.textarea ? this.textarea.focus : false
  }

  @HostBinding('class.float-label')
  get floatLabel() {
    if (this.textarea) {
      return !this.textarea._hasValue && !this.textarea.focus
    }
  }

  getTypeClass() {
    return `${INPUT_TYPES[this.type]} ${this.status ? FIELD_BORDER[this.status] : ''} ${
      this.label ? 'has-label' : ''
    }`
  }
}
