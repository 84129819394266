import { Component, Input } from '@angular/core'

@Component({
  selector: 'ppf-tooltip',
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.scss'],
})
export class TooltipComponent {
  @Input() text = ''
  @Input() classes: string
}
