import { ChangeDetectionStrategy, Component, Input, HostBinding, OnChanges } from '@angular/core'
import { HINT_CONSTANTS, HintType } from './hint.dictionary'

@Component({
  selector: 'ppf-hint',
  templateUrl: './hint.component.html',
  styleUrls: ['./hint.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HintComponent implements OnChanges {
  @Input() type: HintType = HINT_CONSTANTS.DEFAULT_HINT_TYPE as HintType
  @Input() message: string = null
  iconName: string

  @HostBinding('class')
  get classes() {
    return `ppf-hint-${this.type}`
  }

  ngOnChanges() {
    this.iconName = this.getIconName(this.type)
  }

  getIconName(key: HintType): string {
    const iconNames = {
      warning: 'warning',
      error: 'error',
      success: 'success',
      info: 'info',
    }

    return iconNames[key]
  }
}
