import { Component, HostBinding, Input, OnInit } from '@angular/core'
import { Observable } from 'rxjs'
import { TemplateConfigInterface } from '../../dictionary/flx-template.dictionary'
import { isHidden$ } from '../../store/process-data.store'
import { extractPlaceholders } from '../../flx.utils'

@Component({
  selector: 'flx-flx-container',
  templateUrl: './flx-container.component.html',
  styleUrls: ['./flx-container.component.scss'],
})
export class FlxContainerComponent implements OnInit {
  @HostBinding('class')
  classes = ''

  public isHidden$: Observable<boolean>
  private _config: {
    templateConfig: TemplateConfigInterface
    formGroupId: any
    form: any
  }
  private hiddenDeps

  @Input()
  set config(config: { templateConfig: TemplateConfigInterface; formGroupId: any; form: any }) {
    this._config = config
    this.classes = this._config?.templateConfig.displayOptions?.class || ''
    this.hiddenDeps = extractPlaceholders(this._config.templateConfig?.expressions?.hide)
    this.isHidden$ = isHidden$(this.hiddenDeps, this._config.templateConfig)
  }

  get templateConfig(): TemplateConfigInterface {
    return this._config.templateConfig
  }

  get form(): any {
    return this._config.form
  }

  get formGroupId(): any {
    return this._config.formGroupId
  }

  constructor() {}

  ngOnInit(): void {}
}
