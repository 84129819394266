import { Injectable } from '@angular/core'
import { AsyncValidatorFn, ValidatorFn, Validators } from '@angular/forms'
import {
  AsyncValidatorFnFactory,
  ValidatorFnFactory,
  ValidatorsRegistry,
} from '../dictionary/flx.dictionary'

@Injectable({
  providedIn: 'root',
})
export class FlxValidatorResolver {
  private validatorsRegistry: ValidatorsRegistry = {}

  addValidatorsToRegistry(validators: ValidatorsRegistry): void {
    this.validatorsRegistry = { ...this.validatorsRegistry, ...validators }
  }

  resolveValidator(validatorName: string): ValidatorFn | ValidatorFnFactory {
    if (!this.validatorsRegistry[validatorName] && !Validators[validatorName]) {
      throw new Error(`No validator registered with the name: ${validatorName}`)
    }

    return this.validatorsRegistry[validatorName] ?? Validators[validatorName]
  }

  resolveAsyncValidator(validatorName: string): AsyncValidatorFn | AsyncValidatorFnFactory {
    if (!this.validatorsRegistry[validatorName]) {
      throw new Error(`No async validator registered with the name: ${validatorName}`)
    }
    return this.validatorsRegistry[validatorName] as AsyncValidatorFn | AsyncValidatorFnFactory
  }
}
