import { Component, Input, OnChanges, HostBinding, OnInit } from '@angular/core'
import {
  spinnerType,
  spinnerColor,
  spinnerSize,
  spinnerVisualType,
  DEFAULT_TYPE,
  DEFAULT_VISUAL_TYPE,
  DEFAULT_SIZE,
  DEFAULT_COLOR,
  SIZE,
} from './spinner.dictionary'

@Component({
  selector: 'ppf-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss'],
})
export class SpinnerComponent implements OnInit, OnChanges {
  @Input() type: spinnerType = DEFAULT_TYPE
  @Input() visualType: spinnerVisualType = DEFAULT_VISUAL_TYPE
  @Input() color: spinnerColor = DEFAULT_COLOR
  @Input() size: spinnerSize = DEFAULT_SIZE
  @Input() progress: number
  @Input() radius: number = SIZE[DEFAULT_SIZE].radius
  @Input() border: number = SIZE[DEFAULT_SIZE].border
  @Input() text: string = null
  @Input() backgroundColor: string
  @Input() borderColor: string

  widthSpinner: number
  heightSpinner: number

  @HostBinding('class')
  get classes() {
    return `ppf-spinner-${this.color} ppf-spinner-${this.visualType}`
  }

  ngOnInit() {
    this.calculateWidthHeight()
  }

  ngOnChanges(): void {
    if (SIZE[this.size]) {
      this.border = SIZE[this.size].border
      this.radius = SIZE[this.size].radius
    }

    if (this.type !== DEFAULT_TYPE) {
      this.setProgress()
    }

    this.calculateWidthHeight()
  }

  setProgress() {
    const progressCircle = document.getElementsByClassName('ppf-progress-circle')[0] as HTMLElement
    const circumference = this.radius * 2 * Math.PI
    const offset = circumference - (this.progress / 100) * circumference
    progressCircle.style.strokeDasharray = `${circumference} ${circumference}`
    progressCircle.style.strokeDashoffset = `${circumference}`
    progressCircle.style.strokeDashoffset = `${offset}`
  }

  calculateWidthHeight(): void {
    this.widthSpinner = this.radius * 2 + this.border * 2
    this.heightSpinner = this.radius * 2 + this.border * 2
  }
}
