import { ChangeDetectorRef, Component, DoCheck } from '@angular/core'
import { FlxWrapperComponent } from '../../../flx-wrapper'

@Component({
  selector: 'flx-input-mask-wrapper',
  templateUrl: './input-mask-wrapper.component.html',
  styleUrls: ['./input-mask-wrapper.component.scss'],
})
export class InputMaskWrapperComponent extends FlxWrapperComponent implements DoCheck {
  constructor(private cdr: ChangeDetectorRef) {
    super()
  }

  ngDoCheck(): void {
    // TODO Find a better way to do this.
    this.cdr.detectChanges()
  }

  get inputType(): string {
    return this.config.displayOptions?.flowxProps?.inputType || 'text'
  }
}
