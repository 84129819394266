import { Component, Input, HostBinding, ChangeDetectionStrategy } from '@angular/core'
import {
  DEFAULT_DISPLAY_TYPE,
  BadgeType,
  DisplayType,
  DEFAULT_MESSAGE_TYPE,
  BADGE_TYPES,
} from './badge.dictionary'

@Component({
  selector: 'ppf-badge',
  templateUrl: './badge.component.html',
  styleUrls: ['./badge.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BadgeComponent {
  @Input() text: string
  @Input() iconName: string
  @Input() messageType: BadgeType = DEFAULT_MESSAGE_TYPE
  @Input() displayType: DisplayType = DEFAULT_DISPLAY_TYPE

  @HostBinding('class.ppf-icon-only')
  get isIconOnly() {
    return !this.text
  }

  @HostBinding('class.ppf-badge-success')
  get isSuccess() {
    return this.messageType === BADGE_TYPES.SUCCESS
  }

  @HostBinding('class.ppf-badge-error')
  get isError() {
    return this.messageType === BADGE_TYPES.ERROR
  }

  @HostBinding('class.ppf-badge-warning')
  get isWarning() {
    return this.messageType === BADGE_TYPES.WARNING
  }

  @HostBinding('class.ppf-badge-info')
  get isInfo() {
    return this.messageType === BADGE_TYPES.INFO
  }

  @HostBinding('class.ppf-badge-fill')
  get isFill() {
    return this.displayType === BADGE_TYPES.FILL
  }

  @HostBinding('class.ppf-badge-border')
  get isBorder() {
    return this.displayType === BADGE_TYPES.BORDER
  }
}
