import {
  ComponentRef,
  Directive,
  OnDestroy,
  ViewContainerRef,
  Input,
  OnInit,
  OnChanges,
  SimpleChanges,
} from '@angular/core'

import { FormControl } from '@angular/forms'

import { FieldConfigInterface } from '../dictionary/flx-template.dictionary'
import { FlxComponent } from '../flx-component.model'
import { FlxComponentRendererService } from '../services/flx-component-renderer.service'
import { FlxProcessService } from '../services/flx-process.service'
import { tap } from 'rxjs/operators'
import { Subscription } from 'rxjs'

@Directive({
  selector: '[flxDynamicField]',
})
export class DynamicFieldDirective implements OnDestroy, OnInit, OnChanges {
  private _component: ComponentRef<FlxComponent>
  private _componentData
  private subscriptions: Subscription = new Subscription()

  @Input() fieldConfig: FieldConfigInterface
  @Input() control: FormControl
  @Input() formSubmitted: boolean

  constructor(
    protected flxProcessService: FlxProcessService,
    private componentRenderer: FlxComponentRendererService,
    protected viewContainerRef: ViewContainerRef
  ) {}

  public ngOnInit(): void {
    this._componentData = {
      ...this.fieldConfig,
      ...this.fieldConfig.displayOptions,
      control: this.control,
      status: this.getStatus(),
    }

    this.subscriptions.add(
      this.control?.valueChanges
        .pipe(
          tap((value) => {
            this._component.instance.config.status = this.getStatus()
          })
        )
        .subscribe()
    )

    this._component = this.componentRenderer.render(
      this.viewContainerRef,
      this.fieldConfig.componentIdentifier,
      this._componentData
    )
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (
      this._component?.instance?.config &&
      changes.formSubmitted.currentValue !== changes.formSubmitted.previousValue
    ) {
      this._component.instance.config.status = this.getStatus()
    }
  }

  getStatus(): string {
    return this.formSubmitted && this.control?.errors ? 'error' : ' '
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe()
    if (this.viewContainerRef) {
      this.viewContainerRef.remove()
      this.viewContainerRef = null
    }
  }
}
