import {
  Component,
  OnInit,
  Input,
  OnDestroy,
  ViewContainerRef,
  Injector,
  AfterViewInit,
  QueryList,
  ViewChildren,
} from '@angular/core'

import { Observable } from 'rxjs'
import { map, tap } from 'rxjs/operators'

import { FlxProcessService } from '../../services/flx-process.service'
import { FlxComponentRendererService } from '../../services/flx-component-renderer.service'

import * as TemplateConfigStore from '../../store/template-config.store'
import * as ProcessNotificationsStore from '../../store/process-notifications.store'
import { uiStore$ } from '../../store/ui.store'

@Component({
  selector: 'flx-process-renderer',
  templateUrl: './flx-process-renderer.component.html',
  styleUrls: ['./flx-process-renderer.component.scss'],
})
export class FlxProcessRendererComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChildren('notificationViewport', { read: ViewContainerRef })
  private notificationViewportRefs!: QueryList<ViewContainerRef>
  public _notificationViewportRefs
  public notifications

  @Input() apiUrl: string
  @Input() processApiPath: string
  @Input() processName: string
  @Input() processStartData: any
  @Input() debugLogs: boolean
  @Input() isDraft = false
  @Input() keepState = false
  @Input() debugCustomComponents = false
  @Input() language: string = 'ro-RO'

  rootTemplateConfig$: Observable<any>
  modalTemplateConfig$: Observable<any>
  notifications$: Observable<any>
  showLoader$ = uiStore$.pipe(map((uiStore) => uiStore.showLoader))

  constructor(private flxProcess: FlxProcessService, private injector: Injector) {}

  ngOnInit(): void {
    this.flxProcess.init({
      apiUrl: this.apiUrl,
      processApiPath: this.processApiPath,
      processName: this.processName,
      processStartData: this.processStartData,
      debugLogs: this.debugLogs,
      isDraft: this.isDraft,
      keepState: this.keepState,
      debugCustomComponents: this.debugCustomComponents,
      language: this.language,
    })
    this.notifications$ = ProcessNotificationsStore.processNotificationsByActiveProcess$().pipe(
      tap((notifications) => {
        this.notifications = notifications
      })
    )

    this.rootTemplateConfig$ = TemplateConfigStore.rootTemplateConfigs$

    this.modalTemplateConfig$ = TemplateConfigStore.modalTemplateConfigs$
  }

  ngAfterViewInit(): void {
    this.notificationViewportRefs.changes.subscribe(() => {
      this._notificationViewportRefs = this.notificationViewportRefs.toArray()
      this.renderNotifications(this.notifications)
    })
  }

  renderNotifications(notifications): void {
    setTimeout(() => {
      const componentRenderer = this.injector.get(FlxComponentRendererService)
      notifications.forEach((notification, index) => {
        const notificationComponent = componentRenderer.render(
          this._notificationViewportRefs[index],
          notification.componentIdentifier
        )
        if (notificationComponent) {
          notificationComponent.instance.config = notification
        }
      })
    })
  }

  ngOnDestroy(): void {
    if (!this.keepState) {
      this.flxProcess.reset()
    }
  }
}
