import {
  ModuleWithProviders,
  NgModule,
  Optional,
  SkipSelf,
  InjectionToken,
  Inject,
} from '@angular/core'
import { CommonModule } from '@angular/common'

import { IconComponent } from './component/icon.component'
import { RegistryConfig, IconRegistryConfig } from './model'
import { IconRegistryService } from './service/icon-registry.service'

@NgModule({
  declarations: [IconComponent],
  imports: [CommonModule],
  exports: [IconComponent],
})
export class IconModule {
  static forRoot(config?: IconRegistryConfig): ModuleWithProviders<IconModule> {
    return {
      ngModule: IconModule,
      providers: [
        {
          provide: ICON_MODULE_FORROOT_GUARD,
          useFactory: provideForRootGuard,
          deps: [[IconModule, new Optional(), new SkipSelf()]],
        },
        { provide: RegistryConfig, useValue: config, multi: true },
        IconRegistryService,
      ],
    }
  }

  static forChild(config?: IconRegistryConfig): ModuleWithProviders<IconModule> {
    return {
      ngModule: IconModule,
      providers: [{ provide: RegistryConfig, useValue: config, multi: true }],
    }
  }

  constructor(
    iconRegistry: IconRegistryService,
    @Optional() @Inject(RegistryConfig) configs: IconRegistryConfig[] | null = null
  ) {
    if (!configs) {
      return
    }

    const configsWithValue = configs.filter((config) => !!config)

    configsWithValue.forEach((config) => iconRegistry.registerIcons(config.icons))
  }
}

export function provideForRootGuard(iconModule: IconModule): string {
  if (iconModule) {
    throw Error('IconModule is already loaded. Import it in the AppModule only')
  }

  return 'guarded'
}

export const ICON_MODULE_FORROOT_GUARD = new InjectionToken<void>('ICON_MODULE_FORROOT_GUARD')
