export function setLocalStorageItem(key: string, value: string): void {
  localStorage.setItem(key, JSON.stringify(value))
}

export function getLocalStorageItem(key: string): any {
  const item = localStorage.getItem(key)

  if (!item) {
    return null
  }

  try {
    return JSON.parse(item)
  } catch (error) {
    return item
  }
}

export function removeLocalStorageItem(key: string): void {
  localStorage.removeItem(key)
}
