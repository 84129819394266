import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { CdkStepperModule } from '@angular/cdk/stepper'
import { StepperComponent } from './stepper.component'
import { IconModule } from '../icon/icon.module'

@NgModule({
  declarations: [StepperComponent],
  imports: [CommonModule, CdkStepperModule, IconModule.forChild()],
  exports: [StepperComponent],
})
export class StepperModule {}
