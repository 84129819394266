import { EventEmitter, InjectionToken, Type } from '@angular/core'

export const MODAL_DATA = new InjectionToken<ModalData>('MODAL_DATA')

export type ModalComponentType = Type<{
  dataEventEmitter?: EventEmitter<any>
  [propName: string]: any
}>

export interface ModalConfig {
  closeOnBackdropClick?: boolean
  panelClass?: string
  backdropClass?: string
}

export interface ModalData {
  [key: string]: any
}
