import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core'
import { FormGroup } from '@angular/forms'
import { Observable, Subscription } from 'rxjs'

import { FormConfigInterface } from '../../dictionary/flx-template.dictionary'
import { FlxOrderByPipe } from '../../pipes/order-by.pipe'
import { FlxFormService } from '../../services/flx-form.service'
import { extractPlaceholders } from '../../flx.utils'
import { isHidden$ } from '../../store/process-data.store'
import { FlxWrapperComponent } from '../../flx-wrapper'

@Component({
  selector: 'flx-form',
  templateUrl: './flx-form.component.html',
  styleUrls: ['./flx-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [FlxOrderByPipe],
})
export class FlxFormComponent
  extends FlxWrapperComponent<FormConfigInterface>
  implements OnInit, OnDestroy
{
  private subscriptions = new Subscription()

  public form: FormGroup
  public formSubmitted$: Observable<any>
  public isHidden$: Observable<boolean>
  private hiddenDeps

  get formFields(): any {
    return this.orderBy.transform(this.config.formFields, 'order')
  }

  get validateOn(): string {
    return this.config.displayOptions?.flowxProps?.validateOn
      ? this.config.displayOptions?.flowxProps?.validateOn
      : 'submit'
  }

  get formId(): string {
    return this.config.formId
  }

  get style(): string {
    return this._config?.displayOptions?.style || ''
  }

  get class(): string {
    return this._config?.displayOptions?.class || ''
  }

  @Output() modelChange = new EventEmitter<any>()

  constructor(private orderBy: FlxOrderByPipe, private flxFormService: FlxFormService) {
    super()
  }

  ngOnInit(): void {
    this.form = this.flxFormService.getFormGroup(this.formId)
    this.formSubmitted$ = this.flxFormService.formSubmitted$(this.formId)
    this.hiddenDeps = extractPlaceholders(this._config?.expressions?.hide)
    this.isHidden$ = isHidden$(this.hiddenDeps, this._config)
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe()
  }

  trackByFn(index, item): number {
    return item.id
  }
}
