import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import {
  ListItemComponent,
  ListAvatarDirective,
  ListTextDirective,
  ListActionDirective,
} from './list-item.component'
import { IconModule } from '../icon/icon.module'
import { message, success, error, warning, close, info } from '../icon/ppfIcons'

@NgModule({
  declarations: [ListItemComponent, ListAvatarDirective, ListTextDirective, ListActionDirective],
  imports: [CommonModule, IconModule.forChild({ icons: [message, success, error, warning, close, info]})],
  exports: [ListItemComponent, ListAvatarDirective, ListTextDirective, ListActionDirective],
})
export class ListItemModule {}
