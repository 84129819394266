import { Component, Input, Output, EventEmitter, ChangeDetectorRef } from '@angular/core'

import {
  DEFAULT_BOUNDARY_COUNT,
  DEFAULT_SIBLING_COUNT,
  DEFAULT_START_PAGE,
} from './pagination.dictionary'

@Component({
  selector: 'ppf-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss'],
})
export class PaginationComponent {
  constructor(private changeDetector: ChangeDetectorRef) {}

  @Input() pagesCount: number
  @Input() boundaryCount: number = DEFAULT_BOUNDARY_COUNT
  @Input() siblingCount: number = DEFAULT_SIBLING_COUNT
  @Input() startPage: number = DEFAULT_START_PAGE

  @Output() pageSelect = new EventEmitter<number>()

  currentPage = this.startPage

  get firstPages() {
    const pages = [...Array(this.pagesCount).keys()].map((i) => i + 1)

    if (!this.hidePages()) {
      return pages
    }

    if (this.currentPage >= this.boundaryCount + this.siblingCount) {
      return pages.slice(0, this.boundaryCount)
    }

    return pages.slice(0, this.boundaryCount + this.siblingCount)
  }

  get lastPages() {
    const pages = [...Array(this.pagesCount).keys()].map((i) => i + 1)

    if (!this.hidePages()) {
      return null
    }

    if (this.currentPage > pages.length - this.boundaryCount - this.siblingCount + 1) {
      return pages.slice(pages.length - this.boundaryCount - this.siblingCount)
    }

    return pages.slice(pages.length - this.boundaryCount)
  }

  get siblingPages() {
    const pages = [...Array(this.pagesCount).keys()].map((i) => i + 1)

    if (!this.hidePages()) {
      return null
    }

    if (
      this.currentPage >= this.boundaryCount + this.siblingCount &&
      this.currentPage <= pages.length - this.boundaryCount - this.siblingCount + 1
    ) {
      return pages.slice(
        this.currentPage - this.siblingCount / 2,
        this.currentPage + this.siblingCount / 2
      )
    }

    return null
  }

  handlePageClick(page: number) {
    this.setPage(page)
  }

  handleNextClick() {
    if (this.currentPage >= this.pagesCount) {
      return
    }

    this.setPage(this.currentPage + 1)
  }

  handlePreviousClick() {
    if (this.currentPage <= 1) {
      return
    }

    this.setPage(this.currentPage - 1)
  }

  hidePages() {
    return this.boundaryCount * 2 + this.siblingCount < this.pagesCount - 1
  }

  private setPage(page: number) {
    this.currentPage = page
    this.pageSelect.emit(page)

    this.changeDetector.detectChanges()
  }
}
