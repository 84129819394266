import { BehaviorSubject, Observable } from 'rxjs'
import { distinctUntilChanged, filter, map, tap } from 'rxjs/operators'

import { ProcessToken } from '../dictionary/flx-process.dictionary'
import { isEqual as _isEqual } from 'lodash/fp'

export type ProcessInstanceStore = Record<string, ProcessInstance>

export type ProcessInstance = {
  uuid: string
  websocketPath: string
  parentProcessUuid: string | null
  subprocessIds: string[]
  tokens: ProcessToken[]
  currentNodeId?: number
  currentTokenUuid?: string
  templatesSequence?: number[]
}

const processInstanceInitialState = {}
const activeProcessInstanceIdStoreInitialState = null

const processInstanceStore = new BehaviorSubject<ProcessInstanceStore>(processInstanceInitialState)
const activeProcessInstanceIdStore = new BehaviorSubject<string>(
  activeProcessInstanceIdStoreInitialState
)

export const processInstances$ = processInstanceStore.asObservable()

export const activeProcessInstanceId$ = activeProcessInstanceIdStore.asObservable()

export function addProcessInstance(processInstance: ProcessInstance): void {
  const state = processInstanceStore.value

  processInstanceStore.next({
    ...state,
    [processInstance.uuid]: processInstance,
  })
}

export function getProcessInstanceValueById(processInstanceUuid: string): ProcessInstance {
  return processInstanceStore.value[processInstanceUuid]
}

export function addChildProcessInstance(
  parentProcessUuid: string,
  processInstance: Omit<ProcessInstance, 'subprocessIds' | 'parentProcessUuid'>
): void {
  const state = processInstanceStore.value

  const updatedParentProcess = {
    ...state[parentProcessUuid],

    subprocessIds: [...state[parentProcessUuid].subprocessIds, processInstance.uuid],
  }

  const newProcessInstance = {
    ...processInstance,
    parentProcessUuid,
    subprocessIds: [],
  }

  processInstanceStore.next({
    ...state,
    [parentProcessUuid]: updatedParentProcess,
    [processInstance.uuid]: newProcessInstance,
  })
}

export function setActiveProcessInstance(uuid: string): void {
  activeProcessInstanceIdStore.next(uuid)
}

export function getActiveProcessInstanceIdValue(): string {
  return activeProcessInstanceIdStore.value
}

export function updateProcessInstanceCurrentState(
  uuid: string,
  nodeDefinitionId: number,
  tokenUuid: string
): void {
  const state = processInstanceStore.value

  processInstanceStore.next({
    ...state,

    [uuid]: {
      ...state[uuid],
      currentTokenUuid: tokenUuid,
      currentNodeId: nodeDefinitionId,
    },
  })
}

// PURE
export function isCurrentNode(processInstance: ProcessInstance, nodeDefinitionId: number): boolean {
  return processInstance.currentNodeId === nodeDefinitionId
}

export function isCurrentNode$(uuid: string, nodeDefinitionId: number): Observable<boolean> {
  return processInstances$.pipe(
    filter((processInstances) => Boolean(processInstances[uuid])),
    map((processInstances) => isCurrentNode(processInstances[uuid], nodeDefinitionId)),
    distinctUntilChanged((prev, curr) => _isEqual(prev, curr))
  )
}

export function currentNodeId$(uuid: string): Observable<number> {
  return processInstances$.pipe(
    filter((processInstances) => Boolean(processInstances[uuid])),
    map((processInstances) => processInstances[uuid].currentNodeId),
    distinctUntilChanged((prev, curr) => _isEqual(prev, curr))
  )
}

export function reset(): void {
  processInstanceStore.next(processInstanceInitialState)
  activeProcessInstanceIdStore.next(activeProcessInstanceIdStoreInitialState)
}

export function discardProcessInstance(processInstanceUuid: string): void {
  const oldActiveProcessInstanceId = activeProcessInstanceIdStore.value

  const newActiveProcessInstanceId =
    processInstanceStore.value[oldActiveProcessInstanceId].parentProcessUuid

  activeProcessInstanceIdStore.next(newActiveProcessInstanceId)

  const { [processInstanceUuid]: discardedProcess, ...state } = processInstanceStore.value

  if (!discardedProcess.parentProcessUuid) {
    processInstanceStore.next({
      ...state,
    })

    return
  }

  processInstanceStore.next({
    ...state,

    [discardedProcess.parentProcessUuid]: {
      ...state[discardedProcess.parentProcessUuid],

      subprocessIds: state[discardedProcess.parentProcessUuid].subprocessIds.filter(
        (subprocessId) => subprocessId !== processInstanceUuid
      ),
    },
  })
}
