import {
  Component,
  ContentChild,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  TemplateRef,
} from '@angular/core'
import { BehaviorSubject } from 'rxjs'

import { ArrayDataSource } from '@angular/cdk/collections'

import { TreeNode } from './tree.dictionary'
import { FlatTreeControl } from '@angular/cdk/tree'
import { CdkDragDrop } from '@angular/cdk/drag-drop'
import { DndDropEvent } from 'ngx-drag-drop'

@Component({
  selector: 'ppf-tree',
  templateUrl: './tree.component.html',
  styleUrls: ['./tree.component.scss'],
})
export class TreeComponent implements OnChanges {
  @Input() data: TreeNode[] = []
  @Input() draggingEnabled = false

  @Output() drop: EventEmitter<any> = new EventEmitter<any>()
  @Output() dragStart: EventEmitter<any> = new EventEmitter<any>()
  @Output() dragEnd: EventEmitter<any> = new EventEmitter<any>()
  @Output() dragHover: EventEmitter<any> = new EventEmitter<any>()
  @Output() dragHoverEnd: EventEmitter<any> = new EventEmitter<any>()

  @ContentChild('parentNodeTemplate') parentNodeTemplate: TemplateRef<any>
  @ContentChild('leafNodeTemplate') leafNodeTemplate: TemplateRef<any>

  treeControl = new FlatTreeControl<TreeNode>(
    (node) => node.level,
    (node) => node.expandable
  )

  // tslint:disable-next-line: variable-name
  private _data = new BehaviorSubject<TreeNode[]>([])
  dataSource = new ArrayDataSource(this._data)

  ngOnChanges(): void {
    this._data.next(this.data)
  }

  shouldRender(node: TreeNode) {
    let parent = this.getParentNode(node)
    while (parent) {
      if (!parent.isExpanded) {
        return false
      }
      parent = this.getParentNode(parent)
    }
    return true
  }

  getParentNode(node: TreeNode): TreeNode {
    const nodeIndex = this._data.value.indexOf(node)

    for (let i = nodeIndex - 1; i >= 0; i--) {
      if (this._data.value[i].level === node.level - 1) {
        return this._data.value[i]
      }
    }

    return null
  }

  hasChild(_: number, node: any): boolean {
    return node.expandable
  }

  onDrop(event: DndDropEvent): void {
    this.drop.emit({ dragged: event.data, dropIndex: event.index })
  }

  onDragHover(event): void {
    this.dragHover.emit(event)
  }

  onDragHoverEnd(): void {
    this.dragHoverEnd.emit()
  }

  onDragStart(): void {
    this.dragStart.emit()
  }

  onDragged(node, effect: any) {}

  onDragEnd(event): void {
    this.dragEnd.emit()
  }
}
