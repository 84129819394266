import { Component, Input, Output, EventEmitter } from '@angular/core'
import { Column } from './table.dictionary'
@Component({
  selector: 'ppf-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
})
export class TableComponent {
  @Input() data: [] = []
  @Input() columns: Column[] = []
  @Input() templates: {} = {}
  @Input() headerTemplates: {} = {}
  @Output() rowClick = new EventEmitter<any>()

  get columnsIds(): string[] {
    return this.columns.map((column) => column.id)
  }

  handleRowClick(row: any): void {
    this.rowClick.emit(row)
  }
}
