import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { ToastComponent } from './toast.component'
import { CardModule } from '../card/card.module'
import { IconModule } from '../icon/icon.module'
import { OverlayModule } from '@angular/cdk/overlay'
import { message, play, pause, success, error, warning, close, info } from '../icon/ppfIcons'

@NgModule({
  declarations: [ToastComponent],
  imports: [
    CommonModule,
    CardModule,
    IconModule.forChild({ icons: [message, play, pause, success, error, warning, close, info] }),
    OverlayModule,
  ],
  exports: [ToastComponent],
})
export class ToastModule {
}
