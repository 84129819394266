import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { IconModule } from '../icon/icon.module'
import { SharedModule } from '../shared/shared.module'
import { HintComponent } from './hint.component'
import { error, success, warning, info } from '../icon/ppfIcons'

@NgModule({
  declarations: [HintComponent],
  imports: [
    CommonModule,
    IconModule.forChild({ icons: [success, error, warning, info] }),
    SharedModule,
  ],
  exports: [HintComponent],
})
export class HintModule {}
