import { from, Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { flow as _flow } from 'lodash/fp'

import { buildAuthHeader, getFlowXCMSAxiosInstance } from './axios.utils'
import { getConfig } from '../store/process-config'
import {
  FlxEnumeration,
  FlxEnumerationsRequestOptions,
} from '../dictionary/flx-enumeration.dictionary'

export function get({
  name,
  language,
  ...queryParams
}: FlxEnumerationsRequestOptions): Observable<FlxEnumeration[]> {
  return from(
    _flow(getConfig, getFlowXCMSAxiosInstance)().get(`/api/contents/${name}/values`, {
      headers: {
        ...buildAuthHeader(),
        'Accept-Language': language,
      },

      params: queryParams,
    })
  ).pipe(map((response) => response.data))
}
