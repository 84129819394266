import { Inject, InjectionToken, ModuleWithProviders, NgModule, Optional } from '@angular/core'
import { CommonModule } from '@angular/common'

import {
  AccordionModule,
  ButtonModule,
  CardModule,
  CheckboxModule,
  DatePickerModule,
  MessageModule,
  IconModule,
  InputModule,
  RadioModule,
  SelectModule,
  SharedModule,
  StepperModule,
  SwitchModule,
  HintModule,
  error,
  success,
  arrow,
  checkmark,
  ModalModule,
  SpinnerModule,
  FileUploadModule,
  TooltipModule,
  TextareaModule,
} from 'paperflow-web-components'

import './reactive-local-storage'

import { FlxFormComponent } from './components/flx-form/flx-form.component'
import { FlxImageComponent } from './components/flx-image/flx-image.component'
import { SelectWrapperComponent } from './components/flowX/select-wrapper/select-wrapper.component'
import { InputWrapperComponent } from './components/flowX/input-wrapper/input-wrapper.component'
import { RadioWrapperComponent } from './components/flowX/radio-wrapper/radio-wrapper.component'
import { StepperWrapperComponent } from './components/flowX/stepper-wrapper/stepper-wrapper.component'
import { DatepickerWrapperComponent } from './components/flowX/datepicker-wrapper/datepicker-wrapper.component'
import { ButtonWrapperComponent } from './components/flowX/button-wrapper/button-wrapper.component'
import { SwitchWrapperComponent } from './components/flowX/switch-wrapper/switch-wrapper.component'
import { CheckboxWrapperComponent } from './components/flowX/checkbox-wrapper/checkbox-wrapper.component'
import { InputMaskWrapperComponent } from './components/flowX/input-mask-wrapper/input-mask-wrapper.component'
import { HintWrapperComponent } from './components/flowX/hint-wrapper/hint-wrapper.component'
import { FlxLinkComponent } from './components/flx-link/flx-link.component'
import { FlxTreeNodeComponent } from './components/flx-tree-node/flx-tree-node.component'
import { FlxFormFieldComponent } from './components/flx-form-field/flx-form-field.component'
import { FlxFormErrorComponent } from './components/flx-form-error/flx-form-error.component'
import { FlxTextComponent } from './components/flx-text/flx-text.component'
import { FlxPageComponent } from './components/flx-page/flx-page.component'
import { FlxModalComponent } from './components/flx-modal/flx-modal.component'
import { MessageWrapperComponent } from './components/flowX/message-wrapper/message-wrapper.component'
import { FlxProcessRendererComponent } from './components/flx-process-renderer/flx-process-renderer.component'
import { FlxFormGroupComponent } from './components/flx-form-group/flx-form-group.component'

import { FlxOrderByPipe } from './pipes/order-by.pipe'

import { DynamicFieldDirective } from './directives/dynamic-field.directive'

import { ComponentsRegistry, FlxConfig, ValidatorsRegistry } from './dictionary/flx.dictionary'
import { FlxComponentResolver } from './services/flx-component-resolver.service'

import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { FlexModule } from '@angular/flex-layout'
import { CdkStepperModule } from '@angular/cdk/stepper'

import {
  ClientStoreInterface,
  NomenclatorServiceInterface,
} from './dictionary/flx-nomenclator.dictionary'
import { FlxNomenclatorRepository } from './flx-nomenclator.repository'
import { FlxClientStoreRepository } from './flx-client-store.repository'
import { FLX_VALIDATORS } from './flx-validators'
import { FlxValidatorResolver } from './services/flx-validator-resolver.service'
import { FLX_ASYNC_VALIDATORS } from './flx-async-validators'
import { FileUploadWrapperComponent } from './components/flowX/fileupload-wrapper/fileupload-wrapper.component'
import { FlxGenericCustomComponent } from './components/flx-generic-custom/flx-generic-custom.component'
import { FlxContainerComponent } from './components/flx-container/flx-container.component'
import { FlxLocalizationModule } from './flx-localization.module'
import { FlxInfoTooltipComponent } from './components/flx-info-tooltip/flx-info-tooltip.component'
import { TextareaWrapperComponent } from './components/flowX/textarea-wrapper/textarea-wrapper.component'
import { TextFieldModule } from '@angular/cdk/text-field'

export const FLX_COMPONENTS_CONFIG = new InjectionToken<string[]>('FLX_COMPONENTS_CONFIG')
export const FLX_VALIDATORS_CONFIG = new InjectionToken<string[]>('FLX_VALIDATORS_CONFIG')
export const FLX_NOMENCLATOR_CONFIG = new InjectionToken<string[]>('FLX_NOMENCLATOR_CONFIG')
export const FLX_CLIENT_STORE_CONFIG = new InjectionToken<string[]>('FLX_CLIENT_STORE_CONFIG')

export const defaultComponents = {
  STEPPER: StepperWrapperComponent,
  PAGE: FlxPageComponent,
  MODAL: FlxModalComponent,
  IMAGE: FlxImageComponent,
  LINK: FlxLinkComponent,
  FORM_GROUP: FlxFormGroupComponent,
  FORM: FlxFormComponent,
  SELECT: SelectWrapperComponent,
  INPUT: InputWrapperComponent,
  RADIO: RadioWrapperComponent,
  BUTTON: ButtonWrapperComponent,
  DATEPICKER: DatepickerWrapperComponent,
  SWITCH: SwitchWrapperComponent,
  CHECKBOX: CheckboxWrapperComponent,
  MESSAGE: MessageWrapperComponent,
  INPUT_MASK: InputMaskWrapperComponent,
  TEXT: FlxTextComponent,
  HINT: HintWrapperComponent,
  FILE_UPLOAD: FileUploadWrapperComponent,
  CONTAINER: FlxContainerComponent,
  INFO_TOOLTIP: FlxInfoTooltipComponent,
  TEXTAREA: TextareaWrapperComponent,
}
const FlowXRendererComponents = [
  FlxImageComponent,
  FlxLinkComponent,
  FlxTreeNodeComponent,
  FlxFormComponent,
  FlxFormGroupComponent,
  FlxFormFieldComponent,
  FlxFormErrorComponent,
  FlxProcessRendererComponent,
  FlxModalComponent,
  FlxPageComponent,
  FlxTextComponent,
  FlxGenericCustomComponent,
  FlxContainerComponent,
  FlxInfoTooltipComponent,
]

const FlowXWrappersComponents = [
  InputWrapperComponent,
  RadioWrapperComponent,
  SelectWrapperComponent,
  StepperWrapperComponent,
  ButtonWrapperComponent,
  DatepickerWrapperComponent,
  SwitchWrapperComponent,
  CheckboxWrapperComponent,
  MessageWrapperComponent,
  InputMaskWrapperComponent,
  HintWrapperComponent,
  FileUploadWrapperComponent,
  TextareaWrapperComponent,
]

@NgModule({
  declarations: [
    FlxOrderByPipe,
    DynamicFieldDirective,
    ...FlowXRendererComponents,
    ...FlowXWrappersComponents,
    FlxContainerComponent,
  ],
  imports: [
    FlxLocalizationModule,
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    FlexModule,
    CdkStepperModule,
    CardModule,
    InputModule,
    RadioModule,
    SelectModule,
    StepperModule,
    SharedModule,
    ButtonModule,
    HintModule,
    DatePickerModule.forRoot(),
    CheckboxModule,
    SwitchModule,
    AccordionModule,
    MessageModule,
    ModalModule,
    SpinnerModule,
    FileUploadModule,
    TooltipModule,
    TextareaModule,
    TextFieldModule,
    IconModule.forChild({ icons: [success, error, arrow, checkmark] }),
  ],
  exports: [FlxProcessRendererComponent],
})
export class FlxProcessModule {
  static forRoot(config: FlxConfig): ModuleWithProviders<FlxProcessModule> {
    return {
      ngModule: FlxProcessModule,
      providers: [
        { provide: FLX_COMPONENTS_CONFIG, useValue: config.components },
        { provide: FLX_VALIDATORS_CONFIG, useValue: config.validators },
        ...(config.services.NomenclatorService
          ? [
              {
                provide: FLX_NOMENCLATOR_CONFIG,
                useExisting: config.services.NomenclatorService,
              },
            ]
          : []),
        ...(config.services.LocalDataStoreService
          ? [
              {
                provide: FLX_CLIENT_STORE_CONFIG,
                useExisting: config.services.LocalDataStoreService,
              },
            ]
          : []),
      ],
    }
  }

  constructor(
    flxComponentResolver: FlxComponentResolver,
    flxValidatorResolver: FlxValidatorResolver,
    private nomenclatorRepository: FlxNomenclatorRepository,
    private clientStoreRepository: FlxClientStoreRepository,
    @Optional() @Inject(FLX_COMPONENTS_CONFIG) components: ComponentsRegistry,
    @Optional() @Inject(FLX_VALIDATORS_CONFIG) validators: ValidatorsRegistry,
    @Optional() @Inject(FLX_NOMENCLATOR_CONFIG) nomenclatorService: NomenclatorServiceInterface,
    @Optional() @Inject(FLX_CLIENT_STORE_CONFIG) clientStore: ClientStoreInterface
  ) {
    this.nomenclatorRepository.nomenclator = nomenclatorService
    this.clientStoreRepository.store = clientStore

    flxComponentResolver.addComponentsToRegistry({ ...defaultComponents, ...components })
    flxValidatorResolver.addValidatorsToRegistry({
      ...FLX_VALIDATORS,
      ...FLX_ASYNC_VALIDATORS,
      ...validators,
    })
  }
}
