import {
  AfterViewInit,
  Component,
  Injector,
  Input,
  OnInit,
  QueryList,
  ViewChildren,
  ViewContainerRef,
} from '@angular/core'
import { FormGroup } from '@angular/forms'

import {
  ComponentIdentifierEnum,
  TemplateConfigInterface,
  TemplateConfigType,
} from '../../dictionary/flx-template.dictionary'

import { FlxOrderByPipe } from '../../pipes/order-by.pipe'
import { FlxProcessService } from '../../services/flx-process.service'
import { FlxComponentRendererService } from '../../services/flx-component-renderer.service'
import { isFormGroupDisabled$ } from '../../store/template-config.store'
import { isCurrentNode$ } from '../../store/process-instance.store'
import { uniqBy as _uniqBy } from 'lodash/fp'

@Component({
  selector: 'flx-tree-node',
  templateUrl: './flx-tree-node.component.html',
  styleUrls: ['./flx-tree-node.component.css'],
  providers: [FlxOrderByPipe],
})
export class FlxTreeNodeComponent implements OnInit, AfterViewInit {
  @ViewChildren('viewport', { read: ViewContainerRef })
  private viewportRefs!: QueryList<ViewContainerRef>

  @Input() rootTemplateConfig: TemplateConfigInterface
  @Input() form?: FormGroup
  @Input() formGroupId?: string

  public stepsViewportRef: ViewContainerRef[]

  constructor(
    private flxProcessService: FlxProcessService,
    private injector: Injector,
    private orderBy: FlxOrderByPipe
  ) {}

  ngOnInit(): void {
    // Order templateConfig array by order
    this.rootTemplateConfig = {
      ...this.rootTemplateConfig,
      templateConfig: this.orderBy.transform(this.rootTemplateConfig.templateConfig, 'order'),
    }
  }

  ngAfterViewInit(): void {
    this.stepsViewportRef = this.viewportRefs.toArray()
    this.renderTemplate()

    this.viewportRefs.changes.subscribe(() => {
      this.stepsViewportRef = this.viewportRefs.toArray()
      this.renderTemplate()
    })
  }

  renderTemplate(): void {
    setTimeout(() => {
      if (this.rootTemplateConfig && this.rootTemplateConfig.templateConfig) {
        const componentRenderer = this.injector.get(FlxComponentRendererService)

        _uniqBy('id', this.rootTemplateConfig.templateConfig).forEach((tc, index) => {
          if (tc.type === TemplateConfigType.CUSTOM) {
            const component = componentRenderer.render(
              this.stepsViewportRef[index],
              tc.componentIdentifier
            )

            this.flxProcessService.bindDataAndActionsToComponent(
              tc.processInstanceUuid,
              tc.id,
              tc.inputKeys,
              component
            )

            return
          }

          switch (tc.componentIdentifier) {
            case ComponentIdentifierEnum.FORM_GROUP:
              componentRenderer.render(this.stepsViewportRef[index], tc.componentIdentifier, {
                templateConfig: tc,
                isDisabled$: isFormGroupDisabled$(tc.processInstanceUuid, tc),
                isCurrent$: isCurrentNode$(tc.processInstanceUuid, tc.nodeDefinitionId),
              })
              break

            case ComponentIdentifierEnum.FORM:
              componentRenderer.render(this.stepsViewportRef[index], tc.componentIdentifier, {
                formId: this.formGroupId,
                formFields: tc.formFields,
                displayOptions: tc.displayOptions,
                processInstanceUuid: tc.processInstanceUuid,
                expressions: tc.expressions || null,
              })
              break

            case ComponentIdentifierEnum.BUTTON:
              componentRenderer.render(this.stepsViewportRef[index], tc.componentIdentifier, {
                ...tc,
              })
              break

            case ComponentIdentifierEnum.IMAGE:
              componentRenderer.render(this.stepsViewportRef[index], tc.componentIdentifier, {
                key: tc.key,
                processInstanceUuid: tc.processInstanceUuid,
                displayOptions: tc.displayOptions,
              })
              break

            case ComponentIdentifierEnum.TEXT:
              componentRenderer.render(this.stepsViewportRef[index], tc.componentIdentifier, {
                displayOptions: tc.displayOptions,
                processInstanceUuid: tc.processInstanceUuid,
              })
              break
            case ComponentIdentifierEnum.INFO_TOOLTIP:
              componentRenderer.render(this.stepsViewportRef[index], tc.componentIdentifier, {
                ...tc,
                processInstanceUuid: tc.processInstanceUuid,
              })
              break
            case ComponentIdentifierEnum.TEXTAREA:
              componentRenderer.render(this.stepsViewportRef[index], tc.componentIdentifier, {
                ...tc,
                processInstanceUuid: tc.processInstanceUuid,
              })
              break

            case ComponentIdentifierEnum.HINT:
              componentRenderer.render(this.stepsViewportRef[index], tc.componentIdentifier, {
                displayOptions: tc.displayOptions,
              })
              break

            case ComponentIdentifierEnum.LINK:
              componentRenderer.render(this.stepsViewportRef[index], tc.componentIdentifier, {
                displayOptions: tc.displayOptions,
              })
              break

            case ComponentIdentifierEnum.STEPPER:
              componentRenderer.render(this.stepsViewportRef[index], tc.componentIdentifier, {
                processInstanceUuid: tc.processInstanceUuid,
                templateConfig: tc.templateConfig,
                displayOptions: tc.displayOptions,
              })
              break

            case ComponentIdentifierEnum.FILE_UPLOAD:
              componentRenderer.render(this.stepsViewportRef[index], tc.componentIdentifier, {
                ...tc,
              })
              break

            case ComponentIdentifierEnum.MODAL:
              componentRenderer.render(this.stepsViewportRef[index], tc.componentIdentifier, {
                templateConfig: tc,
                displayOptions: tc.displayOptions,
              })
              break

            case ComponentIdentifierEnum.PAGE:
              componentRenderer.render(this.stepsViewportRef[index], tc.componentIdentifier, {
                templateConfig: tc,
                displayOptions: tc.displayOptions,
              })
              break

            case ComponentIdentifierEnum.MESSAGE:
              componentRenderer.render(this.stepsViewportRef[index], tc.componentIdentifier, {
                templateConfig: tc,
                displayOptions: tc.displayOptions,
              })
              break

            case ComponentIdentifierEnum.CONTAINER:
              componentRenderer.render(this.stepsViewportRef[index], tc.componentIdentifier, {
                templateConfig: tc,
                formGroupId: this.formGroupId,
                form: this.form,
              })
              break
          }
        })
      }
    })
  }

  // isContainerComponent(componentIdentifier: ComponentIdentifierEnum): boolean {
  //   return componentIdentifier === ComponentIdentifierEnum.CONTAINER
  // }
  //
  // isHidden$(tc: TemplateConfigInterface): Observable<boolean> {
  //   return unfoldedProcessData$(
  //     tc.processInstanceUuid,
  //     extractPlaceholders(tc?.expressions?.hide)
  //   ).pipe(
  //     map((model) => {
  //       return tc?.expressions?.hasOwnProperty('hide')
  //         ? Boolean(
  //             // tslint:disable-next-line:no-eval
  //             eval(
  //               replaceExpressionPlaceholders({
  //                 text: tc.expressions.hide,
  //                 model,
  //               })
  //             )
  //           )
  //         : false
  //     })
  //   )
  // }

  trackByFn(index, item): number {
    return item.id
  }
}
