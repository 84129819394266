import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { ChipComponent } from './chip.component'
import { IconModule } from '../icon/icon.module'
import { close } from '../icon/ppfIcons'

@NgModule({
  declarations: [ChipComponent],
  imports: [CommonModule, IconModule.forChild({
    icons: [close],
  })],
  exports: [ChipComponent],
})
export class ChipModule {}
