import { InjectionToken } from '@angular/core'

export const TOAST_DATA = new InjectionToken<ToastData>('TOAST_DATA')
export const DEFAULT_DISPLAY_TYPE = 'fill'
export const DEFAULT_CLOSE_INTERVAL = 5000

export type ToastType = 'warning' | 'info' | 'success' | 'error' | 'custom'
export type ToastPosition = 'top-left' | 'top-right' | 'bottom-left' | 'bottom-right'
export type DisplayType = 'fill' | 'border'

export interface ToastData {
  messageType: ToastType
  displayType?: DisplayType
  title?: string
  message?: string
  position?: ToastPosition
  icon?: string
  action?: {
    name: string
    event: any
  }
  closeOnClick?: boolean
  closeInterval?: number
}
