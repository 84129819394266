import { AbstractControl, ValidationErrors, Validators } from '@angular/forms'
import moment from 'moment'

const format = 'DD.MM.YYYY'

export const FLX_VALIDATORS = {
  minlength: Validators.minLength,
  maxlength: Validators.maxLength,
  isSameOrBeforeToday: function isSameOrBeforeToday(AC: AbstractControl): ValidationErrors | null {
    if (AC && AC.value && !moment(AC.value, format, true).isBefore(moment(new Date(), format))) {
      return { isSameOrBeforeToday: true }
    }
    return null
  },

  isSameOrAfterToday: function isSameOrAfterToday(AC: AbstractControl): ValidationErrors | null {
    if (
      AC &&
      AC.value &&
      !moment
        .utc(AC.value, format, true)
        .endOf('day')
        .isSameOrAfter(moment.utc(new Date(), format).endOf('day'))
    ) {
      return { isSameOrAfterToday: true }
    }
    return null
  },
}
