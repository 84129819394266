import { Component, OnInit, Directive } from '@angular/core'

@Directive({
  selector: '[ppf-list-avatar], [ppfListAvatar]',
  host: { class: 'ppf-list-avatar' },
})
export class ListAvatarDirective {}

@Directive({
  selector: '[ppf-list-text], [ppfListText]',
  host: { class: 'ppf-list-text' },
})
export class ListTextDirective {}

@Directive({
  selector: '[ppf-list-action], [ppfListAction]',
  host: { class: 'ppf-list-action' },
})
export class ListActionDirective {}

@Component({
  selector: 'ppf-list-item',
  templateUrl: './list-item.component.html',
  styleUrls: ['./list-item.component.scss'],
})
export class ListItemComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
