import { Type } from '@angular/core'
import { AsyncValidatorFn, ValidatorFn } from '@angular/forms'
import { Observable } from 'rxjs'

export type FlxConfig = {
  components: ComponentsRegistry
  validators?: ValidatorsRegistry
  services: { NomenclatorService?: any; LocalDataStoreService?: any }
}

export type ComponentsRegistry = {
  [key: string]: Type<any>
}

export type ValidatorsRegistry = {
  [key: string]: ValidatorFn | ValidatorFnFactory | AsyncValidatorFn | AsyncValidatorFnFactory
}

export type ValidatorFnFactory = (...args: any[]) => ValidatorFn

export type AsyncValidatorFnFactory = (args?: Observable<any>[]) => AsyncValidatorFn

export const LOCAL_STORAGE_EVENT_KEY = 'localstorage'
