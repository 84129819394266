import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { MenuComponent } from './menu.component'
import { IconModule } from '../icon/icon.module'
import { ButtonModule } from '../button/button.module'
import { CardModule } from '../card/card.module'
import { message, play, pause, menu } from '../icon/ppfIcons'

@NgModule({
  declarations: [MenuComponent],
  imports: [CommonModule, IconModule.forChild({ icons: [message, play, pause, menu] }), ButtonModule, CardModule],
  exports: [MenuComponent],
})
export class MenuModule {}
