import { Component, Input, OnInit } from '@angular/core'
import { FormGroup } from '@angular/forms'
import { FlxFormService } from '../../../services/flx-form.service'
import { getProcessActionByTemplateId$ } from '../../../store/process-actions.store'
import { FlxProcessService } from '../../../services/flx-process.service'
import { ProcessAction, ProcessActionParams } from '../../../dictionary/flx-process.dictionary'
import { tap } from 'rxjs/operators'
import { Observable } from 'rxjs'
import { FlxWrapperComponent } from '../../../flx-wrapper'

@Component({
  selector: 'flx-button-wrapper',
  templateUrl: './button-wrapper.component.html',
  styleUrls: ['./button-wrapper.component.css'],
})
export class ButtonWrapperComponent extends FlxWrapperComponent implements OnInit {
  private actionFormKey: string[]
  private actionFn: () => Observable<any>

  action$: Observable<ProcessAction>
  form: FormGroup

  constructor(
    private flxFormService: FlxFormService,
    private flxProcessService: FlxProcessService
  ) {
    super()
  }

  get class(): string {
    return this.config?.displayOptions?.class || ''
  }

  get style(): string {
    return this.config?.displayOptions?.style || ''
  }

  ngOnInit(): void {
    this.action$ = getProcessActionByTemplateId$(
      this.config.processInstanceUuid,
      this.config.id
    ).pipe(
      tap((processAction) => {
        if (processAction) {
          this.actionFn = this.flxProcessService.buildAction(
            this.config.processInstanceUuid,
            processAction
          )
          this.actionFormKey = processAction?.params?.formKey
          this.form = this.flxFormService.getFormGroup(this.actionFormKey) as FormGroup
        }
      })
    )
  }

  onClick(actionParams: ProcessActionParams): void {
    if (!this.actionFn) {
      throw new Error(
        `No action defined for the BUTTON with the key ${this.config.displayOptions?.flowxProps?.label}`
      )
    }

    this.actionFn().subscribe()
  }
}
