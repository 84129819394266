import { Component, Input, Output, EventEmitter, HostBinding } from '@angular/core'

import { ButtonType } from './dictionary'

@Component({
  selector: 'ppf-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
})
export class ButtonComponent {
  @Input() title: string = ''
  @Input() rightIconName: string | null = null
  @Input() leftIconName: string | null = null
  @Input() type: ButtonType = 'fill'
  @Input() disabled = false
  @Input() autoSize = true

  @Output() onClick: EventEmitter<Event> = new EventEmitter<Event>()

  @HostBinding('class.icon-only')
  get isIconOnly() {
    return !this.title
  }

  @HostBinding('class.right-icon')
  get hasRightIcon() {
    return this.rightIconName
  }

  @HostBinding('class.auto-size-disabled')
  get isAutoSizeDisabled() {
    return !this.autoSize
  }

  handleClick(event: Event) {
    if (!this.disabled) {
      this.onClick.emit(event)
    }
  }

  getTypeClass() {
    const typesToClassesMap = {
      fill: 'type-fill',
      border: 'type-border',
      flat: 'type-flat',
    }

    return typesToClassesMap[this.type]
  }
}
