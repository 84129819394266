import { Component, OnInit, Input } from '@angular/core'

@Component({
  selector: 'flx-form-error',
  templateUrl: './flx-form-error.component.html',
  styleUrls: ['./flx-form-error.component.scss'],
})
export class FlxFormErrorComponent implements OnInit {
  private _error: string

  @Input()
  set error(error: string) {
    this._error = error
  }

  get error(): string {
    return this._error
  }

  ngOnInit(): void {}
}
