export type SelectType = 'default' | 'custom'
export type SourceType = 'local' | 'remote'
export type VisualType = 'box' | 'line'
export type Item<T> = {
  value: string | number
  label: string
  T?: any
}

export const DEFAULT_TYPE = 'box'
export const DEFAULT_SOURCE = 'local'
export const DEFAULT_SELECT_TYPE = 'default'
