import { Component } from '@angular/core'
import { FlxWrapperComponent } from '../../flx-wrapper'
import { TemplateConfigInterface } from '../../dictionary/flx-template.dictionary'

@Component({
  selector: 'flx-flx-info-tooltip',
  templateUrl: './flx-info-tooltip.component.html',
  styleUrls: ['./flx-info-tooltip.component.scss'],
})
export class FlxInfoTooltipComponent extends FlxWrapperComponent<TemplateConfigInterface> {
  set config(tc: TemplateConfigInterface) {
    this._config = tc
  }
  get config(): TemplateConfigInterface {
    return this._config
  }
  get position(): string {
    return this.config.displayOptions?.flowxProps.tooltipPosition || 'top'
  }
}
