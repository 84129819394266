import {
  Directive,
  Input,
  HostListener,
  ElementRef,
  ComponentRef,
  OnInit,
  OnDestroy,
} from '@angular/core'
import { OverlayRef, Overlay, OverlayPositionBuilder } from '@angular/cdk/overlay'
import { ComponentPortal } from '@angular/cdk/portal'
import { TooltipComponent } from './tooltip.component'
import { TooltipPositionType, TooltipColorType, TooltipSizeType } from './tooltip.dictionary'
import { debounce } from '../shared/utils'

@Directive({ selector: '[ppfTooltip]' })
export class TooltipDirective implements OnInit, OnDestroy {
  @Input('ppfTooltip') text = ''
  @Input() tooltipPosition: TooltipPositionType = 'top'
  @Input() tooltipColor: TooltipColorType = 'default'
  @Input() tooltipSize: TooltipSizeType

  private overlayRef: OverlayRef

  constructor(
    private overlay: Overlay,
    private overlayPositionBuilder: OverlayPositionBuilder,
    private elementRef: ElementRef
  ) {}

  ngOnInit(): void {
    const positionStrategy = this.overlayPositionBuilder
      .flexibleConnectedTo(this.elementRef)
      .withPositions(this.getPosition())
    this.overlayRef = this.overlay.create({ positionStrategy })
  }

  @HostListener('mouseenter')
  show() {
    const tooltipRef: ComponentRef<TooltipComponent> = this.overlayRef.attach(
      new ComponentPortal(TooltipComponent)
    )
    tooltipRef.instance.text = this.text
    tooltipRef.instance.classes = this.getClasses()
  }

  @HostListener('wheel')
  @debounce(100)
  onWheel() {
    this.overlayRef.detach()
  }

  @HostListener('mouseleave')
  hide() {
    this.overlayRef.detach()
  }

  getPosition(): any[] {
    const overlayPosition = {
      bottom: [
        {
          originX: 'center',
          originY: 'bottom',
          overlayX: 'center',
          overlayY: 'top',
          offsetY: 8,
        },
      ],
      top: [
        {
          originX: 'center',
          originY: 'top',
          overlayX: 'center',
          overlayY: 'bottom',
          offsetY: -8,
        },
      ],
      left: [
        {
          originX: 'start',
          originY: 'center',
          overlayX: 'end',
          overlayY: 'center',
          offsetX: -8,
        },
      ],
      right: [
        {
          originX: 'end',
          originY: 'center',
          overlayX: 'start',
          overlayY: 'center',
          offsetX: 8,
        },
      ],
    }
    return overlayPosition[this.tooltipPosition]
  }

  getClasses() {
    return `ppf-tooltip-${this.tooltipColor} ppf-tooltip-${this.tooltipPosition} ppf-tooltip-${this.tooltipSize}`
  }

  ngOnDestroy(): void {
    this.overlayRef.detach()
  }
}
