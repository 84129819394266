import { Component, HostListener, ElementRef, Input, HostBinding } from '@angular/core'
import { MenuItem, XPosition, YPosition } from './menu.dictionary'

@Component({
  selector: 'ppf-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent {
  constructor(public element: ElementRef) {}
  showMenu = false
  @Input() menuTitle: string
  @Input() menuIcon: string
  @Input() menuItems: MenuItem<any>[]
  @Input() xPosition: XPosition = 'after'
  @Input() yPosition: YPosition = 'below'

  @HostBinding('class.ppf-before-menu') get isBefore() {
    return this.xPosition === 'before'
  }

  @HostListener('document:click', ['$event.target'])
  public onClick(targetElement) {
    const clickedInside = this.element.nativeElement.contains(targetElement)
    if (!clickedInside) {
      this.showMenu = false
    }
  }

  getStyle() {
    if (this.yPosition === 'above') {
      return `${this.element.nativeElement.children[0].offsetHeight}`
    }
  }

  trigger(event: Event) {
    event.stopPropagation()
    this.showMenu = !this.showMenu
    return this.showMenu
  }

  handleClick(item) {
    item.action()
    this.showMenu = !this.showMenu
  }
}
